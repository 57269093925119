import React, { Component ,useEffect } from 'react'
import { DOWNFILEURL,BASE_URL ,NEW_YGK_URL, Axios} from '../../utils/url';
import { Grid,Button} from 'antd-mobile';
import './yylb.css'
import clcrsq from '../../assets/images/clcrsq.png'
import clbg from '../../assets/images/clbg.png'
import cljf from '../../assets/images/cljf.png'
import dwclyq from '../../assets/images/clph.png'
import Ygkgs from '../../assets/images/ygkgs.png'
import Ygkjyjlcx from '../../assets/images/ygkjyjlcx.png'
import Ygkzxcz from '../../assets/images/ygkzxcz.png'
import Yktgbfjf from '../../assets/images/yktgbfjf.png'
import Yktjyjlcx from '../../assets/images/yktjyjlcx.png'

import Yktsfyz from '../../assets/images/yktsfyz.png'
import Yktsq from '../../assets/images/yktsq.png'
import Yktzxcz from '../../assets/images/yktzxcz.png'

import Newygkzxcz from '../../assets/images/newygkzxcz.png'
import Newygkjyjl from '../../assets/images/newygkjyjl.png'
import Newygkzhxx from '../../assets/images/newygkzhxx.png'

import Yktgs from '../../assets/images/yktgs.png'
import Wyfjf from '../../assets/images/wyfjf.png'
import Yktbksq from '../../assets/images/yktbksq.png'
import download from '../../assets/images/download.png'
import download2 from '../../assets/images/download2.png'
import download3 from '../../assets/images/wyfjf.png'

//相关链接
import ntcj from '../../assets/images/ntcj.png'
import yqsw from '../../assets/images/yqsw.png'
import xnrl from '../../assets/images/xnrl.png'
import JKZX from '../../assets/images/jkzx.png'
import favicon from '../../assets/images/favicon.ico'
import wsyyt from '../../assets/images/wsyyt.png'

const data = [
    { icon: clcrsq, text: "车辆出入申请" },
    // { icon: clph, text: "地库排号" },
    { icon: clcrsq, text: "车辆延期" },
    { icon: cljf, text: "车辆缴费" },
    { icon: dwclyq, text: "单位车辆延期" },
    // { icon: download, text: "模板下载" },
];
//新员工卡
const newygkglydata = [
    { icon: Newygkzxcz, text: "在线充值" },
    { icon: Newygkjyjl, text: "个人交易流水" },
    { icon: Newygkzhxx, text: "个人账户信息" },
    { icon: Yktjyjlcx, text: "卡片信息查询" },
    // { icon: Yktsq, text: "一卡通申请" },
    { icon: Yktgs, text: "一卡通挂失" },
    { icon: Yktbksq, text: "一卡通补卡" },
    { icon: Yktgbfjf, text: "工本费缴费" },
    { icon: Yktsfyz, text: "C卡门禁延期" },
    { icon: Yktsq, text: "一卡通申请" },
    { icon: Yktbksq, text: "一卡通审核" },

];
//新员工卡 newygkdataGeneral
const newygkdata = [
    { icon: Newygkzxcz, text: "在线充值" },
    { icon: Newygkjyjl, text: "个人交易流水" },
    { icon: Newygkzhxx, text: "个人账户信息" },
    { icon: Yktjyjlcx, text: "卡片信息查询" },
    { icon: Yktsq, text: "一卡通申请" },
    { icon: Yktgs, text: "一卡通挂失" },
    { icon: Yktbksq, text: "一卡通补卡" },
    { icon: Yktgbfjf, text: "工本费缴费" },
    { icon: Yktsfyz, text: "C卡门禁延期" },
];

const newygkdataGeneral = [
    { icon: Newygkzxcz, text: "在线充值" },
    { icon: Newygkjyjl, text: "个人交易流水" },
    { icon: Newygkzhxx, text: "个人账户信息" },
    { icon: Yktjyjlcx, text: "卡片信息查询" },
    { icon: Yktsq, text: "一卡通申请" },
    { icon: Yktgs, text: "一卡通挂失" },
    { icon: Yktbksq, text: "一卡通补卡" },
    { icon: Yktgbfjf, text: "工本费缴费" },
    { icon: Yktsfyz, text: "C卡门禁延期" },
];

const newygkzxczlist = [
    { icon: Newygkzxcz, text: "在线充值" },
]

const ygkdata = [
    { icon: Ygkzxcz, text: "在线充值" },
    { icon: Ygkgs, text: "员工卡挂失" },
    { icon: clbg, text: "员工卡补卡" },
    // { icon: cljf, text: "工本费缴费" },
    { icon: Ygkjyjlcx, text: "交易记录查询" },
    { icon: Yktjyjlcx, text: "个人账户信息查询" }


];
// const fwcz = [
//     { icon: Fwsc, text: "上传合同" }
// ];
const yktdata = [
    { icon: Yktzxcz, text: "在线充值" },
    { icon: Yktsq, text: "一卡通申请" },
    { icon: Yktgs, text: "一卡通挂失" },
    { icon: Yktbksq, text: "一卡通补卡" },
    { icon: Yktsfyz, text: "C卡门禁延期" },
    { icon: Yktgbfjf, text: "工本费缴费" },
    { icon: Yktjyjlcx, text: "交易记录查询" },
];
const wyfdata = [
    { icon: Wyfjf, text: "物业费缴费" },
    // { icon: Yktsq, text: "罚款缴费" },
    { icon: Yktgbfjf, text: "幼儿费" },
    { icon: Yktjyjlcx, text: "物业费查询" }
];

const xgbdxz = [
    { icon: download2, text: "车辆出入申请单" },
    { icon: download, text: "一卡通申请单" },
    { icon: download3, text: "出租房表单" }
];

const aboutLink = [
    { icon: ntcj, text: "南天城建" },
    { icon: xnrl, text: "新隆热力" },
    { icon: yqsw, text: "银泉水务" },
    { icon: JKZX, text: "健康咨询" } ,
    // { icon: favicon, text: "享惠家" }
    { icon: wsyyt, text: "个人固话缴费" }
]

const aboutLinkAdmin = [
    { icon: ntcj, text: "南天城建" },
    { icon: xnrl, text: "新隆热力" },
    { icon: yqsw, text: "银泉水务" },
    { icon: JKZX, text: "健康咨询" } ,
    // { icon: favicon, text: "享惠家" },
    { icon: wsyyt, text: "个人固话缴费" }
]


const yysqdata = [
    { icon: Wyfjf, text: "预约申请" },
    { icon: Newygkzhxx, text: "预约审核" },
];
export default class clgl extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            isgly: false,
            isVisible: 'block',
            isExitCard: true,
            isFlag: props.isFlag,
        });
    }
    componentDidMount = () => {
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        var idCardNo = userInfo.sfzh;
        //  var idCardNo = '612321199509106233';
         let openid = this.props.location.search;
         if(openid.length > 0 ){
            var open = openid.split("?");
            var newValue = open[1].split('=');
            if(newValue == "isNone,'none'"){
               this.setState({
                   isVisible: 'none',
                })
            }else{
                this.setState({
                    isVisible: 'block'
                })
            }
         }
        // alert(idCardNo);
        if(userInfo === null){
            // alert("A")
            if (openid.length > 0) {
                // alert("B")
                // var open = openid.split("&");
                // var openidArr = open[0].split("=");
                // var openid1 = openidArr[1];
                // var params = new URLSearchParams();
                // localStorage.setItem("openID", openid1);
                // params.append('openid', openid1);
                // Axios({
                //     method: 'post',
                //     url: "http://218.205.135.179:8899/clydd/bRyzcController/getUserInfo",
                //     data: params
                // }).then((res) => {
                //     // alert("C")
                //     localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
                //     localStorage.setItem("bshtoken", JSON.stringify(res.data.bshtoken));
                //     userInfo = JSON.parse(localStorage.getItem("userInfo"));
                //     this.getIsAdmin(idCardNo);
                //     this.isExitCardM(idCardNo);
                // });
            }else{
                this.getIsAdmin(idCardNo);
                this.isExitCardM(idCardNo);
            }
        }else{
            this.getIsAdmin(idCardNo);
            this.isExitCardM(idCardNo);
        }

        // 判断是否是在微信里
        var ua = navigator.userAgent.toLowerCase()
        var isWXWork = ua.match(/wxwork/i) == 'wxwork'
        var isWeixin = !isWXWork && ua.match(/micromessenger/i) == 'micromessenger'
        if (isWeixin) {
            var date = new Date();
            var curren_timestamp = Date.parse(date);
            curren_timestamp = curren_timestamp/1000;
            // alert("当前时间戳到秒级："+curren_timestamp);
            var wxSignature = JSON.parse(localStorage.getItem("wxSignature"));
            if(wxSignature ==="" || wxSignature ===null || wxSignature ==="undefined"){
                this.getSignature();
            }else{
                var timestamp = wxSignature.timestamp;
                var nonceStr = wxSignature.nonceStr;
                var signature = wxSignature.signature;
                var appId = wxSignature.appId;
                // 当前时间转换成秒级，减去签名生成的时间秒，如何大于7000秒，则重新生成签名，否则使用缓存里的签名
                if( (curren_timestamp - timestamp) >7000){
                    this.getSignature();
                }else{
                    // alert("相差秒数："+(curren_timestamp - timestamp));
                    window.wx.config({
                        //debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
                        appId: appId, // 必填，公众号的唯一标识
                        timestamp: timestamp, // 必填，生成签名的时间戳
                        nonceStr: nonceStr, // 必填，填任意非空字符串即可
                        signature: signature, // 必填，填任意非空字符串即可
                        jsApiList: ['chooseImage', 'previewImage'], // 必填，随意一个接口即可
                        openTagList: ['wx-open-launch-weapp'], // 填入打开小程序的开放标签名
                    });
                }
            }
        }else {
            // alert("请在微信中打开");
        }

    }
    //获取管理员信息
   async getIsAdmin(idCardNo){
        const url = NEW_YGK_URL + 'cardApply/getIsAdminByIdcardNo';
        var params = new URLSearchParams();
        params.append("idCardNo",idCardNo);
      await  Axios({
            method: 'post',
            url: url,
            data: params
        }).then(res => {
            console.log("我是获取管理员的打印res>>>>",res)
            // alert(res.data.flag)
            if(res.data.flag){
                this.setState({
                    isgly: res.data.flag
                })
            }else{
                this.setState({
                    isgly: res.data.flag
                })
            }
        })
    }

    async isExitCardM(idCardNo){
        const url = NEW_YGK_URL + 'cardHolderView/isExistsCard';
        var params = new URLSearchParams();
        params.append("idCardNo",idCardNo);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then(res =>{
            if(res.data.flag){
                this.setState({
                    isExitCard: res.data.flag
                })
            }else{
                this.setState({
                    isExitCard: res.data.flag
                })
            }
        })
    }

    openGn = e => {
        if (e.text === "车辆出入申请") {
            this.props.history.push('./clList');

        } else if (e.text === "车辆缴费") {
            this.props.history.push('./cljf');
        } else if (e.text === "地库排号") {
            this.props.history.push('./clph');
        } else if (e.text === "车辆延期") {
            this.props.history.push('./clxs');
        }
        else if (e.text === "车辆缴费") {
            this.props.history.push('./cljf');
        }
        else if (e.text === "模板下载") {
            this.props.history.push('./download');
        } else if (e.text === "单位车辆延期") {
            this.props.history.push('./clxsTrEitder');
        }
    }
    openYgkGn = e => {
        if (e.text === "员工卡补卡") {
            this.props.history.push('./ygkbksq');
        }
        // else if(e.text==="工本费缴费"){
        //     this.props.history.push('./ygkgbfjf');
        // }
        else if (e.text === "交易记录查询") {
            this.props.history.push('./ygkjfjlcx');
        } else if (e.text === "在线充值") {
            this.props.history.push('./ygkzxcz');
        } else if (e.text === "员工卡挂失") {
            this.props.history.push('./ygkgs');
        } else if (e.text === "个人账户信息查询") {
            this.props.history.push('./ygkzhxxcx');
        }
    }

    openNewYgkGn = e => {
        if (e.text === "在线充值") {
            this.props.history.push('./Newygkzxcz');
        } else if (e.text === "个人交易流水") {
            this.props.history.push('./newygkjyjl');
        } else if (e.text === "个人账户信息") {
            this.props.history.push('./Newygkzhxx');
        } else if (e.text === "卡片信息查询") {
            this.props.history.push('./ScanQrCode');
        }else if (e.text === "一卡通挂失") {
            this.props.history.push('./lossReport');
        }else if (e.text === "一卡通补卡") {
            this.props.history.push('./replaceCard');
        }else if (e.text === "一卡通补卡申请") {
            this.props.history.push('./replaceCard');
        }else if (e.text === "工本费缴费") {
            this.props.history.push('./yktgbfjf');
        }else if (e.text === "C卡门禁延期") {
            this.props.history.push('./postpone');
        }if(e.text === "一卡通申请") {
            this.props.history.push('./applyCard');
        }if(e.text === "一卡通审核") {
            this.props.history.push('./applyCardAudit');
        }
    }
    openYktGn = e => {
        if (e.text === "一卡通申请") {
            this.props.history.push('./yktsq');
        } else if (e.text === "一卡通补卡") {
            this.props.history.push('./yktbksq');
        } else if (e.text === "工本费缴费") {
            this.props.history.push('./yktgbfjf');
        } else if (e.text === "交易记录查询") {
            this.props.history.push('./yktjfjlcx');
        } else if (e.text === "在线充值") {
            this.props.history.push('./yktzxcz');
        } else if (e.text === "C卡门禁延期") {
            this.props.history.push('./yktsfyz');
        } else if (e.text === "权限信息查询") {
            this.props.history.push('./yktqxxxcx');
        } else if (e.text === "一卡通挂失") {
            this.props.history.push('./yktgs');
        }
    }
    openWyfGn = e => {
        if (e.text === "物业费缴费") {
            this.props.history.push('./wyfjf');
        } else if (e.text === "罚款缴费") {
            this.props.history.push('./wyfkjf');
        } else if (e.text === "幼儿费") {
            this.props.history.push('./wyyck');
        } else if (e.text === "物业费查询") {
            this.props.history.push('./wyfcx');
        }
    }
    openYysqGn =e=>{
        if (e.text === "预约申请") {
            this.props.history.push('./YysqList');
        }
        if (e.text === "预约审核") {
            this.props.history.push('./YysqListQr');
        }
    }

    openWyfGn = e => {
        if (e.text === "物业费缴费") {
            this.props.history.push('./wyfjf');
        } else if (e.text === "罚款缴费") {
            this.props.history.push('./wyfkjf');
        } else if (e.text === "幼儿费") {
            this.props.history.push('./wyyck');
        } else if (e.text === "物业费查询") {
            this.props.history.push('./wyfcx');
        }
    }

    openDownload = e => {
        if (e.text == "车辆出入申请单") {
            window.open(DOWNFILEURL + 'yktsqxx/download?fjlx=clsqdfj')
        } else if (e.text =="一卡通申请单") {
            window.open(DOWNFILEURL + 'yktsqxx/download?fjlx=yktsqfj')
        } else if (e.text == "出租房表单") {
            this.props.history.push('./czfbd');
        }
    }
    openLink = e =>{
        if (e.text === "南天城建") {
            window.open("http://channel-wechatserver.eslink.cc/co/channel/oauth?appid=wxac278e3b8333c10f&menu=381966&scope=snsapi_userinfo")
        } else if (e.text === "新隆热力") {
            window.open("http://www.refeibao.com/wxplat/user/toListBindedUsers")
        } else if (e.text === "银泉水务") {
            window.open("http://www.kelyqsw.com/WeChatNewsKEL/plugins/wechat.action?method=userinfo&code=091Afh0w3fb9O03yiD2w3zbnyS0Afh0t&state=1")
        } else if (e.text === "健康咨询") {
            window.open("https://wxweb.bshdjk.com/wx2021/Default.aspx")
        }
        // else if (e.text === "享惠家") {
        //     this.props.history.push('./openLittleApp');
        // }
        else if (e.text === "个人固话缴费") {
            var userInfo = JSON.parse(localStorage.getItem("userInfo"));
            var openID = localStorage.getItem("openID");
            var sfzh ="";
            var ryxm ="";
            var sjh ="";
            if(userInfo != null){
                sfzh = userInfo.sfzh;
                ryxm = userInfo.ryxm;
                sjh = userInfo.rysx20120716p0048;
            }
            //alert("身份证："+sfzh+"  人员："+ryxm+"   手机号："+sjh+"   openID:"+openID);
            window.open("https://tlm.asstar.net:37002/telecom-app-front/?openid="+openID+"&sfzh="+sfzh+"&ryxm="+ryxm+"&sjh="+sjh);
        }
    }
    openFwcz = () => {
        // this.props.history.push('./scht');
        var openid = localStorage.getItem("openID");
        window.open("https://tlm.wx.api.etvcsoft.com/index.html#/home?id=" + openid)
    }
    handleClose = () => {
        this.setState({
            isVisible: 'none'
        });
    };
    getPhoneDom = () => {
        return <>
            <div className="grid-css-child">物业车辆运维：
                <div style={{ paddingLeft: "20px", display: "inline-block" }}><a href="tel:2174251">2174251</a></div>
                <div style={{ paddingLeft: "20px", display: "inline-block" }}><a href="tel:18709983767">18709983767</a></div>
            </div>
            <div className="grid-css-child">一卡通运维：
                <div style={{ paddingLeft: "20px", display: "inline-block" }}><a href="tel:13239943508">13239943508</a></div>
            </div>
        </>
    }
    getAboutAppDom = () => {
        return <>
            <div className="grid-css-child">相关小程序 </div>
            <div style={{ marginTop: ".8rem" ,width:"100%",display:"flex"}} >
                <div className='am-flexbox-item am-grid-item' style={{width: "25%",padding:"10px 0"}}>
                    <wx-open-launch-weapp id="launch-btn" username="gh_722cb819ae99" style={{width:"100%",display:"block",position:"relative"}} >
                        <script type="text/wxtag-template">
                            <div style={{textAlign:"center",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                                <div style={{width:"28%"}}>
                                    <img src="http://www.bzbsh.cn:18080/static/media/favicon.2d76d096.ico" style={{width:"100%"}} alt="物业报修" />
                                </div>
                                <div style={{fontSize:"12px",paddingTop:"4px"}}>物业报修</div>
                            </div>
                        </script>
                    </wx-open-launch-weapp>
                </div>
                <div className='am-flexbox-item am-grid-item' style={{width: "25%",padding:"10px 0"}}>
                    <wx-open-launch-weapp id="national-btn" username="gh_56b2c43416a4" style={{width:"100%",display:"block",position:"relative"}}>
                        <script type="text/wxtag-template">
                            <div style={{textAlign:"center",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                                <div style={{width:"28%"}}>
                                    <img src="http://www.bzbsh.cn:18080/stategrid.png" style={{width:"100%"}} alt="国家电网" />
                                </div>
                                <div style={{fontSize:"12px",paddingTop:"4px"}}>国家电网</div>
                            </div>
                        </script>
                    </wx-open-launch-weapp>
                </div>
                <div className='am-flexbox-item am-grid-item' style={{width: "25%",padding:"10px 0"}}>
                    <wx-open-launch-weapp id="police-btn" username="gh_338bf7a78076" style={{width:"100%",display:"block",position:"relative"}}>
                        <script type="text/wxtag-template">
                            <div style={{textAlign:"center",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                                <div style={{width:"28%"}}>
                                    <img src="http://www.bzbsh.cn:18080/policing.png" style={{width:"100%"}} alt="身份证户籍办理" />
                                </div>
                                <div style={{fontSize:"12px",paddingTop:"4px"}}>身份证户籍办理</div>
                            </div>
                        </script>
                    </wx-open-launch-weapp>
                </div>
                <div className='am-flexbox-item am-grid-item' style={{width: "25%",padding:"10px 0"}}>
                    <wx-open-launch-weapp id="bmcs-btn" username="gh_5898e4403342" style={{width:"100%",display:"block",position:"relative"}}>
                        <script type="text/wxtag-template">
                            <div style={{textAlign:"center",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                                <div style={{width:"28%"}}>
                                    <img src="http://www.bzbsh.cn:18080/bshbmcs.jpg" style={{width:"100%"}} alt="便民超市" />
                                </div>
                                <div style={{fontSize:"12px",paddingTop:"4px"}}>便民超市</div>
                            </div>
                        </script>
                    </wx-open-launch-weapp>
                </div>
            </div>
        </>
    }
    getAntiFraudDom = () => {
        return <>
            <div id='antiFraudModel' style={{
                zIndex:"999999999999999", position:"fixed", top:"10%", left:"10%", width:"80%", height:"80%", display:this.state.isVisible
            }}>

                <button onClick={this.handleClose} style={{
                    width:'40px', height:'40px', display: "block", fontSize:'24px', cursor:'pointer', position: 'absolute', top: '0', right:'0',
                    color: 'red', fontWeight: 'bold', borderStyle:'hidden', backgroundColor:'rgba(87,91,222,0)', borderColor:'rgba(87,91,222,0)'
                }}>X</button>
                <img src = "asjkdhfkljas.jpg" alt='反诈照片' style={{width:"100%",height:'100%'}} />
            </div>
        </>
    }

    // 获取唤醒微信小程序的签名
    async getSignature() {
        // console.log("执行获取数据");
        var params = new URLSearchParams();
        var tokenUrl = window.location.href.split("#")[0];//这里【url参数一定是去参的动态的当前页网址】
        params.append('tokenUrl', tokenUrl);
        // params.append('ticket', "LIKLckvwlJT9cWIhEQTwfE8p_Cxxov9MmMTpfjQ0I52P-yQ_APR_dFJB5Ch6io-FINEAWsDNPVnvGfxTJhR8Ow");
        var ticket ="";
        await Axios({
            method: 'get',
            url: "http://www.bzbsh.cn/bshwxgzh/admin/getTicket.html",
            data: {}
        }).then((res) => {
            //console.log("获取Ticket:",res.data);
            ticket = res.data;
            if(ticket ==="" || ticket==null){
                ticket = "LIKLckvwlJT9cWIhEQTwfE8p_Cxxov9MmMTpfjQ0I52P-yQ_APR_dFJB5Ch6io-FINEAWsDNPVnvGfxTJhR8Ow";
            }
            params.append('ticket', ticket);
            Axios({
                method: 'post',
                url: BASE_URL +"bRyzcController/getSign",
                data: params
            }).then((res) => {
                var timestamp = res.data.timestamp;
                var nonceStr = res.data.nonceStr;
                var signature = res.data.signature;
                var appId = res.data.appId;
                localStorage.setItem("wxSignature",JSON.stringify(res.data));
                // alert("signature:"+signature+" appId:"+appId+"  nonceStr:"+nonceStr);
                window.wx.config({
                    //debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
                    appId: appId, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: nonceStr, // 必填，填任意非空字符串即可
                    signature: signature, // 必填，填任意非空字符串即可
                    jsApiList: ['chooseImage', 'previewImage'], // 必填，随意一个接口即可
                    openTagList: ['wx-open-launch-weapp'], // 填入打开小程序的开放标签名
                });

            });
        });
    }
    isglyjm = () => {
        const adminFlag = this.state.isgly;
        const isExistsCardFlag = this.state.isExitCard
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        var sfzh = userInfo.sfzh;
        var canShow = true
        if(sfzh =='622323199208183117'||sfzh =='652801199903014539'||sfzh =='511224198112289013'||sfzh =='420881198110204431'||sfzh=='620523199305234691'||sfzh=='652301199904286029'||sfzh=='620422199601207442'){
            canShow = true
        }
        if (!canShow) {
            if (adminFlag == true && isExistsCardFlag == true) {
                return <div style={{ paddingBottom: "100px" }}>
                    <div className="grid-css-child">新一卡通 </div>
                    <Grid data={newygkzxczlist} activeStyle={false} hasLine={false} onClick={this.openNewYgkGn} />
                    {this.getPhoneDom()}
                    {this.getAntiFraudDom()}
                </div>
            } else if(adminFlag == false && isExistsCardFlag == true){
                return <div style={{ paddingBottom: "100px",position:"relative" }}>
                    <div className="grid-css-child">新一卡通 </div>
                    <Grid data={newygkzxczlist} activeStyle={false} hasLine={false} onClick={this.openNewYgkGn} />
                    {this.getPhoneDom()}
                    {this.getAntiFraudDom()}
                </div>

            }else if(adminFlag == false && isExistsCardFlag == false){
                return <div style={{ paddingBottom: "100px",position:"relative" }}>
                    <div className="grid-css-child">新一卡通 </div>
                    <Grid data={newygkzxczlist} activeStyle={false} hasLine={false} onClick={this.openNewYgkGn} />
                    {this.getPhoneDom()}
                    {this.getAntiFraudDom()}
                </div>
            }
        }
        if (adminFlag == true && isExistsCardFlag == true) {
            return <div style={{ paddingBottom: "100px" }}>
                <div className="grid-css-child">车辆管理 </div>
                <Grid data={data} activeStyle={false} hasLine={false} onClick={this.openGn} />
                <div className="grid-css-child">新一卡通 </div>
                <Grid data={newygkglydata} activeStyle={false} hasLine={false} onClick={this.openNewYgkGn} />
                <div className="grid-css-child">访客管理</div>
                <Grid data={yysqdata} activeStyle={false} hasLine={false} onClick={this.openYysqGn} />
                {/* <div className="grid-css-child">员工卡 </div>
                <Grid data={ygkdata} activeStyle={false} hasLine={false} onClick={this.openYgkGn} /> */}
                {/* <div className="grid-css-child">一卡通C卡 </div>
                <Grid data={yktdata} activeStyle={false} hasLine={false} onClick={this.openYktGn} /> */}
                <div className="grid-css-child">收费业务 </div>
                <Grid data={wyfdata} activeStyle={false} hasLine={false} onClick={this.openWyfGn} />
                {/* <div className="grid-css-child">房屋出租 </div>
                <Grid data={fwcz} activeStyle={false} hasLine={false} onClick={this.openFwcz} /> */}
                <div className="grid-css-child">相关表单下载 </div>
                <Grid data={xgbdxz} activeStyle={false} hasLine={false} onClick={this.openDownload} />
                <div className="grid-css-child">相关链接 </div>
                <Grid data={aboutLinkAdmin} activeStyle={false} hasLine={false} onClick={this.openLink} />
                {this.getAboutAppDom()}
                {this.getPhoneDom()}
                {this.getAntiFraudDom()}
            </div>
        } else if(adminFlag == false && isExistsCardFlag == true){
            return <div style={{ paddingBottom: "100px",position:"relative" }}>
                <div className="grid-css-child">车辆管理 </div>
                <Grid data={data} activeStyle={false} hasLine={false} onClick={this.openGn} />
                <div className="grid-css-child">新一卡通 </div>
                <Grid data={newygkdata} activeStyle={false} hasLine={false} onClick={this.openNewYgkGn} />
                {/* <div className="grid-css-child">访客管理</div>
                <Grid data={yysqdata} activeStyle={false} hasLine={false} onClick={this.openYysqGn} /> */}
                {/* <div className="grid-css-child">员工卡 </div>
                <Grid data={ygkdata} activeStyle={false} hasLine={false} onClick={this.openYgkGn} /> */}
                {/* <div className="grid-css-child">一卡通C卡 </div>
                <Grid data={yktdata} activeStyle={false} hasLine={false} onClick={this.openYktGn} /> */}
                <div className="grid-css-child">收费业务 </div>
                <Grid data={wyfdata} activeStyle={false} hasLine={false} onClick={this.openWyfGn} />
                <div className="grid-css-child">访客管理</div>
                <Grid data={yysqdata} activeStyle={false} hasLine={false} onClick={this.openYysqGn} />
                {/* <div className="grid-css-child">房屋出租 </div>
                <Grid data={fwcz} activeStyle={false} hasLine={false} onClick={this.openFwcz} /> */}
                <div className="grid-css-child">相关表单下载 </div>
                <Grid data={xgbdxz} activeStyle={false} hasLine={false} onClick={this.openDownload} />
                <div className="grid-css-child">相关链接 </div>
                <Grid data={aboutLink} activeStyle={false} hasLine={false} onClick={this.openLink} />
                {this.getAboutAppDom()}
                {this.getPhoneDom()}
                {this.getAntiFraudDom()}
            </div>

        }else if(adminFlag == false && isExistsCardFlag == false){
            return <div style={{ paddingBottom: "100px",position:"relative" }}>
                <div className="grid-css-child">车辆管理 </div>
                <Grid data={data} activeStyle={false} hasLine={false} onClick={this.openGn} />
                <div className="grid-css-child">新一卡通 </div>
                <Grid data={newygkdataGeneral} activeStyle={false} hasLine={false} onClick={this.openNewYgkGn} />
                <div className="grid-css-child">收费业务 </div>
                <Grid data={wyfdata} activeStyle={false} hasLine={false} onClick={this.openWyfGn} />
                <div className="grid-css-child">访客管理</div>
                <Grid data={yysqdata} activeStyle={false} hasLine={false} onClick={this.openYysqGn} />
                <div className="grid-css-child">相关表单下载 </div>
                <Grid data={xgbdxz} activeStyle={false} hasLine={false} onClick={this.openDownload} />
                <div className="grid-css-child">相关链接 </div>
                <Grid data={aboutLink} activeStyle={false} hasLine={false} onClick={this.openLink} />
                {this.getAboutAppDom()}
                {this.getPhoneDom()}
                {this.getAntiFraudDom()}
            </div>
        }
    }
    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
               {this.isglyjm()}
            </div>
        )
    }
}
