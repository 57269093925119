import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import './index.css';
import "antd-mobile/dist/antd-mobile.css"
import './assets/fonts/iconfont.css'
//我们自己写的样式导入在组件库后面才能有效果
import './index.css';
import { BASE_URL, Axios } from './utils/url'
window.addEventListener(
  'hashchange',
  function (event) {
    const oldURL = event.oldURL; // 上一个URL
    const newURL = event.newURL; // 当前的URL
    // if(oldURL.indexOf("ttydbgbd")){
    //     window.close();
    // }
    window.scrollTo(0, 0)
  },
  false
)

// 解析url参数
const url = window.location.href
if (url.includes('?')) {
  const paramsStr = url.split('?')[1]
  if (paramsStr.includes('&') || paramsStr.includes('=')) {
    for (const item of paramsStr.split('&')) {
      const paramsValue = item.split('=')
      localStorage.setItem(paramsValue[0], paramsValue[1])
    }
  }
}
localStorage.setItem('openID', localStorage.getItem('openid'))

// 获取公钥 用户信息
Axios({ method: 'get', url: BASE_URL + "getPublicKey" }).then((result) => {
  if (result.data.flag) {
    localStorage.setItem("publicKey", result.data.data);
  }
  if (localStorage.getItem('openid')) {
    var params = new URLSearchParams();
    params.append('openid', localStorage.getItem("openid"));
    Axios({
      method: 'post',
      url: BASE_URL + "bRyzcController/getUserInfo",
      data: params
    }).then((res) => {
      localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
      localStorage.setItem("bshtoken", JSON.stringify(res.data.bshtoken));
    }).catch(error =>{
      // alert(JSON.stringify(error))
    }).finally(() => {
      creatApp()
    });
  } else {
    creatApp()
  }
});

function creatApp() {
  ReactDOM.render(
    // <React.StrictMode>
    <App />,
    // </React.StrictMode>,
    document.getElementById('root')
  );
}