import './yktsqedit.css'
import React, { Component } from 'react'
import { NavBar, Icon, List, Picker,TextareaItem, InputItem, Modal, Button, SearchBar, PickerView, Radio, Toast, ImagePicker } from 'antd-mobile';
import { WY_URL,DOWNFILEURL, Axios } from '../../../utils/url';
import { XSZ_URL ,CLCRSQ_URL,JHZ_URL,SFZ_URL,YKT_URL,XMHT_URL,LDHT_URL} from '../../../utils/fjyl'
import lrz from "lrz";
import ycz from '../../../assets/images/ycz.jpg'
import hkb from '../../../assets/images/hkb.png'
import yktsqd from '../../../assets/images/yktsqd.png'
const Item = List.Item;
const Brief = Item.Brief;
const RadioItem = Radio.RadioItem;
export default class Yktsq extends Component {
    constructor(props) {
        super(props);
        let ryobject = JSON.parse(localStorage.getItem('userInfo'));
        this.state = {
            smxx:"",
            list: [],
            yhlx: [],
            fzlist: [],
            jtzhlist1: [],
            jtzhlist2: [],
            jtzhlist3: [],
            jtzhid1: "",
            jtzhid2: "",
            jtzhid3: "",
            fzxm: "",
            fzxmvalue: "",
            fzxmid: "",
            fzykth: "",
            yhlxmc: "",
            yhlxbm: "",
            xb: [{ value: '男', label: '男' }, { value: '女', label: '女' }],
            sftz: [{ value: '是', label: '是' }, { value: '否', label: '否' }],
            fjlxlist: [],
            sftzText: "",
            mz: [],
            tktlx: "",
            tktlxText: "",
            tktlxList:[],
            // tktlxList: [{ value: 'B', label: '配偶及直系亲属(B卡)' }, { value: 'C1', label: '外部单位人员(C卡)' }, { value: 'C3', label: '其他亲属(C卡)' }, { value: 'C2', label: '租房户(C卡)' }],
            ryxx: ryobject,
            sjhm: "",
            sfzh: "",
            sbxm: "",
            ryxm:"",
            lb: "B",
            yhzgx: [],
            hzgx: "",
            modal1: false,
            modalhzxm: false,
            multiple: false,
            hkbfiles: [],
            hzyktfiles: [],
            jhzfiles: [],
            yczfiles: [],
            hzsfzfiles: [],
            brsfzfiles: [],
            yktsqdfiles: [],
            yhmzText: "",
            fwbhlist: [],
            fwbhs: "",
            fwbh: "",
            dwlist:[],
            yhdws:"",
            yhdwbm:"",
            yhxb:"",
            jtzz:"",
            previewImage:'',
            sfzyl:false,
            ychdzpyl:false,
            yktyl:false,
            hkbyl:false,
            jhzyl:false,
            yktsqdyl:false
        }
    }

    componentDidMount = () => {
        // this.getyhlx()
        this.getmz();
        this.getjtzz("", "1");
        this.getsqlx();
        this.getSmxx();
    }

    async getsqlx() {
        console.log("根据卡类型判断");
        const url = WY_URL + 'yktsqxx/getlxpd';
        var params = new URLSearchParams();
        let ryobject = JSON.parse(localStorage.getItem('userInfo'));
        // params.append("yhsfzh", ryobject.sfzh);
        params.append("yhsfzh", "61032119721106085X");
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res.data.data);
            if (res.status === 200) {
                if(!res.data.data.check){
                    this.setState({
                        //{ value: 'C4', label: '幼儿园和学校家长(C卡)' }
                        tktlxList: [{ value: 'B', label: '职工配偶及直系亲属(B卡)' }, { value: 'C3', label: '职工其他亲属(C卡)' }]
                    });
                }else{
                    this.setState({
                        tktlxList: [ { value: 'C1', label: '外部单位人员(C卡)' }, { value: 'C2', label: '租房户(C卡)' }]
                    });
                }
            }
        });
    }

    async getSmxx() {
        console.log("获取说明信息");
        const url = WY_URL + 'yktsqxx/getSmxx';
        var params = new URLSearchParams();
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res.data.data);
            console.log( res.data.data[0].tsnr);
            if (res.status === 200) {
                this.setState({
                    smxx: res.data.data[0].tsnr
                });
            }
        });
    }

    async getyhlx() {
        console.log("获取用户类型");
        let ryobject = JSON.parse(localStorage.getItem('userInfo'));
        console.log(ryobject.sfzh);
        console.log(this.state.lb);
        const url = WY_URL + 'yktsqxx/getYhlx';
        var params = new URLSearchParams();
        params.append("yktlx", this.state.lb);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res.data.data);
            if (res.status === 200) {
                for(var i = 0;i< res.data.data.length;i++){
                    if(res.data.data[i].label ==="家属"){
                        res.data.data[i].label = "职工直系亲属"
                    }
                    if(res.data.data[i].label ==="常住户"){
                        res.data.data[i].label = "职工非直系亲属"
                    }
                    if(this.state.tktlx=="C4"){
                        if(res.data.data[i].label ==="职工非直系亲属"){
                            res.data.data.splice(i,1)
                        }
                    }
                }
                this.setState({
                    yhlx: res.data.data
                });
            }
        });
    }



    async getmz() {
        console.log("获取民族");
        const url = WY_URL + 'yktsqxx/getMz';
        var params = new URLSearchParams();

        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res.data.data);
            if (res.status === 200) {
                this.setState({
                    mz: res.data.data
                });
            }
        });
    }

    async getyhzgx() {
        console.log("与户主关系");
        const url = WY_URL + 'yktsqxx/getYhzgx';
        var params = new URLSearchParams();
        params.append("yktlx", this.state.lb);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res.data.data);
            if (res.status === 200) {
                this.setState({
                    yhzgx: res.data.data
                });
            }
        });
    }

    async getgzxm() {
        console.log("获取户主信息");
        const url = WY_URL + 'yktsqxx/getHzinfo';
        var params = new URLSearchParams();
        params.append("yhxm", this.state.fzxm);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res.data.data);
            if (res.status === 200) {
                this.setState({
                    fzlist: res.data.data
                });
            }
        });
    }

    async getdwxm() {
        console.log("获取单位信息");
        const url = WY_URL + 'yktsqxx/getDwxx';
        var params = new URLSearchParams();
        params.append("dwmc", this.state.yhdws);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res.data.data);
            if (res.status === 200) {
                this.setState({
                    dwlist: res.data.data
                });
            }
        });
    }

    async getjtzz(pid, type) {
        console.log("获取家庭住址");
        let that = this;
        const url = WY_URL + 'yktsqxx/getJtzz';
        var params = new URLSearchParams();
        params.append("pid", pid);
        params.append("type", type);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res.data.data);
            if (res.status === 200) {
                if (type == "1") {
                    that.setState({
                        jtzhlist1: res.data.data
                    });
                } else if (type == "2") {
                    that.setState({
                        jtzhlist2: res.data.data
                    });
                } else if (type == "3") {
                    that.setState({
                        jtzhlist3: res.data.data
                    });
                }

            }
        });
    }

    async getfjlx() {
        console.log("获取附件类型");
        console.log(this.state.tktlx);
        console.log(this.state.lb);
        const url = WY_URL + 'yktsqxx/getFjpzxx';
        var params = new URLSearchParams();
        params.append("yktlx", this.state.lb);
        if (this.state.tktlx == "C3") {
            params.append("personType", "常住人口");
        } else if (this.state.tktlx == "C2") {
            params.append("personType", "租房户");
        } else {
            if(this.state.yhlxmc=="职工直系亲属"){
                params.append("personType", "家属");
            }else{
                params.append("personType", this.state.yhlxmc);
            }
        }

        console.log(this.state.tktlx);
        console.log(this.state.yhlxmc);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res.data.data);
            if (res.status === 200) {
                this.setState({
                    fjlxlist: res.data.data
                });
            }
        });
    }

    async getfwbhhzxx() {
        console.log("根据房屋编号获取户主信息");
        const url = WY_URL + 'yktsqxx/getHzxxByFwbh';
        var params = new URLSearchParams();
        params.append("fwbh", this.state.fwbhs);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res.data.data);
            if (res.status === 200) {
                this.setState({
                    fwbhlist: res.data.data
                });
            }
        });
    }

    SerMapDw = (e) => {
        return this.state.fzlist.map(i => (
            <RadioItem key={i.value} onClick={() => this.fzonChange(i)}  >
                {i.label+"-"+i.yktzh}
            </RadioItem>
        ))
    }

    setwhbhlist = (e) => {
        return this.state.fwbhlist.map(i => (
            <RadioItem key={i.value} onClick={() => this.fwryxxChange(i)}  >
                {i.ryxm}
            </RadioItem>
        ))
    }

    setdwlist = (e) => {
        return this.state.dwlist.map(i => (
            <RadioItem key={i.value} onClick={() => this.dwChange(i)}  >
                {i.label}
            </RadioItem>
        ))
    }

    //本人身份证
    brsfzfj = () => {
        const list = this.state.fjlxlist
        let falg = false;
        var sfzfiles = [{url:SFZ_URL}]
        let data={};
        for (var item in list) {
            if (list[item].fjlxid == "2") {
                falg = true;
                data=list[item];
            }
        }
        if (falg) {
            return (
                <div>
                      <div style={{paddingTop: "10px"}}>
                        {/* <span  style={{
                        color:"red",
                        padding: "15px 15px 9px"
                    }}>身份证样例(点击查看)</span> */}
                        {/* <ImagePicker
                            files={[{url:SFZ_URL}]}
                            onImageClick={this.imgonclick}
                            selectable={false}
                            disableDelete="false"
                            capture="camera"
                        /> */}
                    </div>
                    <div className="scfj"><span style={{color:"red"}}>*</span>办卡人身份证(<span onClick={()=>this.setState({ sfzyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
                    <ImagePicker
                        files={this.state.brsfzfiles}
                        onChange={this.onChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.brsfzfiles.length < 4}
                        multiple={this.state.multiple}
                        capture="camera"
                    />
                    <div className="line"></div>
                </div>
            )
        } else {
            return null;

        }
    }

    //一寸照片
    yczpfj = () => {
        const list = this.state.fjlxlist
        let falg = false;
        let data={};
        for (var item in list) {
            if (list[item].fjlxid == "5") {
                falg = true;
                data=list[item];
            }
        }
        if (falg) {
            return (
                <div>
                     <div style={{paddingTop: "10px"}}>
                        {/* <span  style={{
                        color:"red",
                        padding: "15px 15px 9px"
                        }}>一寸照片样例(点击查看)</span> */}
                        {/* <ImagePicker
                            files={[{url:ycz}]}
                            onImageClick={this.imgonclick}
                            selectable={false}
                            disableDelete="false"
                            capture="camera"
                        /> */}
                    </div>
                    <div className="scfj"><span style={{color:"red"}}>*</span>一寸照片(红底)(<span onClick={()=>this.setState({ ychdzpyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
                    <ImagePicker
                        files={this.state.yczfiles}
                        onChange={this.yczponChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.yczfiles.length < 4}
                        multiple={this.state.multiple}
                    />
                    <div className="line"></div>
                </div>
            )
        } else {
            return null;

        }
    }

    //一卡通申请单
    yktfj = () => {
        const list = this.state.fjlxlist
        let falg = false;
        let data={};
        for (var item in list) {
            if (list[item].fjlxid == "1") {
                falg = true;
                data=list[item];
            }
        }
        if (falg) {
            return (
                <div>
                     <div style={{paddingTop: "10px"}}>


                        {/* <ImagePicker
                            files={[{url:yktsqd}]}
                            onImageClick={this.imgonclick}
                            selectable={false}
                            disableDelete="false"
                            capture="camera"
                        /> */}

                    </div>
                    <div className="scfj"><span style={{color:"red"}}>*</span>一卡通申请单(<span onClick={()=>this.setState({ yktsqdyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>

                    <ImagePicker
                        files={this.state.yktsqdfiles}
                        onChange={this.yktChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.yktsqdfiles.length < 4}
                        multiple={this.state.multiple}
                        capture="camera"
                    />
                    <span style={{
                            color:"#888",
                            padding: "15px 15px 9px"
                            }}>1、申请表单直接<span onClick={()=>this.download()} style={{
                            color:"#108ee9",}}>  下载</span></span>
                        <div style={{color:"#888",paddingLeft: "15px",paddingRight: "15px",paddingBottom: "10px"}}>2、宝石花主页-服务大厅表单-一卡通申请表</div>
                    <div className="line"></div>
                </div>
            )
        } else {
            return null;

        }
    }

    //户口本（本页及户主页）
    hkbfj = () => {
        const list = this.state.fjlxlist
        let falg = false;
        let data={};
        for (var item in list) {
            if (list[item].fjlxid == "10") {
                falg = true;
                data=list[item];
            }
        }
        if (falg) {
            return (
                <div>
                     <div style={{paddingTop: "10px"}}>
                        {/* <span  style={{
                        color:"red",
                        padding: "15px 15px 9px"
                        }}>户口本样例(点击查看)</span>
                        <ImagePicker
                            files={[{url:hkb}]}
                            onImageClick={this.imgonclick}
                            selectable={false}
                            disableDelete="false"
                            capture="camera"
                        /> */}
                    </div>
                    <div className="scfj">户口本(<span onClick={()=>this.setState({ hkbyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
                    <ImagePicker
                        files={this.state.hkbfiles}
                        onChange={this.hkbChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.hkbfiles.length < 4}
                        multiple={this.state.multiple}
                        capture="camera"
                    />
                    <div className="line"></div>
                </div>
            )
        } else {
            return null;

        }
    }

    //户主（配偶）一卡通
    hzyktfj = () => {
        const list = this.state.fjlxlist
        let falg = false;
        let data={};
        var yktylfiles = [{
            url:YKT_URL
          }]
        for (var item in list) {
            if (list[item].fjlxid == "6") {
                falg = true;
                data=list[item];
            }
        }

        if (falg) {
            return (
                <div>
                      <div style={{paddingTop: "10px"}}>
                        {/* <span  style={{
                        color:"red",
                        padding: "15px 15px 9px"
                    }}>一卡通样例(点击查看)</span>
                        <ImagePicker
                            files={yktylfiles}
                            onImageClick={this.imgonclick}
                            selectable={false}
                            disableDelete="false"
                            capture="camera"
                        /> */}
                    </div>
                    <div className="scfj"><span style={{color:"red"}}>*</span>户主（配偶）一卡通(<span onClick={()=>this.setState({ yktyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
                    <ImagePicker
                        files={this.state.hzyktfiles}
                        onChange={this.hzyktChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.hzyktfiles.length < 4}
                        multiple={this.state.multiple}
                        capture="camera"
                    />
                    <div className="line"></div>
                </div>
            )
        } else {
            return null;

        }
    }

    //结婚证
    jhzfj = () => {
        const list = this.state.fjlxlist
        let falg = false;
        let data={};
        var files = [{
            url:JHZ_URL
          }]
        for (var item in list) {
            if (list[item].fjlxid == "3") {
                falg = true;
                data=list[item];
            }
        }
        if (falg) {
            return (
                <div>
                       <div style={{paddingTop: "10px"}}>
                        {/* <span  style={{
                        color:"red",
                        padding: "15px 15px 9px"
                    }}>结婚证样例(点击查看)</span>
                        <ImagePicker
                            files={files}
                            onImageClick={this.imgonclick}
                            selectable={false}
                            disableDelete="false"
                        /> */}
                    </div>
                    <div className="scfj">结婚证(<span onClick={()=>this.setState({ jhzyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
                    <ImagePicker
                        files={this.state.jhzfiles}
                        onChange={this.jhzChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.jhzfiles.length < 4}
                        multiple={this.state.multiple}
                        capture="camera"
                    />
                    <div className="line"></div>
                </div>
            )
        } else {
            return null;

        }
    }

    //户主（配偶）身份证
    hzsfzfj = () => {
        const list = this.state.fjlxlist
        let falg = false;
        let data={};
        for (var item in list) {
            if (list[item].fjlxid == "7") {
                falg = true;
                data=list[item];
            }
        }
        if (falg) {
            return (
                <div>
                     <div style={{paddingTop: "10px"}}>
                        {/* <span  style={{
                        color:"red",
                        padding: "15px 15px 9px"
                    }}>户主（配偶）身份证样例(点击查看)</span>
                        <ImagePicker
                            files={[{url:SFZ_URL}]}
                            onImageClick={this.imgonclick}
                            selectable={false}
                            disableDelete="false"
                            capture="camera"
                        /> */}
                    </div>
                    <div className="scfj"><span style={{color:"red"}}>*</span>户主（配偶）身份证(<span onClick={()=>this.setState({ sfzyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
                    <ImagePicker
                        files={this.state.hzsfzfiles}
                        onChange={this.hzsfzChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.hzsfzfiles.length < 4}
                        multiple={this.state.multiple}
                        capture="camera"
                    />
                    <div className="line"></div>
                </div>
            )
        } else {
            return null;
        }
    }

    download =()=>{
        window.open( DOWNFILEURL+'yktsqxx/download?fjlx=yktsqfj')
      }

    imgonclick=(index,files) =>{
        this.setState({
          previewImage: files[index].url,
          visible: true
      });
      }

    genID = (length) => {
        return Number(Math.random().toString().substr(3,length) + Date.now()).toString(36);
    }
    onClosesfz = ()=>{
        this.setState({
              sfzyl: false
            });
      }
      onClosehdzp = ()=>{
        this.setState({
              ychdzpyl: false
            });
      }
      onCloseykt = ()=>{
        this.setState({
              yktyl: false
            });
      }
      onClosehkb = ()=>{
        this.setState({
              hkbyl: false
            });
      }
      onClosejhz = ()=>{
        this.setState({
              jhzyl: false
            });
      }
      onCloseyktsqd = ()=>{
        this.setState({
              yktsqdyl: false
            });
      }

    setContent = () => {
        if (this.state.tktlx == "") {
            return null;
        } else if (this.state.tktlx == "C1") {
            return (
                <div>
                    <div className="scfj">申请信息</div>
                    <InputItem value={this.state.ryxx.ryxm} >
                        <div ><span className="error">*</span>用户姓名</div>
                    </InputItem>
                    <InputItem placeholder="请选择甲方单位" value={this.state.yhdw} onClick={this.showModal('dwsc')} editable="false">
                        <div ><span className="error">*</span>所属单位</div>
                    </InputItem>
                    <Modal
                        popup
                        visible={this.state.dwsc}
                        cols={1}
                        value={this.state.yhdws}
                        onClose={this.onClose('dwsc')}
                        animationType="slide-up"
                        style={{
                            height: "280px",
                            overflow: "auto"
                        }}
                    >
                        <SearchBar placeholder="请输入单位名称"  cancelText="查询" onCancel={this.dwsearch} />
                        {this.setdwlist()}

                    </Modal>
                    <InputItem placeholder="请输入身份证号" editable="false" value={this.state.ryxx.sfzh} >
                        <div ><span className="error">*</span>身份证号</div>
                    </InputItem>
                    <Picker
                        data={this.state.xb}
                        cols={1}
                        onChange={this.xbChange}
                        value={this.state.yhxb}
                    >
                        <List.Item arrow="horizontal"><span className="error">*</span>用户性别</List.Item>
                    </Picker>
                    <Picker
                        data={this.state.mz}
                        cols={1}
                        onChange={this.mzChange}
                        value={this.state.yhmz}
                    >
                        <List.Item arrow="horizontal"><span className="error">*</span>民族</List.Item>
                    </Picker>
                    <InputItem placeholder="请输入手机号码" value={this.state.sjhm} onChange={(value) => this.setState({ sjhm: value })}>
                        <div ><span className="error">*</span>手机号码</div>
                    </InputItem>

                    <InputItem placeholder="请选择家庭住址" value={this.state.jtzz}  onChange={(value) => this.setState({ jtzz: value })}>
                        <div ><span className="error">*</span>家庭住址</div>
                    </InputItem>


                    <InputItem placeholder="工作单位" value={this.state.gzdw} onChange={(value) => this.setState({ gzdw: value })}>
                        <div >工作单位</div>
                    </InputItem>
                    <InputItem placeholder="工作地点" value={this.state.gzdd} onChange={(value) => this.setState({ gzdd: value })}>
                        <div >工作地点</div>
                    </InputItem>
                    <div className="scfj" style={{fontSize: "14px",
    color: "#888"}}>上传附件</div>
                    <div className="scfj"><span style={{color:"red"}}>*</span>办卡人身份证(<span onClick={()=>this.setState({ sfzyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
                    <div style={{paddingTop: "10px"}}>
                        {/* <span  style={{
                        color:"red",
                        padding: "15px 15px 9px"
                    }}>身份证样例(点击查看)</span> */}
                        {/* <ImagePicker
                            files={[{url:SFZ_URL}]}
                            onImageClick={this.imgonclick}
                            selectable={false}
                            disableDelete="false"
                        /> */}
                    </div>
                    <ImagePicker
                        files={this.state.brsfzfiles}
                        onChange={this.onChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.brsfzfiles.length < 4}
                        multiple={this.state.multiple}
                        capture="camera"
                    />
                    <div className="line"></div>
                    <div style={{paddingTop: "10px"}}>
                        {/* <span  style={{
                        color:"red",
                        padding: "15px 15px 9px"
                        }}>一寸照片样例(点击查看)</span> */}
                        {/* <ImagePicker
                            files={[{url:ycz}]}
                            onImageClick={this.imgonclick}
                            selectable={false}
                            disableDelete="false"
                            capture="camera"
                        /> */}
                    </div>
                    <div className="scfj"><span style={{color:"red"}}>*</span>一寸照片(红底)(<span onClick={()=>this.setState({ ychdzpyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
                    <ImagePicker
                        files={this.state.yczfiles}
                        onChange={this.yczponChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.yczfiles.length < 4}
                        multiple={this.state.multiple}
                    />
                    <div className="line"></div>
                    <div style={{paddingTop: "10px"}}>
                        {/* <span  style={{
                        color:"red",
                        padding: "15px 15px 9px"
                        }}>一卡通申请单样例(点击查看)<br /> */}

                        {/* <ImagePicker
                            files={[{url:yktsqd}]}
                            onImageClick={this.imgonclick}
                            selectable={false}
                            disableDelete="false"
                            capture="camera"
                        /> */}

                    </div>
                    <div className="scfj"><span style={{color:"red"}}>*</span>一卡通申请单(<span onClick={()=>this.setState({ yktsqdyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
                    <ImagePicker
                        files={this.state.yktsqdfiles}
                        onChange={this.yktChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.yktsqdfiles.length < 4}
                        multiple={this.state.multiple}
                        capture="camera"
                    />
<span style={{
                            color:"#888",
                            padding: "15px 15px 9px"
                            }}>1、申请表单直接<span onClick={()=>this.download()} style={{
                            color:"#108ee9",}}>  下载</span></span>
                             <div style={{color:"#888",paddingLeft: "15px",paddingRight: "15px",paddingBottom: "10px"}}>2、宝石花主页-服务大厅表单-一卡通申请表</div>
                    <div className="formSubmit">
                        <button className="submit" type="submit" onClick={() => this.getSubmit()}>
                            申 请
                    </button>
                    </div>
                </div>
            )
        } else if (this.state.tktlx == "C2") {
            return (
                <div>
                    <div className="scfj">申请信息</div>


                    <InputItem value={this.state.ryxx.ryxm} >
                        <div ><span className="error">*</span>用户姓名</div>
                    </InputItem>
                    {/* <InputItem placeholder="请选择甲方单位" value={this.state.yhdw} onClick={this.showModal('dwsc')}>
                        <div ><span className="error">*</span>所属单位</div>
                    </InputItem>
                    <Modal
                        popup
                        visible={this.state.dwsc}
                        cols={1}
                        value={this.state.yhdws}
                        onClose={this.onClose('dwsc')}
                        animationType="slide-up"
                        style={{
                            height: "280px",
                            overflow: "auto"
                        }}
                    >
                        <SearchBar placeholder="请输入单位名称"  cancelText="查询" onCancel={this.dwsearch} />
                        {this.setdwlist()}

                    </Modal> */}
                    <InputItem placeholder="请输入身份证号" value={this.state.ryxx.sfzh} >
                        <div ><span className="error">*</span>身份证号</div>
                    </InputItem>
                    <Picker
                        data={this.state.xb}
                        cols={1}
                        onChange={this.xbChange}
                        value={this.state.yhxb}
                    >
                        <List.Item arrow="horizontal"><span className="error">*</span>用户性别</List.Item>
                    </Picker>
                    <Picker
                        data={this.state.mz}
                        cols={1}
                        onChange={this.mzChange}
                        value={this.state.yhmz}
                    >
                        <List.Item arrow="horizontal"><span className="error">*</span>民族</List.Item>
                    </Picker>
                    <InputItem placeholder="请输入手机号码" value={this.state.sjhm} onChange={(value) => this.setState({ sjhm: value })}>
                        <div ><span className="error">*</span>手机号码</div>
                    </InputItem>

                    <InputItem placeholder="请选择家庭住址" value={this.state.jtzz} onClick={this.showModal('modal1')}>
                        <div ><span className="error">*</span>家庭住址</div>
                    </InputItem>
                    <Modal
                        popup
                        visible={this.state.modal1}
                        onClose={this.onClose('modal1')}
                        animationType="slide-up"
                        afterClose={() => { console.log('afterClose'); }}
                    >
                        <List renderHeader={() => '详情信息'} >
                            <Picker
                                data={this.state.jtzhlist1}
                                cols={1}
                                onOk={(value) => this.setState({ jtzhid1: value })}
                                value={this.state.jtzhid1}
                            >
                                <List.Item arrow="horizontal">小区</List.Item>
                            </Picker>

                            <Picker
                                data={this.state.jtzhlist2}
                                cols={1}
                                onChange={(value) => this.setState({ jtzhid2: value })}
                                value={this.state.jtzhid2}
                            >
                                <List.Item arrow="horizontal" onClick={() => this.jtzhclick("2")}>楼宇</List.Item>
                            </Picker>

                            <Picker
                                data={this.state.jtzhlist3}
                                cols={1}
                                onChange={(value) => this.setState({ jtzhid3: value })}
                                value={this.state.jtzhid3}
                            >
                                <List.Item arrow="horizontal" onClick={() => this.jtzhclick("3")}>房号</List.Item>
                            </Picker>
                        </List>
                        <List.Item>
                            <Button type="primary" onClick={() => this.jadzmc()}>确认</Button>
                        </List.Item>
                    </Modal>

                    <InputItem placeholder="工作单位" value={this.state.gzdw} onChange={(value) => this.setState({ gzdw: value })}>
                        <div >工作单位</div>
                    </InputItem>
                    <InputItem placeholder="工作地点" value={this.state.gzdd} onChange={(value) => this.setState({ gzdd: value })}>
                        <div >工作地点</div>
                    </InputItem>

                    <InputItem placeholder="选户主房号" value={this.state.fwbh} onClick={this.showModal('fwbhshow')}>
                        <div ><span className="error">*</span>选户主房号</div>
                    </InputItem>
                    <Modal
                        popup
                        visible={this.state.fwbhshow}
                        cols={1}
                        value={this.state.fwbhs}
                        onClose={this.onClose('fwbhshow')}
                        animationType="slide-up"
                        afterClose={() => { console.log('afterClose'); }}
                        style={{
                            height: "280px",
                            overflow: "auto"
                        }}
                    >
                        <SearchBar placeholder="请输入房屋编号" cancelText="查询" onCancel={this.searchhzxm} />
                        {this.setwhbhlist()}
                    </Modal>

                    <InputItem placeholder="户主姓名" value={this.state.fzxm} editable="false" >
                        <div ><span className="error">*</span>户主姓名</div>
                    </InputItem>
                    <div className="scfj" style={{fontSize: "14px",
    color: "#888"}}>上传附件</div>
                    {this.brsfzfj()}
                    {this.yczpfj()}
                    {this.yktfj()}
                    {this.hkbfj()}
                    {this.hzyktfj()}
                    {this.jhzfj()}
                    {this.hzsfzfj()}

                    <div className="formSubmit">
                        <button className="submit" type="submit" onClick={() => this.getSubmit()}>
                            申 请
                    </button>
                    </div>
                </div>
            )
        } else if (this.state.tktlx == "B" || this.state.tktlx == "C3") {
            return (
                <div>
                    <div className="scfj">申请信息</div>
                    <Picker
                        data={this.state.yhlx}
                        cols={1}
                        onOk={this.yhlxChange}
                        value={this.state.yhlxbm}
                    >
                        <List.Item arrow="horizontal"><span className="error">*</span>用户类型:</List.Item>
                    </Picker>
                    <InputItem placeholder="请输入申报姓名" value={this.state.ryxm} onChange={(value) => this.setState({ ryxm: value })}>
                     <div ><span className="error">*</span>申报姓名</div>
                    </InputItem>
                    {/* <InputItem placeholder="请输入申报姓名" value={this.state.sbxm} onChange={(value) => this.setState({ sbxm: value })}>
                        <div ><span className="error">*</span>申报姓名</div>
                    </InputItem> */}

                    <InputItem placeholder="请输入身份证号" value={this.state.sfzh} onChange={(value) => this.setState({ sfzh: value })}>
                        <div ><span className="error">*</span>身份证号</div>
                    </InputItem>
                    <Picker
                        data={this.state.xb}
                        cols={1}
                        onChange={this.xbChange}
                        value={this.state.yhxb}
                    >
                        <List.Item arrow="horizontal"><span className="error">*</span>用户性别</List.Item>
                    </Picker>
                    <Picker
                        data={this.state.mz}
                        cols={1}
                        onChange={this.mzChange}
                        value={this.state.yhmz}
                    >
                        <List.Item arrow="horizontal"><span className="error">*</span>民族</List.Item>
                    </Picker>
                    <InputItem placeholder="请输入手机号码" value={this.state.sjhm} onChange={(value) => this.setState({ sjhm: value })}>
                        <div ><span className="error">*</span>手机号码</div>
                    </InputItem>
                    <Picker
                        data={this.state.sftz}
                        cols={1}
                        onChange={this.sftzChange}
                        value={this.state.sftzText}
                    >
                        <List.Item arrow="horizontal">是否塔指住户</List.Item>
                    </Picker>
                    <InputItem placeholder="请选择家庭住址" value={this.state.jtzz} onClick={this.showModal('modal1')} onChange={(value) => this.setState({ jtzz: value })}>
                        <div ><span className="error">*</span>家庭住址</div>
                    </InputItem>
                    <Modal
                        popup
                        visible={this.state.modal1}
                        onClose={this.onClose('modal1')}
                        animationType="slide-up"
                        afterClose={() => { console.log('afterClose'); }}
                    >
                        <List renderHeader={() => '详情信息'} >
                            <Picker
                                data={this.state.jtzhlist1}
                                cols={1}
                                onOk={(value) => this.setState({ jtzhid1: value })}
                                value={this.state.jtzhid1}
                            >
                                <List.Item arrow="horizontal">小区</List.Item>
                            </Picker>

                            <Picker
                                data={this.state.jtzhlist2}
                                cols={1}
                                onChange={(value) => this.setState({ jtzhid2: value })}
                                value={this.state.jtzhid2}
                            >
                                <List.Item arrow="horizontal" onClick={() => this.jtzhclick("2")}>楼宇</List.Item>
                            </Picker>

                            <Picker
                                data={this.state.jtzhlist3}
                                cols={1}
                                onChange={(value) => this.setState({ jtzhid3: value })}
                                value={this.state.jtzhid3}
                            >
                                <List.Item arrow="horizontal" onClick={() => this.jtzhclick("3")}>房号</List.Item>
                            </Picker>
                        </List>
                        <List.Item>
                            <Button type="primary" onClick={() => this.jadzmc()}>确认</Button>
                        </List.Item>
                    </Modal>

                    <InputItem placeholder="工作单位" value={this.state.gzdw} onChange={(value) => this.setState({ gzdw: value })}>
                        <div >工作单位</div>
                    </InputItem>
                    <InputItem placeholder="工作地点" value={this.state.gzdd} onChange={(value) => this.setState({ gzdd: value })}>
                        <div >工作地点</div>
                    </InputItem>
                    <Picker
                        data={this.state.yhzgx}
                        cols={1}
                        onChange={this.yhzgxChange}
                        value={this.state.hzgx}
                    >
                        <List.Item arrow="horizontal">与户主关系</List.Item>
                    </Picker>



                    <InputItem placeholder="户主姓名" value={this.state.fzxmvalue} onClick={this.showModal('modalhzxm')}>
                        <div ><span className="error">*</span>户主姓名</div>
                    </InputItem>
                    <Modal
                        popup
                        visible={this.state.modalhzxm}
                        cols={1}
                        value={this.state.fzxm}
                        onClose={this.onClose('modalhzxm')}
                        animationType="slide-up"
                        afterClose={() => { console.log('afterClose'); }}
                        style={{
                            height: "280px",
                            overflow: "auto"
                        }}
                    >
                        <SearchBar placeholder="请输入房主姓名" maxLength={8} cancelText="查询" onCancel={this.search} />
                        {this.SerMapDw()}

                    </Modal>

                    <InputItem placeholder="房主一卡通号" value={this.state.fzykth} editable="false" >
                        <div ><span className="error">*</span>房主一卡通号</div>
                    </InputItem>
                    <InputItem placeholder="请选择甲方单位" value={this.state.yhdw} onClick={this.showModal('dwsc')} editable="false">
                        <div ><span className="error">*</span>户主单位</div>
                    </InputItem>
                    <Modal
                        popup
                        visible={this.state.dwsc}
                        cols={1}
                        value={this.state.yhdws}
                        onClose={this.onClose('dwsc')}
                        animationType="slide-up"
                        style={{
                            height: "280px",
                            overflow: "auto"
                        }}
                    >
                        <SearchBar placeholder="请输入单位名称"  cancelText="查询" onCancel={this.dwsearch} />
                        {this.setdwlist()}

                    </Modal>
                    <div className="scfj" style={{fontSize: "14px",
    color: "#888"}}>上传附件</div>
                    {this.brsfzfj()}
                    {this.yczpfj()}
                    {this.yktfj()}
                    {this.hkbfj()}
                    {this.hzyktfj()}
                    {this.jhzfj()}
                    {this.hzsfzfj()}
                    <div className="formSubmit">
                        <button className="submit" type="submit" onClick={() => this.getSubmit()}>
                            申 请
                    </button>
                    </div>
                </div>

            )
        } else if (this.state.tktlx == "C4") {
            return (
                <div>
                    <div className="scfj">申请信息</div>
                    <Picker
                        data={this.state.yhlx}
                        cols={1}
                        onOk={this.yhlxChange}
                        value={this.state.yhlxbm}
                    >
                        <List.Item arrow="horizontal"><span className="error">*</span>用户类型:</List.Item>
                    </Picker>
                    <InputItem placeholder="请输入申报姓名" value={this.state.ryxm} onChange={(value) => this.setState({ ryxm: value })}>
                     <div ><span className="error">*</span>申报姓名</div>
                    </InputItem>
                    {/* <InputItem placeholder="请输入申报姓名" value={this.state.sbxm} onChange={(value) => this.setState({ sbxm: value })}>
                        <div ><span className="error">*</span>申报姓名</div>
                    </InputItem> */}

                    <InputItem placeholder="请输入身份证号" value={this.state.sfzh} onChange={(value) => this.setState({ sfzh: value })}>
                        <div ><span className="error">*</span>身份证号</div>
                    </InputItem>
                    <Picker
                        data={this.state.xb}
                        cols={1}
                        onChange={this.xbChange}
                        value={this.state.yhxb}
                    >
                        <List.Item arrow="horizontal"><span className="error">*</span>用户性别</List.Item>
                    </Picker>
                    <Picker
                        data={this.state.mz}
                        cols={1}
                        onChange={this.mzChange}
                        value={this.state.yhmz}
                    >
                        <List.Item arrow="horizontal"><span className="error">*</span>民族</List.Item>
                    </Picker>
                    <InputItem placeholder="请输入手机号码" value={this.state.sjhm} onChange={(value) => this.setState({ sjhm: value })}>
                        <div ><span className="error">*</span>手机号码</div>
                    </InputItem>
                    <Picker
                        data={this.state.sftz}
                        cols={1}
                        onChange={this.sftzChange}
                        value={this.state.sftzText}
                    >
                        <List.Item arrow="horizontal">是否塔指住户</List.Item>
                    </Picker>
                    <InputItem placeholder="请选择家庭住址" value={this.state.jtzz} onClick={this.showModal('modal1')} onChange={(value) => this.setState({ jtzz: value })}>
                        <div ><span className="error">*</span>家庭住址</div>
                    </InputItem>
                    <Modal
                        popup
                        visible={this.state.modal1}
                        onClose={this.onClose('modal1')}
                        animationType="slide-up"
                        afterClose={() => { console.log('afterClose'); }}
                    >
                        <List renderHeader={() => '详情信息'} >
                            <Picker
                                data={this.state.jtzhlist1}
                                cols={1}
                                onOk={(value) => this.setState({ jtzhid1: value })}
                                value={this.state.jtzhid1}
                            >
                                <List.Item arrow="horizontal">小区</List.Item>
                            </Picker>

                            <Picker
                                data={this.state.jtzhlist2}
                                cols={1}
                                onChange={(value) => this.setState({ jtzhid2: value })}
                                value={this.state.jtzhid2}
                            >
                                <List.Item arrow="horizontal" onClick={() => this.jtzhclick("2")}>楼宇</List.Item>
                            </Picker>

                            <Picker
                                data={this.state.jtzhlist3}
                                cols={1}
                                onChange={(value) => this.setState({ jtzhid3: value })}
                                value={this.state.jtzhid3}
                            >
                                <List.Item arrow="horizontal" onClick={() => this.jtzhclick("3")}>房号</List.Item>
                            </Picker>
                        </List>
                        <List.Item>
                            <Button type="primary" onClick={() => this.jadzmc()}>确认</Button>
                        </List.Item>
                    </Modal>

                    <InputItem placeholder="工作单位" value={this.state.gzdw} onChange={(value) => this.setState({ gzdw: value })}>
                        <div >工作单位</div>
                    </InputItem>
                    <InputItem placeholder="工作地点" value={this.state.gzdd} onChange={(value) => this.setState({ gzdd: value })}>
                        <div >工作地点</div>
                    </InputItem>
                    <Picker
                        data={this.state.yhzgx}
                        cols={1}
                        onChange={this.yhzgxChange}
                        value={this.state.hzgx}
                    >
                        <List.Item arrow="horizontal">与户主关系</List.Item>
                    </Picker>



                    <InputItem placeholder="户主姓名" value={this.state.fzxmvalue} onClick={this.showModal('modalhzxm')}>
                        <div >户主姓名</div>
                    </InputItem>
                    <Modal
                        popup
                        visible={this.state.modalhzxm}
                        cols={1}
                        value={this.state.fzxm}
                        onClose={this.onClose('modalhzxm')}
                        animationType="slide-up"
                        afterClose={() => { console.log('afterClose'); }}
                        style={{
                            height: "280px",
                            overflow: "auto"
                        }}
                    >
                        <SearchBar placeholder="请输入房主姓名" maxLength={8} cancelText="查询" onCancel={this.search} />
                        {this.SerMapDw()}

                    </Modal>

                    <InputItem placeholder="房主一卡通号" value={this.state.fzykth} editable="false" >
                        <div >房主一卡通号</div>
                    </InputItem>
                    <InputItem placeholder="请选择甲方单位" value={this.state.yhdw} onClick={this.showModal('dwsc')} editable="false">
                        <div >户主单位</div>
                    </InputItem>
                    <Modal
                        popup
                        visible={this.state.dwsc}
                        cols={1}
                        value={this.state.yhdws}
                        onClose={this.onClose('dwsc')}
                        animationType="slide-up"
                        style={{
                            height: "280px",
                            overflow: "auto"
                        }}
                    >
                        <SearchBar placeholder="请输入单位名称"  cancelText="查询" onCancel={this.dwsearch} />
                        {this.setdwlist()}

                    </Modal>
                    <div className="scfj" style={{fontSize: "14px",
    color: "#888"}}>上传附件</div>
                    {this.brsfzfj()}
                    {this.yczpfj()}
                    {this.yktfj()}
                    {this.hkbfj()}
                    {this.hzyktfj()}
                    {this.jhzfj()}
                    {this.hzsfzfj()}
                    <div className="formSubmit">
                        <button className="submit" type="submit" onClick={() => this.getSubmit()}>
                            申 请
                    </button>
                    </div>
                </div>

            )
        }
    }
    //类型选择
    handleFromChange = (e) => {
        console.log(e)
        if (e == 'B') {
            this.setState({
                lb: "B"
            });
        } else if (e == 'C1' || e == 'C2' || e == 'C3'|| e == 'C4') {
            this.setState({
                lb: "C"
            });
        }

        this.setState({
            tktlx: e
        }, () => {
            this.getyhlx();
            this.getyhzgx();
            if(e=='C2'){
                this.getfjlx();
            }
            if(e=="C2"){
                this.setState({
                    yhlxbm:"00007",
                    sjhm: this.state.ryxx.rysx20120716p0048,
                    sfzh: this.state.ryxx.sfzh,
                    ryxm: this.state.ryxx.ryxm
                });
            }else if(e=="C1"){
                this.setState({
                    yhlxbm:"00011",
                    sjhm: this.state.ryxx.rysx20120716p0048,
                    sfzh: this.state.ryxx.sfzh,
                    ryxm: this.state.ryxx.ryxm
                });
            }else if(e=="B"){
                this.setState({
                    yhlxbm:"",
                    sjhm: "",
                    sfzh: "",
                    ryxm: ""
                });
            }
        });

    }

    jadzmc = () => {
        const list = this.state.jtzhlist3
        for (var item in list) {
            if (list[item].value == this.state.jtzhid3) { //item 表示Json串中的属性，如'name'
                var jValue = list[item];//key所对应的value
                console.log(jValue.label);
                this.setState({
                    jtzz: jValue.label,
                    modal1: false,
                });
            }
        }
    }

    yhlxChange = (e) => {
        console.log(e)
        this.setState({
            yhlxbm: e
        });
        const list = this.state.yhlx
        for (var item in list) {
            if (list[item].value == e) { //item 表示Json串中的属性，如'name'
                var jValue = list[item];//key所对应的value
                console.log(jValue.label);
                this.setState({
                    yhlxmc: jValue.label
                }, () => {
                    this.getfjlx();
                });
                //   this.state.yhlxmc = jValue.label;
            }
        }
    }

    xbChange = (e) => {
        console.log(e)
        this.setState({
            yhxb: e
        });
    }

    mzChange = (e) => {
        console.log(e)
        const list = this.state.mz
        for (var item in list) {
            if (list[item].value == e) { //item 表示Json串中的属性，如'name'
                var jValue = list[item];//key所对应的value
                this.setState({
                    yhmzText: jValue.label,
                });
            }
        }

        this.setState({
            yhmz: e
        });
    }

    yhzgxChange = (e) => {
        console.log(e)
        this.setState({
            hzgx: e
        });
    }

    sftzChange = (e) => {
        console.log(e)
        if(e=="否"){
            this.setState({
                jtzz:""
            });
        }
        this.setState({
            sftzText: e
        });
    }


    showModal = key => (e) => {
        if(this.state.sftzText=="否"&&key=="modal1"){
            return;
        }
        this.setState({
            [key]: true,
        });
    }

    onClose = key => (e) => {
        this.setState({
            [key]: false,
        });
    }

    search = (val) => {
        // this.getgzxm(val);
        console.log(val);
        this.setState({
            fzxm: val
        }, () => {
            this.getgzxm();
        });
    }

    dwsearch = (val) => {
        // this.getgzxm(val);
        console.log(val);
        this.setState({
            yhdws: val
        }, () => {
            this.getdwxm();
        });
    }

    searchhzxm = (val) => {
        console.log(val);
        this.setState({
            fwbhs: val
        }, () => {
            this.getfwbhhzxx();
        });
    }

    fzonChange = (e) => {
        console.log(e)
        this.setState({
            modalhzxm: false,
            fzxmvalue: e.label,
            fzxmid: e.ryid,
            fzykth: e.yktzh
        });
    }

    fwryxxChange = (e) => {
        console.log(e)
        this.setState({
            fwbhshow: false,
            fwbh: e.fwbh.substring(12),
            fzxm: e.ryxm,
        });
    }

    dwChange = (e) => {
        console.log(e)
        this.setState({
            dwsc: false,
            yhdw: e.dwjc,
            yhdwbm:e.value
        });
    }

    jtzhclick = (e) => {
        console.log(e);
        if (e == "2") {
            if (this.state.jtzhid1 != "") {
                this.getjtzz(this.state.jtzhid1, "2");
            } else {
                Toast.info('请先选择一级单位', 3, null, false);
            }
        }
        if (e == "3") {
            if (this.state.jtzhid2 != "") {
                this.getjtzz(this.state.jtzhid2, "3");
            } else {
                Toast.info('请先选择二级单位', 3, null, false);
            }
        }
    }

    onChange = (brsfzfiles, type, index) => {
        console.log(brsfzfiles);
        var str=[];
        if(type!="remove"){
        for(let i=0;i<brsfzfiles.length;i++){

              lrz(brsfzfiles[i].url, { quality: 0.1 }) .then((rst) => {
                console.log(rst)
                let file = new File([rst.file],this.genID(16)+"."+brsfzfiles[i].file.name.split(".")[1], { type: brsfzfiles[i].file.type, lastModified: Date.now() });
                var arr = [{ orientation: 1, url: rst.base64, file: file }];
                str.push(arr[0])
                this.setState({
                    brsfzfiles: str,
                })
              })

          }
        }else{
            this.setState({
              brsfzfiles,
          });
          }
        // this.setState({
        //     brsfzfiles,
        // });
    }

    yczponChange = (yczfiles, type, index) => {
        var str=[];
        if(type!="remove"){
        for(let i=0;i<yczfiles.length;i++){

              lrz(yczfiles[i].url, { quality: 0.1 }) .then((rst) => {
                console.log(rst)
                let file = new File([rst.file], this.genID(16)+"."+yczfiles[i].file.name.split(".")[1], { type: yczfiles[i].file.type, lastModified: Date.now() });
                var arr = [{ orientation: 1, url: rst.base64, file: file }];
                str.push(arr[0])
                this.setState({
                    yczfiles: str,
                })
              })

          }
        }else{
            this.setState({
              yczfiles,
          });
          }
        // console.log(yczfiles, type, index);
        // this.setState({
        //     yczfiles,
        // });
    }

    yktChange = (yktsqdfiles, type, index) => {
        var str=[];
        if(type!="remove"){
        for(let i=0;i<yktsqdfiles.length;i++){

              lrz(yktsqdfiles[i].url, { quality: 0.1 }) .then((rst) => {
                console.log(rst)
                let file = new File([rst.file], this.genID(16)+"."+yktsqdfiles[i].file.name.split(".")[1], { type: yktsqdfiles[i].file.type, lastModified: Date.now() });
                var arr = [{ orientation: 1, url: rst.base64, file: file }];
                str.push(arr[0])
                this.setState({
                    yktsqdfiles: str,
                })
              })

          }
        }else{
            this.setState({
              yktsqdfiles,
          });
          }
        // console.log(yktsqdfiles, type, index);
        // this.setState({
        //     yktsqdfiles,
        // });
    }

    hkbChange = (hkbfiles, type, index) => {
        var str=[];
        if(type!="remove"){
        for(let i=0;i<hkbfiles.length;i++){

              lrz(hkbfiles[i].url, { quality: 0.1 }) .then((rst) => {
                console.log(rst)
                let file = new File([rst.file], this.genID(16)+"."+hkbfiles[i].file.name.split(".")[1], { type: hkbfiles[i].file.type, lastModified: Date.now() });
                var arr = [{ orientation: 1, url: rst.base64, file: file }];
                str.push(arr[0])
                this.setState({
                    hkbfiles: str,
                })
              })

          }
        }else{
            this.setState({
              hkbfiles,
          });
          }
        // console.log(hkbfiles, type, index);
        // this.setState({
        //     hkbfiles,
        // });
    }

    hzyktChange = (hzyktfiles, type, index) => {
        var str=[];
        if(type!="remove"){
        for(let i=0;i<hzyktfiles.length;i++){

              lrz(hzyktfiles[i].url, { quality: 0.1 }) .then((rst) => {
                console.log(rst)
                let file = new File([rst.file], this.genID(16)+"."+hzyktfiles[i].file.name.split(".")[1], { type: hzyktfiles[i].file.type, lastModified: Date.now() });
                var arr = [{ orientation: 1, url: rst.base64, file: file }];
                str.push(arr[0])
                this.setState({
                    hzyktfiles: str,
                })
              })

          }
        }else{
            this.setState({
              hzyktfiles,
          });
          }
        // console.log(hzyktfiles, type, index);
        // this.setState({
        //     hzyktfiles,
        // });
    }

    jhzChange = (jhzfiles, type, index) => {
        console.log(jhzfiles, type, index);
        this.setState({
            jhzfiles,
        });
    }

    hzsfzChange = (hzsfzfiles, type, index) => {
        var str=[];
        if(type!="remove"){
        for(let i=0;i<hzsfzfiles.length;i++){

              lrz(hzsfzfiles[i].url, { quality: 0.1 }) .then((rst) => {
                console.log(rst)
                let file = new File([rst.file], this.genID(16)+"."+hzsfzfiles[i].file.name.split(".")[1], { type: hzsfzfiles[i].file.type, lastModified: Date.now() });
                var arr = [{ orientation: 1, url: rst.base64, file: file }];
                str.push(arr[0])
                this.setState({
                    hzsfzfiles: str,
                })
              })

          }
        }else{
            this.setState({
              hzsfzfiles,
          });
          }
        // console.log(hzsfzfiles, type, index);
        // this.setState({
        //     hzsfzfiles,
        // });
    }

    async getSubmit() {
        console.log(this.state.ryxm);
        console.log(this.state.yhdwbm);
        console.log(this.state.sfzh);
        console.log(this.state.yhxb);
        console.log(this.state.yhmzText);
        console.log(this.state.sjhm);
        console.log(this.state.jtzz);
        if(this.state.tktlx == "B" || this.state.tktlx == "C3"){
            if(this.state.ryxm==""||this.state.yhdwbm==""||this.state.sfzh==""||this.state.yhxb==""||this.state.yhmzText==""||this.state.sjhm==""||this.state.jtzz==""||this.state.fzxm==""||this.state.fzykth==""){
                Toast.info("请将表单填写完整", 4, null, false);
                return;
            }

        }else if(this.state.tktlx == "C1"){
            if(this.state.ryxm==""||this.state.yhdwbm==""||this.state.sfzh==""||this.state.yhxb==""||this.state.yhmzText==""||this.state.sjhm==""||this.state.jtzz==""){
                Toast.info("请将表单填写完整", 4, null, false);
                return;
            }

        }else if(this.state.tktlx == "C2"){
            if(this.state.ryxm==""||this.state.sfzh==""||this.state.yhxb==""||this.state.yhmzText==""||this.state.sjhm==""||this.state.jtzz==""||this.state.fwbh==""||this.state.fzxm==""){
                Toast.info("请将表单填写完整", 4, null, false);
                return;
            }
        }else if(this.state.tktlx == "C4"){
            if(this.state.ryxm==""||this.state.yhdwbm==""||this.state.sfzh==""||this.state.yhxb==""||this.state.yhmzText==""||this.state.sjhm==""||this.state.jtzz==""){
                Toast.info("请将表单填写完整", 4, null, false);
                return;
            }
        }
        console.log("判断是否可以提交");
        const url = WY_URL + 'yktsqxx/checkYhykt';
        var params = new URLSearchParams();
        console.log(this.state.sfzh);
        params.append("yhsfzh", this.state.sfzh);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res.data.data);
            if (res.status === 200) {
                if(res.data.data.check){
                    this.dataSubmit();
                }else{
                    Toast.info(res.data.data.msg, 4, null, false);
                }

            }
        });
    }

    dataSubmit = () => {
        const url = WY_URL + 'yktsqxx/save';


        let params = new FormData();

        params.append('yktlx', this.state.lb);
        params.append('yhxm', this.state.ryxm);
        params.append('sbxm', this.state.sbxm);

        params.append('yhsfzh', this.state.sfzh);
        params.append('yhxb', this.state.yhxb);
        params.append('yhmz', this.state.yhmzText);
        params.append('yhsjhm', this.state.sjhm);
        if(this.state.tktlx=="C2"){
            params.append('zz', this.state.fwbh);
            params.append('hzxm', this.state.fzxm);
            params.append('dwmc', "宝石花物业塔里木油田地区公司");
            params.append('dwbm', "CNPC.PetroChina.TLM..WYGLZX");
        }else if(this.state.tktlx=="C4"){
            params.append('dwmc', "宝石花物业塔里木油田地区公司");
            params.append('dwbm', "CNPC.PetroChina.TLM..WYGLZX");
        }else{
            params.append('zz', this.state.jtzz);
            params.append('hzxm', this.state.fzxmvalue);
            params.append('dwmc', this.state.yhdw);
            params.append('dwbm', this.state.yhdwbm);
        }
        params.append('gzdw', this.state.gzdw);
        params.append('gzdd', this.state.gzdd);
        params.append('yhzgx', this.state.hzgx);
        params.append('hzzh', this.state.fzykth);
        params.append('yhlxid', this.state.yhlxbm);
        params.append('dqzt', "手机申请");
        let fileinfo = []
        if (this.state.brsfzfiles.length !== 0) {
            for (let f of this.state.brsfzfiles) {
                params.append("fjList", f.file);
                fileinfo.push({ name: f.file.name, type: '2' });
            }
            // params.append('fileinfo', JSON.stringify(fileinfo));
        }

        if (this.state.hkbfiles.length !== 0) {
            for (let f of this.state.hkbfiles) {
                params.append("fjList", f.file);
                fileinfo.push({ name: f.file.name, type: '10' });
            }
            // params.append('fileinfo', JSON.stringify(fileinfo));
        }

        if (this.state.hzyktfiles.length !== 0) {
            for (let f of this.state.hzyktfiles) {
                params.append("fjList", f.file);
                fileinfo.push({ name: f.file.name, type: '6' });
            }
            // params.append('fileinfo', JSON.stringify(fileinfo));
        }

        if (this.state.jhzfiles.length !== 0) {
            for (let f of this.state.jhzfiles) {
                params.append("fjList", f.file);
                fileinfo.push({ name: f.file.name, type: '3' });
            }
            // params.append('fileinfo', JSON.stringify(fileinfo));
        }

        if (this.state.yczfiles.length !== 0) {
            for (let f of this.state.yczfiles) {
                params.append("fjList", f.file);
                fileinfo.push({ name: f.file.name, type: '5' });
            }
            // params.append('fileinfo', JSON.stringify(fileinfo));
        }

        if (this.state.hzsfzfiles.length !== 0) {
            for (let f of this.state.hzsfzfiles) {
                params.append("fjList", f.file);
                fileinfo.push({ name: f.file.name, type: '7' });
            }
            // params.append('fileinfo', JSON.stringify(fileinfo));
        }

        if (this.state.yktsqdfiles.length !== 0) {
            for (let f of this.state.yktsqdfiles) {
                params.append("fjList", f.file);
                fileinfo.push({ name: f.file.name, type: '1' });
            }
            // params.append('fileinfo', JSON.stringify(fileinfo));
        }
        params.append('fileinfo', JSON.stringify(fileinfo));
        Toast.loading("加载中...", 0, null, true);
        Axios({
            headers: { 'Content-Type': 'multipart/form-data' },
            method: 'post',
            url: url,
            data: params,
            //这部分非常重要，否则formdata会被转格式
            // transformRequest: [function () {
            //     return params;
            // }],
        }).then((res) => {
            Toast.hide();
            if (res.status === 200) {
                Toast.info(res.data.msg, 2, null, false);
            }
            this.props.history.go(-1)

        });


    }




    render() {
        console.log(this.state.brsfzfiles)
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >一卡通申请</NavBar>
                <div className="divmagin">
                    <List className="my-list">
                        <Picker
                            data={this.state.tktlxList}
                            cols={1}
                            onOk={this.handleFromChange}
                            value={this.state.tktlx}
                        >
                            <List.Item >申请类型：</List.Item>
                        </Picker>
                        <div className="scfj">申请说明</div>
                            <div style={{color:'red',lineHeight:'25px',fontSize:'15px',margin:'10px 10px 10px 10px'}}>提交一卡通申请后，请联系本单位管理员审核并送审，否则无法正常办理！</div>
                        {/* <p style={{color:'#C0C0C0',lineHeight:'25px',fontSize:'15px',margin:'10px 10px 10px 10px'}}> */}
                            <div style={{color:'#C0C0C0',lineHeight:'25px',fontSize:'15px',margin:'10px 10px 10px 10px'}} dangerouslySetInnerHTML={{ __html:this.state.smxx}}></div>
                            {/* </p> */}

                        {this.setContent()}
                        <Modal
                    visible={ this.state.sfzyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClosesfz(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={SFZ_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
                <Modal
                    visible={ this.state.ychdzpyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClosehdzp(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={ycz}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
                <Modal
                    visible={ this.state.yktyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onCloseykt(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={YKT_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
                <Modal
                    visible={ this.state.hkbyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClosehkb(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={hkb}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
                <Modal
                    visible={ this.state.jhzyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClosejhz(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={JHZ_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
                <Modal
                    visible={ this.state.yktsqdyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onCloseyktsqd(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={yktsqd}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
                    </List>

                </div>
            </div>
        )
    }
}
