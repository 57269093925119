import './clxxOrLc.css'
import React, { Component } from 'react'
import { BASE_URL,YL_URL, Axios } from '../../../utils/url'
import Wxzf from '../../../assets/images/wxzf.png'
import Zfbzf from '../../../assets/images/zfbzf.png'
import { NavBar, Icon, Tabs, List, InputItem, TextareaItem, Toast, Modal, Radio, Button ,Picker,ImagePicker} from 'antd-mobile';
const RadioItem = Radio.RadioItem;
const tabs = [
    { title: "车辆申请信息" },
    { title: '审核流程信息' }
];
const Item = List.Item;
const Brief = Item.Brief;


export default class clxxOrLc extends Component {
    constructor(props) {
        super(props);
        var clxx ="";

        if(localStorage.getItem("clxx")){

            clxx = JSON.parse(localStorage.getItem("clxx"));
        }else{
            clxx = this.props.location.query.clxx;
        }
        console.log(clxx)
        // if(this.props.location.query == undefined){
        //     clxx =   localStorage.getItem("clxx")
        // }else{
        //     clxx = this.props.location.query.clxx;
        // }
        // return;





        let isBgBool = false;
        if (clxx.yxdqzt === '已发放') {
            isBgBool = true;
        }
        this.state = {
            cph: clxx.cph,
            clxx: clxx,
            cllcxx: [],
            modal: false,
            id: "",
            isLoaded: false,
            djfisLoaded: false,
            zfdz: "",
            merOrderId: "",
            isPay: false,
            dqzt: clxx.yxdqzt,
            isBg: isBgBool,
            value: "",
            modal2: false,
            bglx:"",
            bglxtext:"",

            showxs:false,
            showqsbg:false,
            xssjh:"",
            xszfiles:[],
            yktfiles:[],
            sfzfiles:[],
            jhzfiles:[],
            clcrsqdfiles:[],
            xmhtfiles:[],
            ldhtfiles:[]

        }
    }
    componentDidMount = () => {
        // this.getdjmxList();
        this.getFjList();
        // this.getClLcxx();


    }
    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }
    onChange = (value, label) => {
        this.setState({
            value,
        });
    };
    checkOrder = () => {
        if (this.state.id.je === 0) {

            const url = BASE_URL + 'bjfjlController/save';
            var params = new URLSearchParams();
            //获取车辆缴费信息，传递参数车牌号
            params.append("jfid", this.state.id.jfid);
            params.append("zfzt", "已缴费");
            params.append("jffs", "现金支付");
            params.append("cph", this.state.cph);
            params.append("sl", this.state.clxx.sl);
            params.append("je", this.state.clxx.je);
            params.append("dj", this.state.clxx.dj);
            params.append("jldw", this.state.clxx.jldw);
            params.append("jfxmmc", this.state.clxx.jfxmmc);
            params.append("jfxmid", this.state.clxx.jfxmid);
            params.append("jfje", this.state.clxx.jfje);

            Toast.loading("加载中...", 0, null, true);
            Axios({
                method: 'post',
                url: url,
                data: params
            }).then((res) => {
                Toast.hide();
                if (res.status == 200) {
                    Toast.info("缴费成功", 1, null, false);
                    this.props.history.go(-1)
                }
            });
        } else {
            this.setState({
                modal: true
            });
        }
    }
    //判断是否是微信浏览器的函数
    isWeiXin() {
        //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
        var ua = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true;
        } else {
            return false;
        }
    }

    //下单
    handleOrder = () => {

        if (this.state.value === "") {
            Toast.info("请选择支付方式", 1, null, false);
        } else {
            // const url = YL_URL + 'xiadanController/order';
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            var  url ="";
            if(this.isWeiXin()){
                url =YL_URL + 'xiadanWXController/order'
            }else{
                url = YL_URL + 'xiadanController/order';
            }
            var params = new URLSearchParams();
            params.append('totalAmount', this.state.id.je);
            params.append('goodsId', this.state.id.jfid);
           // params.append('goodsName', this.state.id.bz);
           params.append('goodsName', "车辆缴费");
            params.append('id', this.state.id.cph);
            params.append('price', this.state.id.je);
            params.append('payMethod', this.state.value);
            params.append('ywbm', "CLJF");
            params.append('yhid',  userInfo.sfzh);
            params.append('yhxm',  userInfo.ryxm);
            Toast.loading("加载中...", 0, null, true);
            Axios({
                method: 'post',
                url: url,
                data: params
            }).then((res) => {
                Toast.hide();
                if(this.state.value=="WXPay.jsPay"){
                    if (res.status == '200') {
                    window.parent.frames.location.href = res.data.data;
                } else {
                    alert("支付失败，请重新支付");
                }
                }else{
                if (res.status == '200') {
                    let jsonData = JSON.parse(res.data.data);
                    if (jsonData.errCode == 'SUCCESS' && jsonData.xdmsg == "下单成功!") {
                        let payJson = JSON.parse(jsonData.respStr);
                        this.setState((state, props) => {
                            return {
                                zfdz: payJson.appPayRequest.qrCode,
                                merOrderId: payJson.merOrderId
                            }
                        },
                            () => {
                                //支付
                                Modal.alert('确认支付？', '确认支付？', [
                                    { text: '取消', onPress: () => console.log('下次一定') },
                                    {
                                        text: '确定',
                                        onPress: () =>
                                            new Promise((resolve) => {
                                                this.setState({
                                                    isPay: true
                                                });
                                                //跳转到登录
                                                window.open(this.state.zfdz);
                                                window.location.href = this.state.zfdz;
                                                this.props.history.push({ pathname: '/jfqr', query: { merOrderId: this.state.merOrderId } });
                                                setTimeout(resolve, 500);

                                            }),
                                    },
                                ])
                            }
                        )
                    } else {
                        Toast.info("支付失败，请重新支付", 2, null, false)
                    }
                }
            }
            });
        }



    }
    //获取当前车辆缴费信息
    async getdjmxList() {
        const url = BASE_URL + 'bjfjlController/getList';
        let dqzt = "待缴费";
        if (this.state.dqzt === "已审核") {
            dqzt = "待缴费";
        } else if (this.state.dqzt === "已缴费" || this.state.dqzt === "已发放") {
            dqzt = "已缴费";
        }
        var params = new URLSearchParams();
        //获取车辆缴费信息，传递参数车牌号
        params.append("cph", this.state.cph);
        params.append("dqzt", dqzt);
        Toast.loading("加载中...", 0, null, true);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            Toast.hide();
            // console.log("我需要的信息", res);
            if (res.status == 200) {
                console.log("我需要的信息", res);
                this.setState({
                    id: res.data[0],
                    djfisLoaded: true
                });

            }
        });
    }
    async getClLcxx() {
        const url = BASE_URL + 'bclcrsqbController/getClLcxx';
        console.log(this.state.clxx.gzlid)
        var params = new URLSearchParams();

        params.append("gzlid", this.state.clxx.gzlid);
        params.append("slid", this.state.clxx.slid);
        params.append("djid", this.state.clxx.sqid);
        //获取人员身份证号并传递参数
        Toast.loading("加载中...", 0, null, true);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            Toast.hide();
            if (res.status == '200') {
                this.setState({
                    cllcxx: res.data,
                    isLoaded: true
                });
            }
        });
    }
    async getFjList(){

        const url = BASE_URL + 'bclcrsqbController/getFjList';
        var params = new URLSearchParams();
        params.append("cph", this.state.clxx.sqid);
        //获取人员身份证号并传递参数
        Toast.loading("加载中...", 0, null, true);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            Toast.hide();
            console.log(res)
            if (res.status == '200') {
                // this.setState({
                //     fjlist: res.data,
                //     isLoaded: true
                // });

                this.setState({
                    fjlist: res.data,
                    isLoaded: true
                },()=>{

                    const list = this.state.fjlist
                    let falg = false;
                    let arr = [];
                    let arr2 = [];
                    let arr3 = [];
                    let arr4 = [];
                    let arr5 = [];
                    let arr6 = [];
                    let arr7 = [];
                    for (var item in list) {
                        if (list[item].fjlb == "2") {
                            falg = true;
                            arr.push(list[item]);
                        }
                        if (list[item].fjlb == "8") {
                            falg = true;
                            arr2.push(list[item]);
                        }
                        if (list[item].fjlb == "5") {
                            falg = true;
                            arr3.push(list[item]);
                        }
                        if (list[item].fjlb == "3") {
                            falg = true;
                            arr4.push(list[item]);
                        }
                        if (list[item].fjlb == "4") {
                            falg = true;
                            arr5.push(list[item]);
                        }
                        if (list[item].fjlb == "6") {
                            falg = true;
                            arr6.push(list[item]);
                        }
                        if (list[item].fjlb == "1") {
                            falg = true;
                            arr7.push(list[item]);
                        }
                    }
                    this.setState({
                        sfzfiles:arr,
                        xmhtfiles:arr2,
                        yktfiles:arr3,
                        jhzfiles:arr4,
                        ldhtfiles:arr5,
                        xszfiles:arr6,
                        clcrsqdfiles:arr7,
                    })

                });
            }
        });
    }
    xszfj = () => {
        console.log(this.state.xszfiles)
        if (this.state.xszfiles.length>0) {
            return (
                <div>
                    <div className="scfj">行驶证</div>
                    <ImagePicker
                        files={this.state.xszfiles}
                        disableDelete="true"
                        selectable={false}
                    />
                </div>
            )
        } else {
            return null;

        }
    }
    jhzfj = () => {

        if (this.state.jhzfiles.length>0) {
            return (
                <div>
                    <div className="scfj">结婚证</div>
                    <ImagePicker
                        files={this.state.jhzfiles}
                        disableDelete="true"
                        selectable={false}
                    />
                </div>
            )
        } else {
            return null;

        }
    }
    sfzfj = () => {

        if (this.state.sfzfiles.length>0) {
            return (
                <div>
                    <div className="scfj">身份证</div>
                    <ImagePicker
                        files={this.state.sfzfiles}
                        disableDelete="true"
                        selectable={false}
                    />
                </div>
            )
        } else {
            return null;

        }
    }
    clcrsqdfj = () => {

        if (this.state.clcrsqdfiles.length>0) {
            return (
                <div>
                    <div className="scfj">车辆出入申请单</div>
                    <ImagePicker
                        files={this.state.clcrsqdfiles}
                        disableDelete="true"
                        selectable={false}
                    />
                </div>
            )
        } else {
            return null;

        }
    }
    ldhtfj = () => {

        if (this.state.ldhtfiles.length>0) {
            return (
                <div>
                    <div className="scfj">劳动合同</div>
                    <ImagePicker
                        files={this.state.ldhtfiles}
                        disableDelete="true"
                        selectable={false}
                    />
                </div>
            )
        } else {
            return null;

        }
    }
    xmhtfj = () => {

        if (this.state.xmhtfiles.length>0) {
            return (
                <div>
                    <div className="scfj">项目合同</div>
                    <ImagePicker
                        files={this.state.xmhtfiles}
                        disableDelete="true"
                        selectable={false}
                    />
                </div>
            )
        } else {
            return null;

        }
    }
    yktfj = () => {

        if (this.state.yktfiles.length>0) {
            return (
                <div>
                    <div className="scfj">一卡通</div>
                    <ImagePicker
                        files={this.state.yktfiles}
                        disableDelete="true"
                        selectable={false}
                    />
                </div>
            )
        } else {
            return null;

        }
    }
    // setMapList = () => {
    //     const filesxsz = this.state.fjlist;

    //     //行驶证附件
    //     if (filesxsz != '' && filesxsz != undefined) {
    //         return this.state.fjlist.map((item, key) => (
    //             <img className="fjimg" src = {"data:image/png;base64,"+item.url} />
    //         ))
    //     }
    // }
    setLcList = () => {
        if (!this.state.isLoaded) {
            return null;
        } else {
            return this.state.cllcxx.map((item, key) => (
                <Item multipleLine extra={item.dqzt} key={key} >
                    {item.jyhxm} <Brief>{item.sqr}</Brief>
                    <Brief>{item.shyj}</Brief>
                </Item>
            ))
        }

    }
    thyj =() =>{
        console.log(this.state.clxx.yxdqzt)
        if(this.state.clxx.yxdqzt =="手机退回"){
            return (
                <InputItem
                    type="money"
                    value={this.state.clxx.thyy}
                    editable={false}
                ><div>退回原因</div></InputItem>
            )
        }
    }
    setjfxxzt = () => {
        console.log(this.state.dqzt);
        if (!this.state.djfisLoaded) {
            return null;
        } else {
            console.log(this.state.id);
            if (this.state.id !== undefined) {

                if (this.state.dqzt === "待审核") {
                    return null;
                } else if (this.state.dqzt === "已缴费" || this.state.dqzt === "已发放") {
                    return <div> <List renderHeader={() => '缴费信息'} >
                        <InputItem
                            type="money"
                            value={this.state.id.cph}
                            editable={false}
                        ><div>车牌号</div></InputItem>
                        <InputItem
                            type="money"
                            value={this.state.id.bz}
                            editable={false}
                        ><div>收费项目</div></InputItem>
                        <InputItem
                            type="money"
                            value={this.state.id.je + "元"}
                            editable={false}
                        ><div>应收总金额</div></InputItem>
                        <InputItem
                            type="money"
                            value={this.state.id.dqzt}
                            editable={false}
                        ><div>当前状态</div></InputItem>
                    </List>
                    </div>
                } else if (this.state.dqzt === "已审核") {
                    return <div>
                        <List renderHeader={() => '缴费信息'} >
                            <InputItem
                                type="money"
                                value={this.state.id.cph}
                                editable={false}
                            ><div>车牌号</div></InputItem>
                            <InputItem
                                type="money"
                                value={this.state.id.bz}
                                editable={false}
                            ><div>收费项目</div></InputItem>
                            <InputItem
                                type="money"
                                value={this.state.id.je + "元"}
                                editable={false}
                            ><div>应收总金额</div></InputItem>
                            <InputItem
                                type="money"
                                value={this.state.id.dqzt}
                                editable={false}
                            ><div>当前状态</div></InputItem>
                        </List>
                        <button className="submit" type="submit" onClick={this.checkOrder}>
                            支付
                        </button>
                    </div>
                }
            }

        }

    }

    //弹窗
    showModal = key => (e) => {
        e.preventDefault(); // 修复 Android 上点击穿透
        this.setState({
          [key]: true,
        });
      }
      onClose = key => () => {
        this.setState({
          [key]: false,
        });
      }


// handleJump=()=>{

//         //跳转单据明细，并展示流程信息
//         this.props.history.push({ pathname : "./clbg" , query : { clxx : this.state.clxx,id:this.state.id }});
// }
    render() {
        const { value } = this.state;
        console.log(this.state)
        var zzyxrq ='';
        if(this.state.clxx.zzyxrq){
            zzyxrq =  this.state.clxx.zzyxrq.substring(0,10)
        }else{
            zzyxrq =  '';
        }


        const data = [
            //{ value: "wx.unifiedOrder", label: '微信支付', img: Wxzf },
            //{ value: "trade.precreate", label: '支付宝支付', img: Zfbzf },
        ];
        if(this.isWeiXin()){
            var obj= {};
            obj.value = "WXPay.jsPay";
            obj.label = "微信支付";
            obj.img = Wxzf;
            data.push(obj);
        }else{
            var obj= {};
            obj.value = "trade.precreate";
            obj.label = "支付宝支付";
            obj.img = Zfbzf;
            data.push(obj);
        }
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    // rightContent={this.state.isBg ? [
                    //     // <div onClick={this.showModal('modal2')}>车辆变更</div>
                    //     <div onClick={() => this.handleJump()}>车辆变更</div>
                    // ] : ""}
                    onLeftClick={() => this.props.history.go(-1)}
                >车辆信息</NavBar>

                <div className="divmagin">
                    <Tabs tabs={tabs}
                        initialPage={0}
                        onChange={(tab, index) => { }}
                        onTabClick={(tab, index) => { }}
                    >
                        <div>
                            {this.setjfxxzt()}
                            <List renderHeader={() => '车辆信息'}>

                            <InputItem
                                    type="money"
                                    value={this.state.clxx.yxdqzt}
                                    editable={false}
                                ><div>当前状态</div></InputItem>
                                {this.thyj()}
                                <InputItem
                                    type="money"
                                    value={this.state.clxx.syrlx}
                                    editable={false}
                                ><div>所有人类型</div></InputItem>
                                <InputItem
                                    type="money"
                                    value={this.state.clxx.crzlx}
                                    editable={false}
                                ><div>出入证类型</div></InputItem>
                                <InputItem
                                    type="money"
                                    value={this.state.clxx.cph}
                                    editable={false}
                                ><div>车牌号</div></InputItem>
                                <InputItem
                                    type="money"
                                    value={this.state.clxx.cz}
                                    editable={false}
                                ><div>车辆所有人</div></InputItem>

                                <InputItem
                                    type="money"
                                    value={this.state.clxx.cllx}
                                    editable={false}
                                ><div>车辆类型</div></InputItem>
                                <InputItem
                                    type="money"
                                    value={zzyxrq}
                                    editable={false}
                                ><div>有效日期</div></InputItem>
                                {/* <TextareaItem
                                    title="收费说明"
                                    data-seed="logId"
                                    value={this.state.clxx.sfjf}
                                    ref={el => this.autoFocusInst = el}
                                    autoHeight
                                /> */}
                            </List>
                            <List renderHeader={() => '基本信息'} >
                                <InputItem
                                    type="money"
                                    value={this.state.clxx.sqr}
                                    editable={false}
                                ><div>申请人</div></InputItem>
                                <InputItem
                                    type="money"
                                    value={this.state.clxx.sqdw}
                                    editable={false}
                                ><div>申请单位</div></InputItem>
                                <InputItem
                                    type="money"
                                    value={this.state.clxx.sqrsfzh}
                                    editable={false}
                                ><div>申请身份证</div></InputItem>
                                <InputItem
                                    type="money"
                                    value={this.state.clxx.sfzh}
                                    editable={false}
                                ><div>车主身份证号</div></InputItem>
                                <InputItem
                                    type="money"
                                    value={this.state.clxx.zz}
                                    editable={false}
                                ><div>住址</div></InputItem>

                                <InputItem
                                    type="money"
                                    value={this.state.clxx.sjh}
                                    editable={false}
                                ><div>手机号码</div></InputItem>
                                {/* <InputItem
                                    type="money"
                                    value={this.state.clxx.jtdh}
                                    editable={false}
                                ><div>家庭电话</div></InputItem>
                                <InputItem
                                    type="money"
                                    value={this.state.clxx.bgdh}
                                    editable={false}
                                ><div>办公电话</div></InputItem> */}
                                <InputItem
                                    type="money"
                                    value={this.state.clxx.yktbh}
                                    editable={false}
                                ><div>一卡通编号</div></InputItem>
                                <InputItem
                                    type="money"
                                    value={this.state.clxx.zrr}
                                    editable={false}
                                ><div>责任人</div></InputItem>
                                {/* <InputItem
                                    type="money"
                                    value={this.state.clxx.jsdw}
                                    editable={false}
                                ><div>结算单位</div></InputItem> */}
                                <TextareaItem
                                    title="申请说明"
                                    data-seed="logId"
                                    value={this.state.clxx.sqbz}
                                    ref={el => this.autoFocusInst = el}
                                    autoHeight
                                />

                            </List>
                            <List renderHeader={() => '附件信息'} >
                                {this.xszfj()}
                                {this.sfzfj()}
                                {this.clcrsqdfj()}
                                {this.jhzfj()}
                                {this.yktfj()}
                                {this.xmhtfj()}
                                {this.ldhtfj()}
                            </List>
                        </div>
                        <div className="divmagin">
                            {this.setLcList()}
                        </div>
                    </Tabs>
                </div>
                {/* 选择支付方式 */}
                <Modal
                    popup
                    visible={this.state.modal}
                    onClose={this.onClose('modal')}
                    animationType="slide-up"
                    afterClose={() => { console.log('afterClose'); }}
                >
                    <List renderHeader={() => '选择支付方式'}>
                        {data.map(i => (
                            <RadioItem key={i.value} checked={value === i.value} onChange={() => this.onChange(i.value, i.label)}>
                                <img src={i.img} />  {i.label}
                            </RadioItem>
                        ))}
                        <Button type="primary" onClick={this.handleOrder}>确认</Button>
                    </List>
                </Modal>

            </div>
        )
    }
}
