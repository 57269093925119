import './replaceCardSubmit.css'
import React, { Component } from 'react'
import { NavBar,Icon,List,InputItem,ImagePicker,Toast,Modal} from 'antd-mobile';
import { NEW_YGK_URL,  Axios } from '../../../utils/url';
import { SFZ_URL } from '../../../utils/fjyl';
import lrz from "lrz";
export default class ReplaceCardSubmit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hrNo:'',
            personId:'',
            personName:'',
            gender:'',
            personType:'',
            nation:'',
            idCardNo:'',
            phoneNo:'',
            list:{},
            accountList: {},
            qblist:[],
            isLoaded:false,
            files:[],
            multiple: false,
            yl:false,
        }
    }




    componentDidMount = () => {
        //获取人员信息
        this.getPersonInfo();
    }

    async getPersonInfo() {
      console.log("开始获取人员信息");
      let ryobject=JSON.parse(localStorage.getItem('userInfo'));
        //console.log(ryobject.sfzh);
        const url = NEW_YGK_URL+'replacecard/getPersonAccountByIdCardNo';
        var params = new URLSearchParams();
        params.append("idCardNo", ryobject.sfzh);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res);
            if(res.data.flag){
                this.setState({
                    phoneNo: res.data.data.personInfo[0].PHONE_NO,
                    list: res.data.data.personInfo[0],
                    accountList:  res.data.data.accountList[0],
                })
            }
              console.log(this.state.list.PERSON_ID);

        });
    }

      setMapList = () => {
        if (!this.state.isLoaded) {
            return null;
        } else {
            return this.state.qblist.map((item, key) => (
                <InputItem
                value={item.ye}
                ref={el => this.labelFocusInst = el}
                labelNumber="7"
                editable
            ><div >{item.zhlxmc}</div></InputItem>
            ))
        }
    }

    handleSubmit = e => {
      e.preventDefault()
      console.log('表单提交')
      console.log(this.state.files)
      const url = NEW_YGK_URL+'replacecard/addReplaceCardBills';
      let params = new FormData();
      params.append('hrNo', this.state.list.HR_NO);
      params.append('personName', this.state.list.PERSON_NAME);
      params.append('centerId', this.state.accountList.CENTER_CODE);
      params.append('centerName', this.state.accountList.CENTER_NAME);
      params.append('personId', this.state.list.PERSON_ID);
      params.append('gender', this.state.list.SEX);
      params.append('personType', this.state.list.PERSON_TYPE_NAME);
      params.append('nation', this.state.list.NATION);
      params.append('idCardNo', this.state.list.ID_CARD_NO);
      params.append('phone', this.state.list.PHONE_NO);
      params.append('createUserId', this.state.list.PERSON_ID);
      params.append('createUserName', this.state.list.PERSON_NAME);
      params.append('createUserUnitId', this.state.list.UNIT_ID);
      params.append('createUserUnitName', this.state.list.UNIT_NAME);
      if(this.state.files.length !==0){
        for(var i=0;i<this.state.files.length;i++){
          params.append("affix",this.state.files[i].file);
          console.log(this.state.files[i].file)
        }
      }else{
        Toast.info('请先上传附件', 3, null, false);
        return;
      }

        Toast.loading("加载中...",0,null,true);
        Axios({
          headers: { 'Content-Type': 'multipart/form-data' },
          method: 'post',
          url: url,
          data: params,
          timeout:900000,
          //这部分非常重要，否则formdata会被转格式
        //   transformRequest: [function(){
        //     return params;
        // }],
        }).then((res) => {
          Toast.hide();
          if (res.status === 200) {
            Toast.info(res.data.msg, 2, null, false);
          }
          this.props.history.go(-1)

        });
    }

    onChangeImage = (e)=>{
      this.setState({
        sfzList: e,
      });
    }


    onChange = (files, type, index) => {
      console.log(files, type, index);
        var str=[];
        if(type!="remove"){
        for(let i=0;i<files.length;i++){

            lrz(files[i].url, { quality: 0.1 }) .then((rst) => {
              console.log(rst)
              let file = new File([rst.file], this.genID(16)+"."+files[i].file.name.split(".")[1], { type: files[i].file.type, lastModified: Date.now() });
              var arr = [{ orientation: 1, url: rst.base64, file: file }];;
              str.push(arr[0])
              this.setState({
                files: str,
              })
            })

        }
      }else{
        this.setState({
        files,
      });
      }
    }
    genID = (length) => {
      return Number(Math.random().toString().substr(3,length) + Date.now()).toString(36);
    }

    handleChange = (e) => {
      this.setState({phone: e});
    }

    setsfzMap = () => {
      var files = [{
        url:SFZ_URL
      }]
      return(
        <div>
          <span  style={{
          color:"red",
          padding: "15px 15px 9px"
        }}>身份证样例(点击查看)</span>
          <ImagePicker
              files={files}
              onImageClick={()=>this.setState({ yl: true })}
              selectable={false}
              disableDelete="false"
            />
        </div>
      )
    }


    render() {
        const {files} = this.state
        return (
            <div style={{ marginTop: ".89rem" }}>
            {/* 导航栏#0F84D6 */}
            <NavBar
              mode="dark"
              icon={<Icon type="left" />}
             onLeftClick={() => this.props.history.goBack()}
            >一卡通补卡申请详情</NavBar>
             <form onSubmit={this.handleSubmit}>
             <List  >
             <div className="scfj" style={{marginTop:"50px",color:"#888",fontSize:"14px"}}>申请人基本信息</div>
             <InputItem
              value={this.state.list.PERSON_NAME}
              ref={el => this.labelFocusInst = el}
              editable
            ><div ><span className="error">*</span>申请人姓名</div></InputItem>

            <InputItem
              value={this.state.list.HR_NO}
              ref={el => this.labelFocusInst = el}
              editable
            ><div ><span className="error">*</span>人员编号</div></InputItem>

            <InputItem
              value={this.state.list.SEX}
              ref={el => this.labelFocusInst = el}
              editable
            ><div ><span className="error">*</span>性别</div></InputItem>

            <InputItem
              value={this.state.list.PERSON_TYPE_NAME}
              ref={el => this.labelFocusInst = el}
              editable
            ><div ><span className="error">*</span>类型名称</div></InputItem>

            <InputItem
              value={this.state.list.NATION}
              ref={el => this.labelFocusInst = el}
              editable
            ><div ><span className="error">*</span>民族</div></InputItem>

            <InputItem
              value={this.state.list.ID_CARD_NO}
              ref={el => this.labelFocusInst = el}
              editable
            ><div ><span className="error">*</span>证件编号</div></InputItem>

            <InputItem
              value={this.state.phoneNo}
              onChange={this.handleChange}
            ><div ><span className="error">*</span>联系手机号</div></InputItem>
            {/* <div className="scfj">余额(元)</div>
            {this.setMapList()} */}
            {/* {this.setsfzMap()} */}
            <Modal
            visible={ this.state.yl }
            transparent
            footer={[{ text: '关闭', onPress: () => {this.setState({ yl: false })}} ]}
            >
              <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                  <img
                      alt="example"
                      style={{width: '100%',height:'100%' }}
                      src={SFZ_URL}
                  />
              </div>
            </Modal>
            <div className="scfj" style={{fontSize: "14px",
    color: "#888"}}>上传附件</div>
    <div>
          <div style={{paddingTop: "10px"}}>

          </div>
          <div className="scfj"><span style={{color:"red"}}>*</span>身份证正反面(<span onClick={()=>this.setState({ yl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
          <ImagePicker
              files={files}
              onChange={this.onChange}
              onImageClick={(index, fs) => console.log(index, fs)}
              selectable={files.length < 2}
              multiple={this.state.multiple}
              capture="camera"
            />


          </div>
            </List>
            <div className="formSubmit">
            <button className="submit" type="submit">
              申 请
            </button>
          </div>
             </form>

            </div>
        )
    }
}
