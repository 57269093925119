import './applycardauditshow.css'
import React, { Component } from 'react'
import { NavBar, Icon, List,InputItem, Modal, Toast, ImagePicker ,Picker} from 'antd-mobile';
import { NEW_YGK_URL, Axios } from '../../../utils/url';
const Item = List.Item;
const Brief = Item.Brief;
const alert = Modal.alert;
export default class ApplyCardAuditShow extends Component {
    constructor(props) {

        super(props);
        const apply_id = this.props.location.query.apply_id;
        this.state = {
            list: {},
            isLoaded: false,
            apply_id:apply_id,
            yhlxlist:[],
            fjlist:[],
            multiple: false,
            hkbfiles: [],//户口本
            hzyktfiles: [],//户主一卡通
            jhzfiles: [],//结婚证
            yczfiles: [],//一寸照片
            hzsfzfiles: [],//户主身份证
            brsfzfiles: [],//本人身份证号
            yktsqdfiles: [],//一卡通申请单,
            opinion: "",//审核意见
            isAudit:[{ value: '通过', label: '通过' }, { value: '退回', label: '退回' }],
            auditStatusStr: ""
        }
    }

    componentDidMount = () => {
        this.getData();//申请单据详情获取
        this.getAttachmentList();//附件信息获取
    }

    async getData() {
        const url = NEW_YGK_URL+'cardApply/getApplyBuildByApplyId';
        var params = new URLSearchParams();
        params.append("applyId", this.state.apply_id);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log("一卡通申请单据详情单据：",res);
            if(res.data.flag){
               this.setState({
                list: res.data.data[0]
               })
            }
        });
    }
    //申请附件信息获取
    async getAttachmentList(){
        const url = NEW_YGK_URL + 'cardApply/getCardApplyAttachmentListByApplyId';
        var params = new URLSearchParams();
        params.append("applyId", this.state.apply_id);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res);
            if(res.data.flag){
                let applyFormArr = [];//一卡通申请单附件信息
                let ownerIdCardNoArr = [];//本人身份证附件信息
                let photoArr = [];//申请人一寸照片附件信息
                let houseOwnerCardArr = [];//户主一卡通附件
                let houseOwnerIdCardArrange = [];//户主身份证号附件
                let houseBookArr = [];//户口本附件
                let marriageCardArr = [];//结婚证附件信息
                const attachement = res.data.data;
                if(attachement.length > 0){
                    //数据格式[{'url': 'www.baidu.com'}]
                    attachement.map((item)=>{
                        if(item.applyForm){
                            const obj = {};
                            obj.url = item.applyForm;
                            applyFormArr.push(obj);
                        }
                        if(item.ownerIdCardNo){
                            const obj = {};
                            obj.url = item.ownerIdCardNo;
                            ownerIdCardNoArr.push(obj);
                        }
                        if(item.photo){
                            const obj = {};
                            obj.url = item.photo;
                            photoArr.push(obj);
                        }
                        if(item.houseOwnerCard){
                            const obj = {};
                            obj.url = item.houseOwnerCard;
                            houseOwnerCardArr.push(obj);
                        }
                        if(item.houseOwnerIdCard){
                            const obj = {};
                            obj.url = item.houseOwnerIdCard;
                            houseOwnerIdCardArrange.push(obj);
                        }
                        if(item.houseBook){
                            const obj = {};
                            obj.url = item.houseBook;
                            houseBookArr.push(obj);
                        }
                        if(item.marriageCard){
                            const obj = {};
                            obj.url = item.marriageCard;
                            marriageCardArr.push(obj);
                        }
                    })
                    this.setState({
                        hkbfiles: houseBookArr,//户口本
                        hzyktfiles: houseOwnerCardArr,//户主一卡通
                        jhzfiles: marriageCardArr,//结婚证
                        yczfiles: photoArr,//一寸照片附件
                        hzsfzfiles: houseOwnerIdCardArrange,//户主身份证
                        brsfzfiles: ownerIdCardNoArr,//本人身份证号
                        yktsqdfiles: applyFormArr,//一卡通申请单
                    })
                }
            }
        })
    }

    async getyhlx() {
        const url = NEW_YGK_URL + 'packagetype/getUsefulUserType';
        var params = new URLSearchParams();
        params.append("userType", this.state.list.apply_person_person_type);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            // console.log("2222",res);
            if(res.data.flag){
                const list = res.data.data;
                for(var item in list ){
                    if(list[item].value == this.state.list.apply_person_person_type){
                        var jValue = list[item];
                        // console.log(jValue)
                        this.setState({
                            yhlxmc: jValue.label,
                        } )
                    }
                }
            }
        });
    }

    //本人身份证
    brsfzfj = () => {
        if (this.state.brsfzfiles.length>0) {
            return (
                <div>
                    <div className="scfj">本人身份证(必填)</div>
                    <ImagePicker
                        files={this.state.brsfzfiles}
                        onChange={false}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        multiple={this.state.multiple}
                        selectable={false}
                        disableDelete = {true}
                    />
                </div>
            )
        } else {
            return null;

        }
    }

    //一寸照片
    yczpfj = () => {
        if (this.state.brsfzfiles.length>0) {
            return (
                <div>
                    <div className="scfj">一寸照片(红底)(必填)</div>
                    <ImagePicker
                        files={this.state.yczfiles}
                        onChange={this.yczponChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        multiple={this.state.multiple}
                        selectable={false}
                        disableDelete = {true}
                    />
                </div>
            )
        } else {
            return null;

        }
    }

    //一卡通申请单
    yktfj = () => {
        if (this.state.yktsqdfiles.length>0) {
            return (
                <div>
                    <div className="scfj">一卡通申请单(必填)</div>
                    <ImagePicker
                        files={this.state.yktsqdfiles}
                        onChange={this.yktChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        multiple={this.state.multiple}
                        selectable={false}
                        disableDelete = {true}
                    />
                </div>
            )
        } else {
            return null;

        }
    }

    //户口本（本页及户主页）
    hkbfj = () => {

        if (this.state.hkbfiles.length>0) {
            return (
                <div>
                    <div className="scfj">户口本(必填)</div>
                    <ImagePicker
                        files={this.state.hkbfiles}
                        onChange={this.hkbChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        multiple={this.state.multiple}
                        selectable={false}
                        disableDelete = {true}
                    />
                </div>
            )
        } else {
            return null;

        }
    }

    //户主（配偶）一卡通
    hzyktfj = () => {

        if (this.state.hzyktfiles.length>0) {
            return (
                <div>
                    <div className="scfj">户主（配偶）一卡通(必填)</div>
                    <ImagePicker
                        files={this.state.hzyktfiles}
                        onChange={this.hzyktChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        multiple={this.state.multiple}
                        selectable={false}
                        disableDelete = {true}
                    />
                </div>
            )
        } else {
            return null;

        }
    }

    //结婚证
    jhzfj = () => {
        if (this.state.jhzfiles.length>0) {
            return (
                <div>
                    <div className="scfj">结婚证(非必填)</div>
                    <ImagePicker
                        files={this.state.jhzfiles}
                        onChange={this.jhzChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        multiple={this.state.multiple}
                        selectable={false}
                        disableDelete = {true}
                    />
                </div>
            )
        } else {
            return null;

        }
    }

    //户主（配偶）身份证
    hzsfzfj = () => {

        if (this.state.hzsfzfiles.length>0) {
            return (
                <div>
                    <div className="scfj">户主（配偶）身份证(非必填)</div>
                    <ImagePicker
                        files={this.state.hzsfzfiles}
                        onChange={this.hzsfzChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        multiple={this.state.multiple}
                        selectable={false}
                        disableDelete = {true}
                    />
                </div>
            )
        } else {
            return null;
        }
    }

    onChange = (brsfzfiles, type, index) => {
        this.setState({
            brsfzfiles,
        });
        console.log(this.state.brsfzfiles);
    }

    yczponChange = (yczfiles, type, index) => {
        console.log(yczfiles, type, index);
        this.setState({
            yczfiles,
        });
    }

    yktChange = (yktsqdfiles, type, index) => {
        console.log(yktsqdfiles, type, index);
        this.setState({
            yktsqdfiles,
        });
    }

    hkbChange = (hkbfiles, type, index) => {
        console.log(hkbfiles, type, index);
        this.setState({
            hkbfiles,
        });
    }

    hzyktChange = (hzyktfiles, type, index) => {
        console.log(hzyktfiles, type, index);
        this.setState({
            hzyktfiles,
        });
    }

    jhzChange = (jhzfiles, type, index) => {
        console.log(jhzfiles, type, index);
        this.setState({
            jhzfiles,
        });
    }

    hzsfzChange = (hzsfzfiles, type, index) => {
        console.log(hzsfzfiles, type, index);
        this.setState({
            hzsfzfiles,
        });
    }

    setContent = () => {
        const yktlx  = this.state.list.apply_card_type;
        const yhlxid  = this.state.list.apply_person_person_type;
        if (this.state.list.length == 0) {
            return null;
        } else if (yktlx == "B卡") {
            return (<div>
                <div className="scfj">申请信息</div>
                <InputItem value={this.state.list.code_content} >
                    <div >用户类型</div>
                </InputItem>
                <InputItem value={this.state.list.apply_person} >
                    <div >用户姓名</div>
                </InputItem>
                <InputItem placeholder="请输入申报姓名" value={this.state.list.create_user_name} >
                    <div >申报姓名</div>
                </InputItem>
                <InputItem placeholder="请输入用户单位" value={this.state.list.apply_person_unit_name} >
                    <div >用户单位</div>
                </InputItem>
                <InputItem placeholder="请输入身份证号" value={this.state.list.apply_person_id_card_no} >
                    <div >身份证号</div>
                </InputItem>
                <InputItem placeholder="请输入用户性别" value={this.state.list.apply_person_sex} >
                    <div >用户性别</div>
                </InputItem>
                <InputItem placeholder="请输入民族" value={this.state.list.apply_person_nation} >
                    <div >民族</div>
                </InputItem>
                <InputItem placeholder="请输入手机号码" value={this.state.list.apply_person_phone} >
                    <div >手机号码</div>
                </InputItem>

                <InputItem placeholder="请选择家庭住址" value={this.state.list.apply_person_address} >
                    <div >家庭住址</div>
                </InputItem>

                <InputItem placeholder="关系" value={this.state.list.relation} >
                    <div >关系</div>
                </InputItem>
                <InputItem placeholder="关系人" value={this.state.list.privy} >
                    <div >关系人</div>
                </InputItem>
                <InputItem placeholder="关系人单位" value={this.state.list.apply_person_unit_name} >
                    <div >关系人单位</div>
                </InputItem>
                {/* 是否通过*/}
                <Picker
                        data={this.state.isAudit}
                        cols={1}
                        onChange={this.isAuditChange}
                        value={this.state.auditStatusStr}
                    >
                        <List.Item arrow="horizontal"><span className="error">*</span>是否通过</List.Item>
                </Picker>
                <InputItem placeholder="请填写审核意见"
                            value={this.state.opinion} editable="true"
                            onChange={(value) => this.setState({ opinion: value })}
                            onBlur={value =>this.setOpinion(value)} >
                    <div ><span className="error">*</span>审核意见</div>
                </InputItem>
                {this.brsfzfj()}
                {this.yczpfj()}
                {this.yktfj()}
                {this.hkbfj()}
                {this.hzyktfj()}
                {this.jhzfj()}
                {this.hzsfzfj()}
            </div>)
        }else if(yktlx == "C卡"){
            return (<div>
                <div className="scfj">申请信息</div>
                <InputItem value={this.state.list.code_content} >
                    <div >用户类型</div>
                </InputItem>
                <InputItem value={this.state.list.apply_person} >
                    <div >用户姓名</div>
                </InputItem>
                <InputItem placeholder="请输入申报姓名" value={this.state.list.create_user_name} >
                    <div >申报姓名</div>
                </InputItem>
                <InputItem placeholder="请输入用户单位" value={this.state.list.apply_person_unit_name} >
                    <div >用户单位</div>
                </InputItem>
                <InputItem placeholder="请输入身份证号" value={this.state.list.apply_person_id_card_no} >
                    <div >身份证号</div>
                </InputItem>
                <InputItem placeholder="请输入用户性别" value={this.state.list.apply_person_sex} >
                    <div >用户性别</div>
                </InputItem>
                <InputItem placeholder="请输入民族" value={this.state.list.apply_person_nation} >
                    <div >民族</div>
                </InputItem>
                <InputItem placeholder="请输入手机号码" value={this.state.list.apply_person_phone} >
                    <div >手机号码</div>
                </InputItem>

                <InputItem placeholder="请选择家庭住址" value={this.state.list.apply_person_address} >
                    <div >家庭住址</div>
                </InputItem>

                <InputItem placeholder="关系" value={this.state.list.relation} >
                    <div >关系</div>
                </InputItem>
                <InputItem placeholder="关系人" value={this.state.list.privy} >
                    <div >关系人</div>
                </InputItem>
                <InputItem placeholder="关系人单位" value={this.state.list.apply_person_unit_name} >
                    <div >关系人单位</div>
                </InputItem>
                {/* 是否通过*/}
                <Picker
                    data={this.state.isAudit}
                    cols={1}
                    onChange={this.isAuditChange}
                    value={this.state.auditStatusStr}
                    >
                        <List.Item arrow="horizontal"><span className="error">*</span>是否通过</List.Item>
                </Picker>
                <InputItem placeholder="请填写审核意见" value={this.state.opinion} editable="true" onChange={(value) => this.setState({ opinion: value })}
                            onBlur={value =>this.setOpinion(value)}>
                    <div ><span className="error">*</span>审核意见</div>
                </InputItem>
                {this.brsfzfj()}
                {this.yczpfj()}
                {this.yktfj()}
                {this.hkbfj()}
                {this.hzyktfj()}
                {this.jhzfj()}
                {this.hzsfzfj()}
            </div>)
        }
    }
        //是否审核通过
        isAuditChange = (e) => {
            this.setState({
                auditStatusStr: e
            });
        }

    //更新实时输入的审核意见
    setOpinion = (value) =>{
        if(value !==""){
            this.setState({
                opinion: value,
            })
        }
    }
     auditInfo = () =>{
        const {opinion,auditStatusStr} = this.state;

        if(opinion !== undefined && opinion !== "" ){
            if(auditStatusStr!== ""  && auditStatusStr!== undefined){
                this.startAudit();
            }else{
                Toast.info("请选择是否通过", 4, null, false);
            }
        }else{
            Toast.info("请填写审核意见", 4, null, false);
        }
    }

    //开始处理审核流程
   async startAudit(){
        const url = NEW_YGK_URL + 'cardApplyInfo/audit';
        var params = new URLSearchParams();
        //连续结构赋值获取单据信息
        const {apply_id} = this.state.list;
        const {opinion,auditStatusStr} = this.state;
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const userIdCardNo = userInfo.sfzh;//当前用户身份证号码
        params.append("applyId",apply_id);//当前单据的申请id
        params.append("opinion",opinion);//审核意见
        params.append("userIdCardNo",userIdCardNo);//当前登录用户的身份证号
        params.append("centerName","塔里木管理中心");//管理中心名称
        params.append("centerId","MCN_TLM");//管理中心名
        console.log(auditStatusStr[0])
        params.append("flag",auditStatusStr[0]);//管理中心名
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res);
            Toast.hide();
            if(res.data.flag){
                Toast.info("操作成功", 4, null, false);
                this.props.history.go(-1)
            }else{
                Toast.info(res.data.data, 4, null, false);
            }
        })
    }

    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
            {/* 导航栏#0F84D6 */}
            <NavBar
              mode="dark"
              icon={<Icon type="left" />}
              rightContent={[
                <div onClick = {this.auditInfo}>审核</div>
              ]}
             onLeftClick={() => this.props.history.goBack()}
            >一卡通申请审核</NavBar>
            <div className="divmagin">
                    {this.setContent()}
                </div>
            </div>
        )
    }
}
