import './clbg.css'
import { Route } from 'react-router'
import lrz from 'lrz';
import { BASE_URL, Axios } from  '../../../utils/url'
import { XSZ_URL ,CLCRSQ_URL,JHZ_URL,SFZ_URL,YKT_URL,XMHT_URL,LDHT_URL} from '../../../utils/fjyl'
import React, { Component } from 'react'
import { NavBar,Radio, Modal,Button,Icon, InputItem, List, Picker, ImagePicker, Toast ,TextareaItem} from 'antd-mobile';
const RadioItem = Radio.RadioItem;
export default class ClbgEitder extends Component {
    constructor(props) {
        super(props);

        const clxx = this.props.location.query.clxx;
        console.log(clxx)
        //执行缓存数据
        localStorage.setItem("clxx", JSON.stringify(clxx));
        const location = this.props.location.pathname;
        console.log(location)
        const id = this.props.location.query.id;
        let isBgBool = false;


        this.state = {
            cph: clxx.cph,
            clxx: clxx,
            cllcxx: [],
            modal: false,
            id: id,
            isLoaded: false,
            djfisLoaded: false,
            zfdz: "",
            merOrderId: "",
            isPay: false,
            dqzt: clxx.yxdqzt,
            isBg: isBgBool,
            value: "",
            modal2: false,


            xssjh: "",
            pathname: location,
            cllx: '',
      syrlxList: '',
      syrlxText: '',
      crzlxList: [{
        label: '',
        value: '',
      }],
      crzlxText: '',
      cllxtext: '',
      fjList: '',
      syrlx: '',
      cllxList: '',
      crzlx: '',
      clsyr: clxx.cz,
      sqr: '',
      sqdw: '',
      sfzh: '',
      zz: '',
      phone: '',
      ektbh: '',
      zrr: '',
      files:[],
      xszfj:false,
      sfzfj:false,
      jhzfj:false,
      clcrsqdfj:false,
      yktfj:false,
      xmhtfj:false,
      ldhtfj:false,
      value:'',
      modal2: false,
      xszyl: false,
      yktyl:false,
      clcrsqyl:false,
      sfzyl:false,
      jhzyl:false,
      xmhtyl:false,
      ldhtyl:false,

      text:""
        }
    }
    componentDidMount = () => {
        this.getlx();
        // this.queryDwTree();
        this.state.clxx.cph = this.state.cph
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        console.log(userInfo)
        this.setState({
          xm:userInfo.ryxm,
          sfzh: userInfo.sfzh,
          dwbm:userInfo.dwbm
        })
    }




    onChangeImage = (files01, type, index) => {
      // console.log(e)
      // this.setState({
      //   xszList: e,
      // });

      if (type === 'add') {
        var str=[];
        for(let i=0;i<files01.length;i++){

          lrz(files01[i].url, { quality: 0.1 })
      .then((rst) => {
        // 处理成功会执行
        console.log('压缩成功')




        let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
        console.log(file)
        var arr = [{ orientation: 1, url: rst.base64, file: file }];
        // arr.push(file);
        str.push(arr[0])

        this.setState({
          xszList: str,
        })
      })
      }

      } else {
        this.setState({ xszList: '' })
      }

    }
    //车辆出入申请附件选择
    onChangeImageClcrsqd = (files01, type, index) => {
      // this.setState({
      //   clcrsqdList: e,
      // });
      if (type === 'add') {
        var str=[];
        for(let i=0;i<files01.length;i++){

          lrz(files01[i].url, { quality: 0.1 })
      .then((rst) => {
        // 处理成功会执行
        console.log('压缩成功')




        let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
        console.log(file)
        var arr = [{ orientation: 1, url: rst.base64, file: file }];
        // arr.push(file);
        str.push(arr[0])

        this.setState({
          clcrsqdList: str,
        })
      })
      }

      } else {
        this.setState({ clcrsqdList: '' })
      }
    }
    //身份证附件选择
    onChangeImageSfz = (files01, type, index) => {
      // this.setState({
      //   sfzList: e,
      // });
      if (type === 'add') {
        var str=[];
        for(let i=0;i<files01.length;i++){

          lrz(files01[i].url, { quality: 0.1 })
      .then((rst) => {
        // 处理成功会执行
        console.log('压缩成功')




        let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
        console.log(file)
        var arr = [{ orientation: 1, url: rst.base64, file: file }];
        // arr.push(file);
        str.push(arr[0])

        this.setState({
          sfzList: str,
        })
      })
      }

      } else {
        this.setState({ sfzList: '' })
      }
    }
    //一卡通附件选择
    onChangeImageYkt = (files01, type, index) => {
      // this.setState({
      //   yktList: e,
      // });
      if (type === 'add') {
        var str=[];
        for(let i=0;i<files01.length;i++){

          lrz(files01[i].url, { quality: 0.1 })
      .then((rst) => {
        // 处理成功会执行
        console.log('压缩成功')




        let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
        console.log(file)
        var arr = [{ orientation: 1, url: rst.base64, file: file }];
        // arr.push(file);
        str.push(arr[0])

        this.setState({
          yktList: str,
        })
      })
      }

      } else {
        this.setState({ yktList: '' })
      }
    }
    //项目合同附件选择
    onChangeImageXmht = (files01, type, index) => {
      // this.setState({
      //   xmhtList: e,
      // });
      if (type === 'add') {
        var str=[];
        for(let i=0;i<files01.length;i++){

          lrz(files01[i].url, { quality: 0.1 })
      .then((rst) => {
        // 处理成功会执行
        console.log('压缩成功')




        let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
        console.log(file)
        var arr = [{ orientation: 1, url: rst.base64, file: file }];
        // arr.push(file);
        str.push(arr[0])

        this.setState({
          xmhtList: str,
        })
      })
      }

      } else {
        this.setState({ xmthList: '' })
      }
    }
    //结婚证附件选择
    onChangeImageJhz = (files01, type, index) => {
      // this.setState({
      //   jhzList: e,
      // });
      if (type === 'add') {
        var str=[];
        for(let i=0;i<files01.length;i++){

          lrz(files01[i].url, { quality: 0.1 })
      .then((rst) => {
        // 处理成功会执行
        console.log('压缩成功')




        let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
        console.log(file)
        var arr = [{ orientation: 1, url: rst.base64, file: file }];
        // arr.push(file);
        str.push(arr[0])

        this.setState({
          jhzList: str,
        })
      })
      }

      } else {
        this.setState({ jhzList: '' })
      }
    }
    //劳动合同附件选择
    onChangeImageLdht = (files01, type, index) => {
      // this.setState({
      //   ldhtList: e,
      // });
      if (type === 'add') {
        var str=[];
        for(let i=0;i<files01.length;i++){

          lrz(files01[i].url, { quality: 0.1 })
      .then((rst) => {
        // 处理成功会执行
        console.log('压缩成功')




        let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
        console.log(file)
        var arr = [{ orientation: 1, url: rst.base64, file: file }];
        // arr.push(file);
        str.push(arr[0])

        this.setState({
          ldhtList: str,
        })
      })
      }

      } else {
        this.setState({ ldhtList: '' })
      }
    }
    genID = (length) => {
      return Number(Math.random().toString().substr(3,length) + Date.now()).toString(36);
  }
    setMapList1 = () =>{
      const list = this.state.fjList
      let falg = false;
      for (var item in list) {
          if (list[item].fjlxid == "6") {
              falg = true;
          }
      }
      if (falg) {
        if (this.state.xsz != '' && this.state.xsz != undefined) {
          var files = [{
            url:XSZ_URL
          }]
          return(
            <div>
              <span  style={{
              color:"red",
              padding: "15px 15px 9px"
            }}>行驶证样例(点击查看)</span>
              <ImagePicker
                  files={files}
                  onImageClick={()=>this.setState({ xszyl: true })}
                  selectable={false}
                  disableDelete="false"
                />
            </div>
          )
        }
      }else{
        return null;
      }


    }
    setMapList = () => {
      const list = this.state.fjList
      let falg = false;
      for (var item in list) {
          if (list[item].fjlxid == "6") {
              falg = true;
          }
      }
      if (falg) {
        var filesxsz = this.state.xszList;


      //行驶证附件
      if (this.state.xsz != '' && this.state.xsz != undefined) {
        return this.state.xsz.map((item, key) => (

          <List renderHeader={() => item.fjlxmc + "(" + item.sfbt + ")"} key={key} >
            <ImagePicker
              key={key}
              files={filesxsz}
              onChange={this.onChangeImage}
              selectable={filesxsz.length < 1}
              disableDelete="false"
              capture="camera"
            />
          </List>

        ))

      }
      }else{
        return null;
      }


    }
    setclcrsqMap1 = () => {
      const list = this.state.fjList
      let falg = false;
      for (var item in list) {
          if (list[item].fjlxid == "1") {
              falg = true;
          }
      }
      if (falg) {
        if (this.state.clcrsqd != '' && this.state.clcrsqd != undefined) {
          var files = [{
            url:CLCRSQ_URL
          }]
          return(
            <div>
              <span  style={{
              color:"red",
              padding: "15px 15px 9px"
            }}>车辆出入申请单样例(点击查看)</span>
              <ImagePicker
                  files={files}
                  onImageClick={()=>this.setState({ clcrsqyl: true })}
                  selectable={false}
                  disableDelete="false"
                />
            </div>
          )
        }
      }else{
        return null;
      }

    }
    //车辆出入申请单附件
    setclcrsqMap = () => {
      const list = this.state.fjList
      let falg = false;
      for (var item in list) {
          if (list[item].fjlxid == "1") {
              falg = true;
          }
      }
      if (falg) {
        const fileclcrsqd = this.state.clcrsqdList;

      if (this.state.clcrsqd != '' && this.state.clcrsqd != undefined) {
        return this.state.clcrsqd.map((item, key) => (

          <List renderHeader={() => item.fjlxmc + "(" + item.sfbt + ")"} key={key} >
            <ImagePicker
              key={key}
              files={fileclcrsqd}
              onChange={this.onChangeImageClcrsqd}
              selectable={fileclcrsqd.length < 1}
              disableDelete="false"
              capture="camera"
            />
          </List>
        ))

      }
      }else{
        return null;
      }

    }
    setsfzMap1 = () => {
      const list = this.state.fjList
      let falg = false;
      for (var item in list) {
          if (list[item].fjlxid == "2") {
              falg = true;
          }
      }
      if (falg) {
        if (this.state.sfz != '' && this.state.sfz != undefined) {
          var files = [{
            url:SFZ_URL
          }]
          return(
            <div>
              <span  style={{
              color:"red",
              padding: "15px 15px 9px"
            }}>身份证样例(点击查看)</span>
              <ImagePicker
                  files={files}
                  onImageClick={()=>this.setState({ sfzyl: true })}
                  selectable={false}
                  disableDelete="false"
                />
            </div>
          )
        }
      }else{
        return null;
      }

    }
    //身份证附件
    setsfzMap = () => {
      const list = this.state.fjList
      let falg = false;
      for (var item in list) {
          if (list[item].fjlxid == "2") {
              falg = true;
          }
      }
      if (falg) {
        const filesfz = this.state.sfzList;

      if (this.state.sfz != '' && this.state.sfz != undefined) {
        return this.state.sfz.map((item, key) => (

          <List renderHeader={() => item.fjlxmc + "(" + item.sfbt + ")"} key={key} >
            <ImagePicker
              key={key}
              files={filesfz}
              onChange={this.onChangeImageSfz}
              selectable={filesfz.length < 2}
              disableDelete="false"
              capture="camera"
            />
          </List>
        ))

      }
      }else{
        return null;
      }

    }
    setyktMap1 = () => {
      const list = this.state.fjList
      let falg = false;
      for (var item in list) {
        console.log(list[item].fjlxid)
          if (list[item].fjlxid == "5") {
              falg = true;
          }
      }
      if (falg) {
        if (this.state.ykt != '' && this.state.ykt != undefined) {
          var files = [{
            url:YKT_URL
          }
        ]
          return(
            <div>
              <span  style={{
              color:"red",
              padding: "15px 15px 9px"
            }}>一卡通样例(点击查看)</span>
              <ImagePicker
                  files={files}
                  onImageClick={()=>this.setState({ yktyl: true })}
                  selectable={false}
                  disableDelete="false"
                />
            </div>
          )
        }
      }else{
        return null;
      }

    }
    //一卡通附件
    setyktMap = () => {
      const list = this.state.fjList
      let falg = false;
      for (var item in list) {
        console.log(list[item].fjlxid)
          if (list[item].fjlxid == "5") {
              falg = true;
          }
      }
      if (falg) {
        const yktldht = this.state.yktList;

      if (this.state.ykt != '' && this.state.ykt != undefined) {
        return this.state.ykt.map((item, key) => (

          <List renderHeader={() => item.fjlxmc + "(" + item.sfbt + ")"} key={key} >
            <ImagePicker
              key={key}
              files={yktldht}
              onChange={this.onChangeImageYkt}
              selectable={yktldht.length < 1}
              disableDelete="false"
              capture="camera"
            />
          </List>
        ))

      }
      }else{
        return null;
      }

    }

    setxmhtMap1 = () => {
      const list = this.state.fjList
      let falg = false;
      for (var item in list) {
          if (list[item].fjlxid == "8") {
              falg = true;
          }
      }
      if (falg) {
        if (this.state.xmht != '' && this.state.xmht != undefined) {
          var files = [{
            url:XMHT_URL
          }]
          return(
            <div>
              <span  style={{
              color:"red",
              padding: "15px 15px 9px"
            }}>项目合同样例(点击查看)</span>
              <ImagePicker
                  files={files}
                  onImageClick={()=>this.setState({ xmhtyl: true })}
                  selectable={false}
                  disableDelete="false"
                />
            </div>
          )
        }
      }else{
  return null;
      }

    }
    //项目合同附件
    setxmhtMap = () => {
      const list = this.state.fjList
      let falg = false;
      for (var item in list) {
          if (list[item].fjlxid == "8") {
              falg = true;
          }
      }
      if (falg) {
        const filexmht = this.state.xmhtList;

      if (this.state.xmht != '' && this.state.xmht != undefined) {
        return this.state.xmht.map((item, key) => (

          <List renderHeader={() => item.fjlxmc + "(" + item.sfbt + ")"} key={key} >
            <ImagePicker
              key={key}
              files={filexmht}
              onChange={this.onChangeImageXmht}
              selectable={filexmht.length < 4}
              disableDelete="false"
              capture="camera"
            />
          </List>
        ))

      }
      }else{
        return null;
      }

    }

    setjhzMap1 = () => {
      const list = this.state.fjList
      let falg = false;
      for (var item in list) {
          if (list[item].fjlxid == "3") {
              falg = true;
          }
      }
      if (falg) {
        if (this.state.jhz != '' && this.state.jhz != undefined) {
          var files = [{
            url:JHZ_URL
          }]
          return(
            <div>
              <span  style={{
              color:"red",
              padding: "15px 15px 9px"
            }}>结婚证样例(点击查看)</span>
              <ImagePicker
                  files={files}
                  onImageClick={()=>this.setState({ jhzyl: true })}
                  selectable={false}
                  disableDelete="false"
                />
            </div>
          )
        }
      }else{
        return null;
      }

    }
    //结婚证附件
    setjhzMap = () => {
      const list = this.state.fjList
      let falg = false;
      for (var item in list) {
          if (list[item].fjlxid == "3") {
              falg = true;
          }
      }
      if (falg) {
        const filejhz = this.state.jhzList;

        if (this.state.jhz != '' && this.state.jhz != undefined) {
          return this.state.jhz.map((item, key) => (

            <List renderHeader={() => item.fjlxmc + "(" + item.sfbt + ")"} key={key} >
              <ImagePicker
                key={key}
                files={filejhz}
                onChange={this.onChangeImageJhz}
                selectable={filejhz.length < 1}
                disableDelete="false"
                capture="camera"
              />
            </List>
          ))

        }
      }else{
        return null;
      }

    }
    setldhtMap1 = () => {
      const list = this.state.fjList
      let falg = false;
      for (var item in list) {
          if (list[item].fjlxid == "4") {
              falg = true;
          }
      }
      if (falg) {
        if (this.state.ldht != '' && this.state.ldht != undefined) {
          var files = [{
            url:LDHT_URL
          }]
          return(
            <div>
              <span  style={{
              color:"red",
              padding: "15px 15px 9px"
            }}>劳动合同样例(点击查看)</span>
              <ImagePicker
                  files={files}
                  onImageClick={()=>this.setState({ ldhtyl: true })}
                  selectable={false}
                  disableDelete="false"
                />
            </div>
          )
        }
      }else{
        return null;
      }

    }
    //劳动合同附件
    setldhtMap = () => {
      const list = this.state.fjList
      let falg = false;
      for (var item in list) {
          if (list[item].fjlxid == "4") {
              falg = true;
          }
      }
      if (falg) {
        const fileldht = this.state.ldhtList;

        if (this.state.ldht != '' && this.state.ldht != undefined) {
          return this.state.ldht.map((item, key) => (

            <List renderHeader={() => item.fjlxmc + "(" + item.sfbt + ")"} key={key} >
              <ImagePicker
                key={key}
                files={fileldht}
                onChange={this.onChangeImageLdht}
                selectable={fileldht.length < 4}
                disableDelete="false"
                capture="camera"
              />
            </List>
          ))

        }
      }else{
        return null;
      }

    }


    //续费表单提交
    handleSubmit = e => {
        //阻止表单提交行为
        e.preventDefault()
        console.log('表单提交')

        // 表单数据

        const {sfzh, bgsjh,userInfo, dwmc, bglx, syrlx, syrlxText,zz, crzlx, crzlxText, clsyr, cph, cllx, cllxtext, sfsm, sqr, sqdw,   phone, ektbh, zrr, jsdw, sqsm, sqrid, sqdwbm, xszList, sfzList, yktList, jhzList, xmhtList, ldhtList, clcrsqdList } = this.state
        const {mjkbh,sjcqqx,yhid,yhxm,yhdwbm,yhdwmc,sqid,syrlxbm,crzbh,cz,ckbh,jflb,sjh,yktbh,sqbz,hyhdwbm} = this.state.clxx
        var newsyrlx = syrlxText;
    if(newsyrlx ==="外部单位"){
      newsyrlx = "乙方单位(免费)";
    }else if(newsyrlx ==="外部人员"){
      newsyrlx ="乙方个人(免费)";
    }else if(newsyrlx ==="员工及家属"){
      newsyrlx ="居民";
    }
        const url = BASE_URL + 'bsqjlController/save';
        let params = new FormData()

            params.append('syrlxmc', this.state.syrlxText);
            params.append('syrlxbm', this.state.syrlx);
            params.append('crzlxmc', this.state.crzlxText);
            params.append('crzlxbm', this.state.crzlx);
            params.append('clsyr', this.state.clsyr);
            params.append('cph', this.state.cph);
            params.append('cllxmc', this.state.cllxtext);
            params.append('cllxbm', this.state.cllx);
            params.append('sfsm', this.state.sfsm);
            params.append('sqr', sqr);
            params.append('sqrdw', sqdw);
            params.append('sfzh', sfzh);
            params.append('zz', zz);
            params.append('sjh', bgsjh);
            params.append('yktbh', yktbh);
            params.append('zrr', sqr);
            params.append('sqrid', "");
            params.append('dwmc', dwmc);
            params.append('sqsm', "");
            params.append('dwbm', hyhdwbm);
            params.append('sqid', sqid);
            params.append('preyhid', sfzh);
            params.append('preyhxm', this.state.xm);
            params.append('predwmc', yhdwmc);
            params.append('predwbm', this.state.dwbm);
            params.append('sjcqqx', sjcqqx);
            params.append('crzbh', crzbh);
            params.append('mjkbh', mjkbh);
            params.append('presqid', sqid);
            //xinzeng
            params.append('bglx', "权限变更");


            let fileinfo = []
            if (sfzList !== undefined) {
              // for (var i = 0; i < sfzList.length; i++) {
              //   params.append("sfzfjList", sfzList[i].file);
              // }
              if (sfzList.length !== 0) {
                for (let f of sfzList) {
                    params.append("fjList", f.file);
                    fileinfo.push({ name: f.file.name, type: '2' });
                }
                // params.append('fileinfo', JSON.stringify(fileinfo));
            }
            }
            if (xmhtList !== undefined) {
              // for (var i = 0; i < xmhtList.length; i++) {
              //   params.append("xmhtfjList", xmhtList[i].file);
              // }
              if (xmhtList.length !== 0) {
                for (let f of xmhtList) {
                    params.append("fjList", f.file);
                    fileinfo.push({ name: f.file.name, type: '8' });
                }
                // params.append('fileinfo', JSON.stringify(fileinfo));
            }
            }
            if (yktList !== undefined) {
              // for (var i = 0; i < yktList.length; i++) {
              //   params.append("yktfjList", yktList[i].file);
              // }
              if (yktList.length !== 0) {
                for (let f of yktList) {
                    params.append("fjList", f.file);
                    fileinfo.push({ name: f.file.name, type: '5' });
                }
                // params.append('fileinfo', JSON.stringify(fileinfo));
            }
            }
            if (jhzList !== undefined) {
              // for (var i = 0; i < jhzList.length; i++) {
              //   params.append("jhzfjList", jhzList[i].file);
              // }
              if (jhzList.length !== 0) {
                for (let f of jhzList) {
                    params.append("fjList", f.file);
                    fileinfo.push({ name: f.file.name, type: '3' });
                }
                // params.append('fileinfo', JSON.stringify(fileinfo));
            }
            }
            if (ldhtList !== undefined) {
              // for (var i = 0; i < ldhtList.length; i++) {
              //   params.append("ldhtfjList", ldhtList[i].file);
              // }
              if (ldhtList.length !== 0) {
                for (let f of ldhtList) {
                    params.append("fjList", f.file);
                    fileinfo.push({ name: f.file.name, type: '4' });
                }
                // params.append('fileinfo', JSON.stringify(fileinfo));
            }
            }
            if (this.state.xszList !== undefined) {
              // for (var i = 0; i < xszList.length; i++) {
              //   params.append("xszfjList", xszList[i].file);
              // }
              if (this.state.xszList.length !== 0) {
                for (let f of this.state.xszList) {
                    params.append("fjList", f.file);
                    fileinfo.push({ name: f.file.name, type: '6' });
                }
                // params.append('fileinfo', JSON.stringify(fileinfo));
            }
            }
            if (clcrsqdList !== undefined) {
              // for (var i = 0; i < clcrsqdList.length; i++) {
              //   params.append("clcrsqdfjList", clcrsqdList[i].file);
              //   console.log(clcrsqdList[i].file)
              // }
              if (clcrsqdList.length !== 0) {
                for (let f of clcrsqdList) {
                    params.append("fjList", f.file);
                    fileinfo.push({ name: f.file.name, type: '1' });
                }
                // params.append('fileinfo', JSON.stringify(fileinfo));
            }
            }
            params.append('fileinfo', JSON.stringify(fileinfo));


        if (this.state.xsz !== undefined && this.state.xsz[0].sfbt === "必填") {
            if (xszList.length === 0) {
                Toast.info("请上传行驶证附件", 1, null, false)
            } else {
                params.append('xszList', JSON.stringify(xszList));
                this.state.xszfj = true
            }


        } else {
            params.append('xszList', JSON.stringify(xszList));
            this.state.xszfj = true
        }
        if (this.state.jhz !== undefined && this.state.jhz[0].sfbt === "必填") {

            if (jhzList.length === 0) {
                Toast.info("请上传结婚证附件", 1, null, false)

            } else {
                params.append('jhzList', JSON.stringify(jhzList));
                this.state.jhzfj = true
            }

        } else {
            params.append('jhzList', JSON.stringify(jhzList));
            this.state.jhzfj = true
        }
        if (this.state.clcrsqd !== undefined && this.state.clcrsqd[0].sfbt === "必填") {
            if (clcrsqdList.length === 0) {
                Toast.info("请上传车辆出入申请单附件", 1, null, false)
            } else {
                params.append('clcrsqdList', JSON.stringify(clcrsqdList));
                this.state.clcrsqdfj = true
            }

        } else {
            params.append('clcrsqdList', JSON.stringify(clcrsqdList));
            this.state.clcrsqdfj = true
        }
        if (this.state.sfz !== undefined && this.state.sfz[0].sfbt === "必填") {

            if (sfzList.length === 0) {
                Toast.info("请上传身份证附件", 1, null, false)
            } else {
                params.append('sfzList', JSON.stringify(sfzList));
                this.state.sfzfj = true
            }

        } else {
            this.state.sfzfj = true
            params.append('sfzList', JSON.stringify(sfzList));
        }
        if (this.state.ykt !== undefined && this.state.ykt[0].sfbt === "必填") {

            if (yktList.length === 0) {
                Toast.info("请上传一卡通附件", 1, null, false)
            } else {
                params.append('yktList', JSON.stringify(yktList));
                this.state.yktfj = true
            }

        } else {
            this.state.yktfj = true
            params.append('yktList', JSON.stringify(yktList));
        }
        if (this.state.xmht !== undefined && this.state.xmht[0].sfbt === "必填") {
            if (xmhtList.length === 0) {
                Toast.info("请上传项目合同附件", 1, null, false)

            } else {
                params.append('xmhtList', JSON.stringify(xmhtList));
                this.state.xmhtfj = true
            }

        } else {
            this.state.xmhtfj = true
            params.append('xmhtList', JSON.stringify(xmhtList));
        }
        if (this.state.ldht !== undefined && this.state.ldht[0].sfbt === "必填") {

            if (ldhtList.length === 0) {
                Toast.info("请上传劳动合同附件", 1, null, false)
            } else {
                this.state.ldhtfj = true
                params.append('ldhtList', JSON.stringify(ldhtList));
            }

        } else {
            this.state.ldhtfj = true
            params.append('ldhtList', JSON.stringify(ldhtList));
        }
        console.log(cllxtext)
        console.log(cph)
        console.log(syrlxText)
        console.log(crzlxText)
        console.log(clsyr)
        console.log(sfzh)
        console.log(zz)
        console.log(bgsjh)
        if(this.state.jhzfj&& this.state.sfzfj&& this.state.yktfj&& this.state.xmhtfj&& this.state.ldhtfj&& this.state.xszfj&& this.state.clcrsqdfj&& cllxtext && cph &&syrlxText && crzlxText  && clsyr  &&sfzh&&zz&&bgsjh){
        console.log("现在可以提交表单")



        //   console.log(xszList)
        Toast.loading("加载中...", 0, null, true);
        Axios({
            headers: { 'Content-Type': 'multipart/form-data' },
            method: 'post',
            url: url,
            data: params,
            //这部分非常重要，否则formdata会被转格式
            // transformRequest: [function () {
            //     return params;
            // }],
        }).then((res) => {
            console.log(res)
            Toast.hide();
            if(res.status == 200){
                Toast.info("保存成功")
                this.props.history.go(-1)
            }else{
                Toast.info("保存失败")
            }


        });
        }else{
          Toast.info("请将表单填写完整再提交~")
        }

    }
    //选择所有人类型
  handleFromChange = (e) => {
    const list = this.state.syrlxList

    for (var item in list) {
      if (list[item].value == e) { //item 表示Json串中的属性，如'name'
        var jValue = list[item];//key所对应的value
        console.log(jValue.label);
        this.state.syrlxText = jValue.label;
      }
    }

    this.setState((state, props) => {
      return {
        syrlx: e
      }
    },
      () => {
        console.log(this.state.syrlx)
        if (this.state.syrlx !== '') {
          const url = BASE_URL + 'brysfxmpzController/getCrzlxList';
          var params = new URLSearchParams();
          params.append('lxbm', this.state.syrlx);
          Axios({
            method: 'post',
            url: url,
            data: params
          }).then((res) => {
            console.log(res)
            if (res.status == 200) {
            if(this.state.syrlxText ==="员工及家属"){

              var jmcrz =[];
               jmcrz.push(res.data.crzList[1])
               jmcrz.push(res.data.crzList[2])

              this.setState({
                crzlxList: jmcrz,
                fjList: res.data.fjList,
                fjlxList: res.data.fjlxList
              })
            }else{

              this.setState({
                crzlxList: res.data.crzList,
                fjList: res.data.fjList,
                fjlxList: res.data.fjlxList
              })
            }
          }
            console.log(this.state.fjList.length)
            var cc = this.state.fjList.length;
            const fjList = this.state.fjList;
            console.log(fjList)
            for(var a=0;a<cc;a++){
              console.log(fjList[a].fjlxbm);
              if(fjList[a].fjlxbm === "XSZ"){
                this.setState({
                  xsz:[fjList[a]],
                  xszList:[]
                })
                console.log(this.state.xsz);
              }else if(fjList[a].fjlxbm === "XMHT"){
                this.setState({
                  xmht:[fjList[a]],
                  xmhtList:[]
                })
              }else if(fjList[a].fjlxbm === "CLCRSQD"){
                this.setState({
                  clcrsqd:[fjList[a]],
                  clcrsqdList:[]
                })
              }else if(fjList[a].fjlxbm === "SFZ"){
                this.setState({
                  sfz:[fjList[a]],
                  sfzList:[]
                })
              }else if(fjList[a].fjlxbm === "JHZ"){
                this.setState({
                  jhz:[fjList[a]],
                  jhzList:[]
                })
              }else if(fjList[a].fjlxbm === "LDHT"){
                this.setState({
                  ldht:[fjList[a]],
                  ldhtList:[]
                })
              }else if(fjList[a].fjlxbm === "YKT"){
                this.setState({
                  ykt:[fjList[a]],
                  yktList:[]
                })
              }

            }
            // console.log(this.state)

          });


        }
      }
    )
      this.forceUpdate()


  }
  //获取类型
  getlx = () => {
    const url = BASE_URL + 'bcrzlxbmController/getSyrlxList';
    var params = new URLSearchParams();
    Axios({
      method: 'post',
      url: url,
      data: params
    }).then((res) => {
      console.log(res);
      if (res.status === 200) {
        if(res.data.syrlxList){
          if(res.data.syrlxList.length >0){

            for(var i = 0;i< res.data.syrlxList.length;i++){
              if(res.data.syrlxList[i].label ==="甲方单位"){
                res.data.syrlxList.splice(i, i)
                console.log(res.data.syrlxList);
              }
              if(res.data.syrlxList[i].label ==="乙方单位(免费)"){
                res.data.syrlxList[i].label = "外部单位"
              }
              if(res.data.syrlxList[i].label ==="乙方个人(免费)"){
                res.data.syrlxList[i].label = "外部人员"
              }
              if(res.data.syrlxList[i].label ==="居民"){
                res.data.syrlxList[i].label = "员工及家属"
              }
            }
          }
        }
        this.setState({
          syrlxList: res.data.syrlxList,
          cllxList: res.data.cllxList
        });
      }
    });

  }
  //选择车辆类型
  handleCllxChange = (e) => {

    const listCllx = this.state.cllxList
    console.log(listCllx)
    for (var item in listCllx) {
      if (listCllx[item].value == e) { //item 表示Json串中的属性，如'name'
        var lValue = listCllx[item];//key所对应的value
        this.state.cllxtext = lValue.label;
      }
    }
    this.setState({
      cllx: e
    })
  }
  //选择出入证类型
  handleCrzlxChange = (e) => {
    console.log(e);

    if (e == 0) {
      Toast.info('请先选择所有人类型')
    } else {
      const listCrzlx = this.state.crzlxList
      for (var item in listCrzlx) {
        if (listCrzlx[item].value == e) { //item 表示Json串中的属性，如'name'
          var cValue = listCrzlx[item];//key所对应的value
          this.state.crzlxText = cValue.label;
        }
      }
      this.setState({
        crzlx: e
      })
    }

    this.forceUpdate()

  }

  checkCph =(e)=>{
    const url = BASE_URL + 'bclxxController/checkCph';
          var params = new URLSearchParams();
          params.append('cph', e);
          Axios({
            method: 'post',
            url: url,
            data: params
          }).then((res) => {
            console.log(res)
            if(res.data.flag === false){
              // Toast.info(res.data.msg,1,null,false)
              Toast.info(res.data.msg)
              this.setState({
                cph:''
              })
            }

          });
    this.setState({
      cph:e
    })
  }


  onClosexsz = ()=>{
    this.setState({
          xszyl: false
        });
  }
  onClosesfz = ()=>{
    this.setState({
          sfzyl: false
        });
  }
  onClosesfzfm = ()=>{
    this.setState({
          sfzyl: false
        });
  }
  onCloseclcrsq = ()=>{
    this.setState({
          clcrsqyl: false
        });
  }
  onCloseykt = ()=>{
    this.setState({
          yktyl: false
        });
  }
  onCloseygk = ()=>{
    this.setState({
          ygkyl: false
        });
  }
  onClosejhz = ()=>{
    this.setState({
          jhzyl: false
        });
  }
  onClosexmht = ()=>{
    this.setState({
          xmhtyl: false
        });
  }
  onCloseldht = ()=>{
    this.setState({
          ldhtyl: false
        });
  }

    render() {
        const entranceCllxOptions = this.state.cllxList
        console.log(this.state)

        return (
            <div style={{ marginTop: ".89rem" ,height:"100%"}}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.go(-1)}
                >车辆变更</NavBar>
                <List renderHeader={() => <div>车辆变更</div>} className="popup-list">

                    <div>
                        {

                                <div>
                                    <form onSubmit={this.handleSubmit}>



                   <Picker
                     data={this.state.syrlxList}
                     cols={1}
                     onChange={this.handleFromChange}
                     className="forss"
                     value={this.state.syrlx}
                     id="aaa"
                   >
                     <List.Item arrow="horizontal"><span className="error">*</span>所有人类型</List.Item>
                   </Picker>
                   {/* <Modal
                   style={{height: "280px",
                   overflow: "auto"}}
          popup
          visible={this.state.modal2}
          onClose={this.onClose('modal2')}
          animationType="slide-up"
          // afterClose={() => { alert('afterClose'); }}
        >

          <List renderHeader={() => <div>单位选择</div>} className="popup-list">
          <InputItem
              placeholder="请输入单位后选择确认"
              ref={el => this.labelFocusInst = el}
              onChange={this.search}
              // onChange={(value) => this.setState({ text: value })}
            ></InputItem>
                {this.SerMapDw(this.state.text)}
            <List.Item>
              <Button type="primary" onClick={this.onClose('modal2')}>确认</Button>
            </List.Item>

          </List>
        </Modal> */}
                    <Picker
                     data={this.state.crzlxList}
                     cols={1}
                     onChange={this.handleCrzlxChange}
                     className="forss"
                     value={this.state.crzlx}

                   >
                     <List.Item arrow="horizontal"><span className="error">*</span>出入证类型</List.Item>
                   </Picker>
                   <InputItem
                     placeholder="请输入车牌号"
                     ref={el => this.labelFocusInst = el}
                     value={this.state.cph}
                    //  onChange={(value) => this.setState({ cph: value })}
                    //  onBlur = {this.checkCph}
                     editable={false}
                   ><div onClick={() => this.labelFocusInst.focus()}>车牌号</div></InputItem>

                   <InputItem
                     placeholder="请输入车辆所有人"
                     ref={el => this.labelFocusInst = el}
                     value={this.state.clsyr}
                    //  editable={false}
                    // onClick={this.showModal('modal2')}
                     onChange={(value) => this.setState({ clsyr: value })}
                   ><div onClick={() => this.labelFocusInst.focus()}><span className="error">*</span>车辆所有人</div></InputItem>


                   <Picker
                     data={entranceCllxOptions}
                     cols={1}
                     onChange={this.handleCllxChange}
                     className="forss"
                     value={this.state.cllx}
                   >
                     <List.Item arrow="horizontal"><span className="error">*</span>车辆类型</List.Item>
                   </Picker>
                   <InputItem

                                    type="phone"
                                    placeholder="请输入变更手机号"
                                    value={this.state.bgsjh}
                                    onChange={(value) => this.setState({ bgsjh: value })}
                                ><span className="error">*</span>手机号码</InputItem>
                  <InputItem

type="text"
placeholder="请输入住址"
value={this.state.zz}
onChange={(value) => this.setState({ zz: value })}
><span className="error">*</span>住址</InputItem>

                                               <div className="scfj">上传附件</div>
                                               {this.setMapList1()}
            {this.setMapList()}
            <Modal
                    visible={ this.state.xszyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClosexsz(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={XSZ_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
            {this.setclcrsqMap1()}
            {this.setclcrsqMap()}
            <Modal
                    visible={ this.state.clcrsqyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onCloseclcrsq(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={CLCRSQ_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
            {this.setsfzMap1()}
            {this.setsfzMap()}
            <Modal
                    visible={ this.state.sfzyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClosesfz(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={SFZ_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>

            {this.setyktMap1()}
            {this.setyktMap()}
            <Modal
                    visible={ this.state.yktyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onCloseykt(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={YKT_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>

            {this.setjhzMap1()}
            {this.setjhzMap()}
            <Modal
                    visible={ this.state.jhzyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClosejhz(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={JHZ_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
            {this.setxmhtMap1()}
            {this.setxmhtMap()}
            <Modal
                    visible={ this.state.xmhtyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClosexmht(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={XMHT_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
            {this.setldhtMap1()}
            {this.setldhtMap()}
            <Modal
                    visible={ this.state.ldhtyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onCloseldht(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={LDHT_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>

                                               <div className="formSubmit">
                                                   <button className="submit" type="submit">
                                                       申 请
                   </button>
                                               </div>
                                           </form>
                                </div>

                        }
                    </div>
                </List>
            </div>


        )
    }
}
