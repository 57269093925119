import './applycardsubmit.css'
import React, { Component } from 'react'
import { NavBar, Icon, List, Picker, InputItem, Modal, Button, SearchBar,Radio, Toast, ImagePicker } from 'antd-mobile';
import { NEW_YGK_URL,DOWNFILEURL, Axios } from '../../../utils/url';
import { JHZ_URL,SFZ_URL,YKT_URL} from '../../../utils/fjyl'
import lrz from "lrz";
import ycz from '../../../assets/images/ycz.jpg'
import hkb from '../../../assets/images/hkb.png'
import yktsqd from '../../../assets/images/yktsqd.png'
const Item = List.Item;
const Brief = Item.Brief;
const RadioItem = Radio.RadioItem;
export default class ApplyCardSubmit extends Component {
    constructor(props) {
        super(props);
        let ryobject = JSON.parse(localStorage.getItem('userInfo'));
        this.state = {
            smxx:"",
            list: [],
            yhlx: [],
            fzlist: [],
            jtzhlist1: [],
            jtzhlist2: [],
            jtzhlist3: [],
            jtzhid1: "",
            jtzhid2: "",
            jtzhid3: "",
            fzxm: "",
            fzxmvalue: "",
            fzxmid: "",
            fzykth: "",
            yhlxmc: "",
            yhlxbm: "",
            xb: [
                { value: '男', label: '男' },
                { value: '女', label: '女' }
            ],
            detailType:[
                {value:'直系亲属',label:'直系亲属'},
                {value:'兴塔员工',label:'兴塔员工'},
                {value:'塔西南驻库亲属',label:'塔西南驻库亲属'},
                {value:'已故人员亲属',label:'已故人员亲属'},
            ],
            sftz: [{ value: '是', label: '是' }, { value: '否', label: '否' }],
            fjlxlist: [],
            sftzText: "",
            mz: [],
            tktlx: "",
            tktlxText: "",
            tktlxList:[],
            // tktlxList: [{ value: 'B', label: '配偶及直系亲属(B卡)' }, { value: 'C1', label: '外部单位人员(C卡)' }, { value: 'C3', label: '其他亲属(C卡)' }, { value: 'C2', label: '租房户(C卡)' }],
            ryxx: ryobject,
            sjhm: "",
            sfzh: "",
            sbxm: "",
            ryxm:"",
            lb: "B",
            yhzgx: [],
            hzgx: "",
            modal1: false,
            modalhzxm: false,
            multiple: false,
            hkbfiles: [],
            hzyktfiles: [],
            jhzfiles: [],
            yczfiles: [],
            hzsfzfiles: [],
            brsfzfiles: [],
            yktsqdfiles: [],
            yhmzText: "",
            fwbhlist: [],
            fwbhs: "",
            fwbh: "",
            dwlist:[],
            yhdw: "",
            yhdws:"",
            yhdwbm:"",
            yhxb:"",
            jtzz:"",
            previewImage:'',
            sfzyl:false,
            ychdzpyl:false,
            yktyl:false,
            hkbyl:false,
            jhzyl:false,
            yktsqdyl:false,
            privyList:[],
            privyStr: "",
            privyId:"",
            queryPersonInfo: [],//查询时获取的人员信息
            isSubmit: true,
            relationList: [],//关系列表
            relationStr: "",//关系
            relationId: "",
            typeName:"",//详细类型名称
        }
    }

    componentDidMount = () => {
        this.getmz();//民族信息获取
        this.getjtzz("", "1");//家庭住址获取
        this.getsqlx();//申请类型获取
        this.getSmxx();//说明信息获取
        this.getRelations();//关系获取
    }

    //申请类型获取
    async getsqlx() {
        const url = NEW_YGK_URL + 'packagetype/getUsefulUserType';
        var params = new URLSearchParams();
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            if(res.data.flag){
                this.setState({
                    tktlxList: [{ value: 'B', label: '职工配偶及直系亲属(B卡)' },
                                { value: 'C', label: '职工其他亲属(C卡)' }
                            ]
                })
            }
        });
    }
    //说明信息获取
    async getSmxx() {
        const url = NEW_YGK_URL + 'toast/queryToastInfo';
        var params = new URLSearchParams();
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            if(res.data.flag){
                this.setState({
                    smxx: res.data.data[0].toast_content
                });
            }
        });
    }

    //用户类型获取
    async getyhlx() {
        const url = NEW_YGK_URL + 'packagetype/getUsefulUserType';
        var params = new URLSearchParams();
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            if(res.data.flag){
                const type = this.state.tktlx;
                if(type == 'B'){
                    this.setState({
                        yhlx: [{'value': '11','label': '职工直系亲属'}]
                    })
                }
                if(type == 'C'){
                    this.setState({
                        yhlx: [{'value': '26','label': '职工非直系亲属'}]
                    })
                }
            }
        });
    }


    //民族信息获取
    async getmz() {
        const url = NEW_YGK_URL + 'packagetype/getNationList';
        var params = new URLSearchParams();
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            if(res.data.flag){
                this.setState({
                    mz: res.data.data
                })
            }
        });
    }
    //家庭住址获取
    async getjtzz(pid, type) {
        let that = this;
        const url = NEW_YGK_URL + 'floorInfo/getAddress';
        var params = new URLSearchParams();
        params.append("pid", pid);
        params.append("type", type);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            if (res.status === 200) {
                if (type == "1") {
                    that.setState({
                        jtzhlist1: res.data.data
                    });
                } else if (type == "2") {
                    that.setState({
                        jtzhlist2: res.data.data
                    });
                } else if (type == "3") {
                    that.setState({
                        jtzhlist3: res.data.data
                    });
                }

            }
        });
    }

    //附件信息获取
    async getfjlx() {
        const url = NEW_YGK_URL + 'attachment/getAttachmentInfo';
        var params = new URLSearchParams();
        if(this.state.yhlxmc=="职工直系亲属"){
            params.append("cardType", "B");
            params.append("personTypeName", "家属");
        }
        if(this.state.yhlxmc =='职工非直系亲属'){
            params.append("cardType", "B");
            params.append("personTypeName", "家属");
        }
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            if(res.data.flag){
                this.setState({
                    fjlxlist: res.data.data
                })
            }
        });
    }

    SerMapDw = (e) => {
        return this.state.fzlist.map(i => (
            <RadioItem key={i.value} onClick={() => this.fzonChange(i)}  >
                {i.label+"-"+i.yktzh}
            </RadioItem>
        ))
    }

    setwhbhlist = (e) => {
        return this.state.fwbhlist.map(i => (
            <RadioItem key={i.value} onClick={() => this.fwryxxChange(i)}  >
                {i.ryxm}
            </RadioItem>
        ))
    }

    //本人身份证
    brsfzfj = () => {
        const list = this.state.fjlxlist
        let falg = false;
        var sfzfiles = [{url:SFZ_URL}]
        let data={};
        for (var item in list) {
            if (list[item].attachment_conf_id == "2") {
                falg = true;
                data=list[item];
            }
        }
        if (falg) {
            return (
                <div>
                      <div style={{paddingTop: "10px"}}></div>
                    <div className="scfj">
                        <span style={{color:"red"}}>*</span>办卡人身份证(<span onClick={()=>this.setState({ sfzyl: true })} style={{color:"#108ee9",}}>查看样例</span>)
                    </div>
                    <ImagePicker
                        files={this.state.brsfzfiles}
                        onChange={this.onChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.brsfzfiles.length < 2}
                        multiple={this.state.multiple}
                        capture="camera"
                    />
                    <div className="line"></div>
                </div>
            )
        } else {
            return null;
        }
    }

    //一寸照片
    yczpfj = () => {
        const list = this.state.fjlxlist
        let falg = false;
        let data={};
        for (var item in list) {
            if (list[item].attachment_conf_id == "5") {
                falg = true;
                data=list[item];
            }
        }
        if (falg) {
            return (
                <div>
                     <div style={{paddingTop: "10px"}}>
                    </div>
                    <div className="scfj"><span style={{color:"red"}}>*</span>一寸照片(红底)(<span onClick={()=>this.setState({ ychdzpyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
                    <ImagePicker
                        files={this.state.yczfiles}
                        onChange={this.yczponChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.yczfiles.length < 1}
                        multiple={this.state.multiple}
                    />
                    <div className="line"></div>
                </div>
            )
        } else {
            return null;
        }
    }

    //一卡通申请单
    yktfj = () => {
        const list = this.state.fjlxlist
        let falg = false;
        let data={};
        for (var item in list) {
            if (list[item].attachment_conf_id == "1") {
                falg = true;
                data=list[item];
            }
        }
        if (falg) {
            return (
                <div>
                     <div style={{paddingTop: "10px"}}>
                    </div>
                    <div className="scfj"><span style={{color:"red"}}>*</span>一卡通申请单(<span onClick={()=>this.setState({ yktsqdyl: true })} style={{
            color:"#108ee9",
          }}>查看样例</span>)</div>
                    <ImagePicker
                        files={this.state.yktsqdfiles}
                        onChange={this.yktChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.yktsqdfiles.length < 1}
                        multiple={this.state.multiple}
                        capture="camera"
                    />
                    <span style={{
                            color:"#888",
                            padding: "15px 15px 9px"
                            }}>1、申请表单直接<span onClick={()=>this.download()} style={{
                            color:"#108ee9",}}>  下载</span></span>
                        <div style={{color:"#888",paddingLeft: "15px",paddingRight: "15px",paddingBottom: "10px"}}>2、宝石花主页-服务大厅表单-一卡通申请表</div>
                    <div className="line"></div>
                </div>
            )
        } else {
            return null;

        }
    }

    //户口本（本页及户主页）
    hkbfj = () => {
        const list = this.state.fjlxlist
        let falg = false;
        let data={};
        for (var item in list) {
            if (list[item].attachment_conf_id == "10") {
                falg = true;
                data=list[item];
            }
        }
        if (falg) {
            return (
                <div>
                     <div style={{paddingTop: "10px"}}>
                    </div>
                    <div className="scfj">户口本(<span onClick={()=>this.setState({ hkbyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
                    <ImagePicker
                        files={this.state.hkbfiles}
                        onChange={this.hkbChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.hkbfiles.length < 1}
                        multiple={this.state.multiple}
                        capture="camera"
                    />
                    <div className="line"></div>
                </div>
            )
        } else {
            return null;

        }
    }
    //户主（配偶）一卡通
    hzyktfj = () => {
        const list = this.state.fjlxlist
        let falg = false;
        let data={};
        var yktylfiles = [{
            url:YKT_URL
          }]
        for (var item in list) {
            if (list[item].attachment_conf_id == "6") {
                falg = true;
                data=list[item];
            }
        }
        if (falg) {
            return (
                <div>
                    <div style={{paddingTop: "10px"}}>
                    </div>
                    <div className="scfj">
                        <span style={{color:"red"}}>*</span>
                        户主（配偶）一卡通(<span onClick={()=>this.setState({ yktyl: true })} style={{
            color:"#108ee9",}}>查看样例</span>)
            </div>
                    <ImagePicker
                        files={this.state.hzyktfiles}
                        onChange={this.hzyktChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.hzyktfiles.length < 1}
                        multiple={this.state.multiple}
                        capture="camera"
                    />
                    <div className="line"></div>
                </div>
            )
        } else {
            return null;
        }
    }

    //结婚证
    jhzfj = () => {
        const list = this.state.fjlxlist
        let falg = false;
        let data={};
        var files = [{
            url:JHZ_URL
          }]
        for (var item in list) {
            if (list[item].attachment_conf_id == "3") {
                falg = true;
                data=list[item];
            }
        }
        if (falg) {
            return (
                <div>
                       <div style={{paddingTop: "10px"}}>
                    </div>
                    <div className="scfj">结婚证(<span onClick={()=>this.setState({ jhzyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
                    <ImagePicker
                        files={this.state.jhzfiles}
                        onChange={this.jhzChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.jhzfiles.length < 1}
                        multiple={this.state.multiple}
                        capture="camera"
                    />
                    <div className="line"></div>
                </div>
            )
        } else {
            return null;

        }
    }

    //户主（配偶）身份证
    hzsfzfj = () => {
        const list = this.state.fjlxlist
        let falg = false;
        let data={};
        for (var item in list) {
            if (list[item].attachment_conf_id == "7") {
                falg = true;
                data=list[item];
            }
        }
        if (falg) {
            return (
                <div>
                     <div style={{paddingTop: "10px"}}>
                    </div>
                    <div className="scfj"><span style={{color:"red"}}>*</span>户主（配偶）身份证(<span onClick={()=>this.setState({ sfzyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
                    <ImagePicker
                        files={this.state.hzsfzfiles}
                        onChange={this.hzsfzChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={this.state.hzsfzfiles.length < 2}
                        multiple={this.state.multiple}
                        capture="camera"
                    />
                    <div className="line"></div>
                </div>
            )
        } else {
            return null;
        }
    }

    download =()=>{
        window.open( DOWNFILEURL+'yktsqxx/download?fjlx=yktsqfj')
      }

    imgonclick=(index,files) =>{
        this.setState({
          previewImage: files[index].url,
          visible: true
      });
      }

    genID = (length) => {
        return Number(Math.random().toString().substr(3,length) + Date.now()).toString(36);
    }
    onClosesfz = ()=>{
        this.setState({
              sfzyl: false
            });
      }
      onClosehdzp = ()=>{
        this.setState({
              ychdzpyl: false
            });
      }
      onCloseykt = ()=>{
        this.setState({
              yktyl: false
            });
      }
      onClosehkb = ()=>{
        this.setState({
              hkbyl: false
            });
      }
      onClosejhz = ()=>{
        this.setState({
              jhzyl: false
            });
      }
      onCloseyktsqd = ()=>{
        this.setState({
              yktsqdyl: false
            });
      }
        //输入身份证号码获取人员信息
        async queryPersion(value){
            const url = NEW_YGK_URL + 'cardApply/getPersonInfoByIdCardNo';
            var params = new URLSearchParams();
            let idCardNo = value.toUpperCase();
            params.append("idCardNo",idCardNo)
            await Axios({
                method: 'post',
                url: url,
                data: params
            }).then((res)=>{
                    console.log("验证人员信息是否存在：",res);
                    if(res.data.flag){
                        this.setState({
                            isSubmit: false
                        })
                    }
            })
          }
    setContent = () => {
        if (this.state.tktlx == "") {
            return null;
        }if (this.state.tktlx == "C") {
            // alert("我开始进去菜单加载了，我的类型是："+this.state.tktlx)
            return (
                <div>
                    <div className="scfj">申请信息</div>
                    <Picker
                        data={this.state.yhlx}
                        cols={1}
                        onOk={this.yhlxChange}
                        value={this.state.yhlxbm}
                    >
                        <List.Item arrow="horizontal"><span className="error">*</span>用户类型:</List.Item>
                    </Picker>
                    {/* 身份证号 */}
                    <InputItem placeholder="请输入身份证号" value={this.state.sfzh} onChange={(value) => this.setState({ sfzh: value })} onBlur={(value) => this.queryPersion(value)}>
                        <div ><span className="error">*</span>身份证号</div>
                    </InputItem>
                    {/* 申报人信息处理 */}
                    <InputItem placeholder="请输入用户姓名" value={this.state.ryxm} onChange={(value) => this.setState({ ryxm: value })}>
                     <div ><span className="error">*</span>用户姓名</div>
                    </InputItem>
                    {/* 申请人性别 */}
                    <Picker
                        data={this.state.xb}
                        cols={1}
                        onChange={this.xbChange}
                        value={this.state.yhxb}
                    >
                        <List.Item arrow="horizontal"><span className="error">*</span>用户性别</List.Item>
                    </Picker>
                    {/* 关系 */}
                    <InputItem placeholder = "请输入关系" onChange={value =>this.setState({relationStr: value})} value={this.state.relationStr}
                    >
                        <div ><span className="error">*</span>关系</div>
                    </InputItem>
                    {/* 关系人处理 */}
                    <InputItem placeholder="请选择家庭关系人" value={this.state.privy} onClick={this.showModal('privyModel')} editable="false">
                        <div ><span className="error">*</span>关系人:</div>
                    </InputItem>
                    <Modal
                        popup
                        visible={this.state.privyModel}
                        cols={1}
                        value={this.state.privyStr}
                        onClose={this.onClose('privyModel')}
                        animationType="slide-up"
                        style={{
                            height: "280px",
                            overflow: "auto"
                        }}
                    >
                        <SearchBar placeholder="请输入家庭关系人姓名"  cancelText="查询" onCancel={this.privySearch} />
                        {this.setPrivyList()}
                    </Modal>
                    {/* 户主单位获取 */}
                    <InputItem placeholder="请选择关系人单位" value={this.state.yhdw} onClick={this.showModal('dwsc')} editable="false">
                        <div ><span className="error">*</span>关系人单位</div>
                    </InputItem>
                    <Modal
                        popup
                        visible={this.state.dwsc}
                        cols={1}
                        value={this.state.yhdws}
                        onClose={this.onClose('dwsc')}
                        animationType="slide-up"
                        style={{
                            height: "280px",
                            overflow: "auto"
                        }}
                    >
                        <SearchBar placeholder="请输入单位名称"  cancelText="查询" onCancel={this.dwsearch} />
                        {this.setdwlist()}
                    </Modal>
                        {/* 申请人性别 */}
                    <Picker
                        data={this.state.xb}
                        cols={1}
                        onChange={this.xbChange}
                        value={this.state.yhxb}
                    >
                        <List.Item arrow="horizontal"><span className="error">*</span>用户性别</List.Item>
                    </Picker>
                    {/* 民族信息 */}
                    <Picker
                        data={this.state.mz}
                        cols={1}
                        onChange={this.mzChange}
                        value={this.state.yhmz}
                    >
                        <List.Item arrow="horizontal"><span className="error">*</span>民族</List.Item>
                    </Picker>
                    {/* 手机号码 */}
                    <InputItem placeholder="请输入手机号码" value={this.state.sjhm} onChange={(value) => this.setState({ sjhm: value })}>
                        <div ><span className="error">*</span>手机号码</div>
                    </InputItem>
                    {/* 是否时塔址住户 */}
                    <Picker
                        data={this.state.sftz}
                        cols={1}
                        onChange={this.sftzChange}
                        value={this.state.sftzText}
                    >
                        <List.Item arrow="horizontal">是否塔指住户</List.Item>
                    </Picker>
                    {/* 家庭住址 */}
                    <InputItem placeholder="请选择家庭住址" value={this.state.jtzz} onClick={this.showModal('modal1')} onChange={(value) => this.setState({ jtzz: value })}>
                        <div ><span className="error">*</span>家庭住址</div>
                    </InputItem>
                    <Modal
                        popup
                        visible={this.state.modal1}
                        onClose={this.onClose('modal1')}
                        animationType="slide-up"
                        afterClose={() => { console.log('afterClose'); }}
                    >
                        <List renderHeader={() => '详情信息'} >
                            <Picker
                                data={this.state.jtzhlist1}
                                cols={1}
                                onOk={(value) => this.setState({ jtzhid1: value })}
                                value={this.state.jtzhid1}
                            >
                                <List.Item arrow="horizontal">小区</List.Item>
                            </Picker>

                            <Picker
                                data={this.state.jtzhlist2}
                                cols={1}
                                onChange={(value) => this.setState({ jtzhid2: value })}
                                value={this.state.jtzhid2}
                            >
                                <List.Item arrow="horizontal" onClick={() => this.jtzhclick("2")}>楼宇</List.Item>
                            </Picker>

                            <Picker
                                data={this.state.jtzhlist3}
                                cols={1}
                                onChange={(value) => this.setState({ jtzhid3: value })}
                                value={this.state.jtzhid3}
                            >
                                <List.Item arrow="horizontal" onClick={() => this.jtzhclick("3")}>房号</List.Item>
                            </Picker>
                        </List>
                        <List.Item>
                            <Button type="primary" onClick={() => this.jadzmc()}>确认</Button>
                        </List.Item>
                    </Modal>
                    <div className="scfj" style={{fontSize: "14px",color: "#888"}}>上传附件</div>
                        {/* 身份证附件 */}
                        {this.brsfzfj()}
                        {/*一寸照片附件*/}
                        {this.yczpfj()}
                        {/*一卡通申请表附件*/}
                        {this.yktfj()}
                        {/* 户口本附件*/}
                        {this.hkbfj()}
                        {/* 户主一卡通附件*/}
                        {this.hzyktfj()}
                        {/* 结婚证 */}
                        {this.jhzfj()}
                        {/* 户主身份证附件 */}
                        {this.hzsfzfj()}
                    <div className="formSubmit">
                        <button className="submit" type="submit" onClick={() => this.getSubmit()}>
                            申 请
                    </button>
                    </div>
                </div>

            )
        }if( this.state.tktlx == "B") {
            // alert("我开始进去菜单加载了，我的类型是："+this.state.tktlx)
            return (
                <div>
                    <div className="scfj">申请信息</div>
                    <Picker
                        data={this.state.yhlx}
                        cols={1}
                        onOk={this.yhlxChange}
                        value={this.state.yhlxbm}
                    >
                        <List.Item arrow="horizontal"><span className="error">*</span>用户类型:</List.Item>
                    </Picker>
                    {/* 用户详细类型 */}
                    <Picker
                        data={this.state.detailType}
                        cols={1}
                        onChange={this.detailTypeChange}
                        value={this.state.typeName}
                    >
                        <List.Item arrow="horizontal"><span className="error">*</span>用户详细类型</List.Item>
                    </Picker>
                    {/* 身份证号 */}
                    <InputItem placeholder="请输入身份证号" value={this.state.sfzh} onChange={(value) => this.setState({ sfzh: value })} onBlur={(value) => this.queryPersion(value)}>
                        <div ><span className="error">*</span>身份证号</div>
                    </InputItem>
                    {/* 申报人信息处理 */}
                    <InputItem placeholder="请输入用户姓名" value={this.state.ryxm} onChange={(value) => this.setState({ ryxm: value })}>
                     <div ><span className="error">*</span>用户姓名</div>
                    </InputItem>
                    {/* 申请人性别 */}
                    <Picker
                        data={this.state.xb}
                        cols={1}
                        onChange={this.xbChange}
                        value={this.state.yhxb}
                    >
                        <List.Item arrow="horizontal"><span className="error">*</span>用户性别</List.Item>
                    </Picker>
                    {/* 关系 */}
                    <Picker
                        data={this.state.relationList}
                        cols={1}
                        onChange={this.relationChange}
                        value={this.state.relationStr}
                    >
                        <List.Item arrow="horizontal"><span className="error">*</span>关系：</List.Item>
                    </Picker>
                    {/* 关系人处理 */}
                    <InputItem placeholder="请选择家庭关系人" value={this.state.privy} onClick={this.showModal('privyModel')} editable="false">
                        <div ><span className="error">*</span>关系人:</div>
                    </InputItem>
                    <Modal
                        popup
                        visible={this.state.privyModel}
                        cols={1}
                        value={this.state.privyStr}
                        onClose={this.onClose('privyModel')}
                        animationType="slide-up"
                        style={{
                            height: "280px",
                            overflow: "auto"
                        }}
                    >
                        <SearchBar placeholder="请输入家庭关系人姓名"  cancelText="查询" onCancel={this.privySearch} />
                        {this.setPrivyList()}
                    </Modal>
                    {/* 户主单位获取 */}
                    {/* <InputItem placeholder="请选择关系人单位" value={this.state.yhdw}  editable="false">
                        <div ><span className="error">*</span>关系人单位</div>
                    </InputItem> */}
                    <InputItem placeholder="请选择关系人单位" value={this.state.yhdw} onClick={this.showModal('dwsc')} editable="false">
                        <div ><span className="error">*</span>关系人单位</div>
                    </InputItem>
                    <Modal
                        popup
                        visible={this.state.dwsc}
                        cols={1}
                        value={this.state.yhdws}
                        onClose={this.onClose('dwsc')}
                        animationType="slide-up"
                        style={{
                            height: "280px",
                            overflow: "auto"
                        }}
                    >
                        <SearchBar placeholder="请输入单位名称"  cancelText="查询" onCancel={this.dwsearch} />
                        {this.setdwlist()}
                    </Modal>
                    {/* 民族信息 */}
                    <Picker
                        data={this.state.mz}
                        cols={1}
                        onChange={this.mzChange}
                        value={this.state.yhmz}
                    >
                        <List.Item arrow="horizontal"><span className="error">*</span>民族</List.Item>
                    </Picker>
                    {/* 手机号码 */}
                    <InputItem placeholder="请输入手机号码" value={this.state.sjhm} onChange={(value) => this.setState({ sjhm: value })}>
                        <div ><span className="error">*</span>手机号码</div>
                    </InputItem>
                    {/* 是否时塔址住户 */}
                    <Picker
                        data={this.state.sftz}
                        cols={1}
                        onChange={this.sftzChange}
                        value={this.state.sftzText}
                    >
                        <List.Item arrow="horizontal">是否塔指住户</List.Item>
                    </Picker>
                    {/* 家庭住址 */}
                    <InputItem placeholder="请选择家庭住址" value={this.state.jtzz} onClick={this.showModal('modal1')} onChange={(value) => this.setState({ jtzz: value })}>
                        <div ><span className="error">*</span>家庭住址</div>
                    </InputItem>
                    <Modal
                        popup
                        visible={this.state.modal1}
                        onClose={this.onClose('modal1')}
                        animationType="slide-up"
                        afterClose={() => { console.log('afterClose'); }}
                    >
                        <List renderHeader={() => '详情信息'} >
                            <Picker
                                data={this.state.jtzhlist1}
                                cols={1}
                                onOk={(value) => this.setState({ jtzhid1: value })}
                                value={this.state.jtzhid1}
                            >
                                <List.Item arrow="horizontal">小区</List.Item>
                            </Picker>

                            <Picker
                                data={this.state.jtzhlist2}
                                cols={1}
                                onChange={(value) => this.setState({ jtzhid2: value })}
                                value={this.state.jtzhid2}
                            >
                                <List.Item arrow="horizontal" onClick={() => this.jtzhclick("2")}>楼宇</List.Item>
                            </Picker>

                            <Picker
                                data={this.state.jtzhlist3}
                                cols={1}
                                onChange={(value) => this.setState({ jtzhid3: value })}
                                value={this.state.jtzhid3}
                            >
                                <List.Item arrow="horizontal" onClick={() => this.jtzhclick("3")}>房号</List.Item>
                            </Picker>
                        </List>
                        <List.Item>
                            <Button type="primary" onClick={() => this.jadzmc()}>确认</Button>
                        </List.Item>
                    </Modal>
                    <div className="scfj" style={{fontSize: "14px",color: "#888"}}>上传附件</div>
                        {/* 身份证附件 */}
                        {this.brsfzfj()}
                        {/*一寸照片附件*/}
                        {this.yczpfj()}
                        {/*一卡通申请表附件*/}
                        {this.yktfj()}
                        {/* 户口本附件*/}
                        {this.hkbfj()}
                        {/* 户主一卡通附件*/}
                        {this.hzyktfj()}
                        {/* 结婚证 */}
                        {this.jhzfj()}
                        {/* 户主身份证附件 */}
                        {this.hzsfzfj()}
                    <div className="formSubmit">
                        <button className="submit" type="submit" onClick={() => this.getSubmit()}>
                            申 请
                    </button>
                    </div>
                </div>

            )
        }
    }
    //类型选择
    handleFromChange = (e) => {
        this.setState({
            tktlx: e
        }, () => {
            this.getyhlx();
            if(e =="C"){
                this.setState({
                    yhlxbm:"",
                    sjhm: "",
                    sfzh: "",
                    ryxm: ""
                });
            }else if(e =="B"){
                this.setState({
                    yhlxbm:"",
                    sjhm: "",
                    sfzh: "",
                    ryxm: ""
                });
            }
        });

    }

    //家庭住址
    jadzmc = () => {
        const list = this.state.jtzhlist3
        for (var item in list) {
            if (list[item].value == this.state.jtzhid3) { //item 表示Json串中的属性，如'name'
                var jValue = list[item];//key所对应的value
                console.log(jValue.label);
                this.setState({
                    jtzz: jValue.label,
                    modal1: false,
                });
            }
        }
    }

    //用户类型
    yhlxChange = (e) => {
        const list = this.state.yhlx
        // console.log("用户类型数据",...list)
            this.setState({
                yhlxmc: list[0].label,
                yhlxbm: e
            },()=>{
                this.getfjlx();
            })
            console.log(list[0].label)
    }
    //人员关系获取
    async getRelations() {
        const url = NEW_YGK_URL + 'cardApply/getRelations';
        var params = new URLSearchParams();
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res);
            if(res.data.data){
                this.setState({
                    relationList: res.data.data
                })
            }
        });
    }

    //关系
    relationChange = (e) =>{
        this.setState({
            relationStr: e,
            relationId: e,
        })
    }
    //性别
    xbChange = (e) => {
        this.setState({
            yhxb: e
        });
    }
    // 详细类型改变
    detailTypeChange = (e) => {
        this.setState({
            typeName: e,
        })
    }
    //民族
    mzChange = (e) => {
        console.log(e)
        const list = this.state.mz
        for (var item in list) {
            if (list[item].value == e) { //item 表示Json串中的属性，如'name'
                var jValue = list[item];//key所对应的value
                this.setState({
                    yhmzText: jValue.label,
                });
            }
        }

        this.setState({
            yhmz: e
        });
    }
    //与户主关系
    yhzgxChange = (e) => {
        console.log(e)
        this.setState({
            hzgx: e
        });
    }



    //是否时塔地住户
    sftzChange = (e) => {
        console.log(e)
        if(e=="否"){
            this.setState({
                jtzz:""
            });
        }
        this.setState({
            sftzText: e
        });
    }


    showModal = key => (e) => {
        if(this.state.sftzText=="否" && key=="modal1"){
            return;
        }
        this.setState({
            [key]: true,
        });
    }

    onClose = key => (e) => {
        this.setState({
            [key]: false,
        });
    }

    search = (val) => {
        console.log(val);
        this.setState({
            fzxm: val
        }, () => {
            this.getgzxm();
        });
    }
    //查询户主
    searchhzxm = (val) => {
        console.log(val);
        this.setState({
            fwbhs: val
        }, () => {
            this.getfwbhhzxx();
        });
    }
    //户主变更
    fzonChange = (e) => {
        console.log(e)
        this.setState({
            modalhzxm: false,
            fzxmvalue: e.label,
            fzxmid: e.ryid,
            fzykth: e.yktzh
        });
    }
    //房屋人员信息
    fwryxxChange = (e) => {
        console.log(e)
        this.setState({
            fwbhshow: false,
            fwbh: e.fwbh.substring(12),
            fzxm: e.ryxm,
        });
    }
    //----------------------------------------------------------------------------------
    // 关系人获取
    async getRrivy(){
        const url = NEW_YGK_URL + 'cardApply/getPrivy';
        var params = new URLSearchParams();
        params.append("privy", this.state.privyStr);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res)=>{
            console.log("关系人获取",res);
            if(res.data.flag){
                this.setState({
                    privyList: res.data.data
                })
            }
        })
    }

    privySearch = (val) =>{
        this.setState({
            privyStr: val
        },()=>{
            this.getRrivy();
        })
    }

    //选择关系人时调用
    privyChange = (e) => {
        let privyName = e.label;
        privyName = privyName.substring(0,privyName.indexOf('-'))
        console.log(e)
        this.setState({
            privyModel: false,
            privy: privyName,
            privyId: e.value,
            yhdwbm: e.unit_id,
            yhdw: e.unit_name
        });
    }

    setPrivyList = (e) =>{
        return this.state.privyList.map(item =>(
            <RadioItem key={item.value} onClick={() => this.privyChange(item)}  >
                {item.label}
            </RadioItem>
        ))
    }

    // -------------------------------------------------------------------------------
        // 单位信息获取
        async getdwxm() {
            const url = NEW_YGK_URL + 'unitInfo/getOwnerUnit';
            var params = new URLSearchParams();
            params.append("unitName", this.state.yhdws);
            await Axios({
                method: 'post',
                url: url,
                data: params
            }).then((res) => {
                if (res.status === 200) {
                    this.setState({
                        dwlist: res.data.data
                    });
                }
            });
        }
    // 单位查询
    dwsearch = (val) => {
        this.setState({
            yhdws: val
        }, () => {
            this.getdwxm();
        });
    }
    //选择单位时调用
    dwChange = (e) => {
        this.setState({
            dwsc: false,
            yhdw: e.label,
            yhdwbm: e.value
        });
    }

    setdwlist = (e) => {
        return this.state.dwlist.map(i => (
            <RadioItem key={i.value} onClick={() => this.dwChange(i)}  >
                {i.label}
            </RadioItem>
        ))
    }
// -----------------------------------------------------------------------------------------------
    //甲方单位
    jtzhclick = (e) => {
        console.log(e);
        if (e == "2") {
            if (this.state.jtzhid1 != "") {
                this.getjtzz(this.state.jtzhid1, "2");
            } else {
                Toast.info('请先选择一级单位', 3, null, false);
            }
        }
        if (e == "3") {
            if (this.state.jtzhid2 != "") {
                this.getjtzz(this.state.jtzhid2, "3");
            } else {
                Toast.info('请先选择二级单位', 3, null, false);
            }
        }
    }

    onChange = (brsfzfiles, type, index) => {
        console.log(brsfzfiles);
        var str=[];
        if(type!="remove"){
        for(let i=0;i<brsfzfiles.length;i++){

              lrz(brsfzfiles[i].url, { quality: 0.1 }) .then((rst) => {
                console.log(rst)
                let file = new File([rst.file],this.genID(16)+"."+brsfzfiles[i].file.name.split(".")[1], { type: brsfzfiles[i].file.type, lastModified: Date.now() });
                var arr = [{ orientation: 1, url: rst.base64, file: file }];
                str.push(arr[0])
                this.setState({
                    brsfzfiles: str,
                })
              })

          }
        }else{
            this.setState({
              brsfzfiles,
          });
          }
        // this.setState({
        //     brsfzfiles,
        // });
    }

    yczponChange = (yczfiles, type, index) => {
        var str=[];
        if(type!="remove"){
        for(let i=0;i<yczfiles.length;i++){

              lrz(yczfiles[i].url, { quality: 0.1 }) .then((rst) => {
                console.log(rst)
                let file = new File([rst.file], this.genID(16)+"."+yczfiles[i].file.name.split(".")[1], { type: yczfiles[i].file.type, lastModified: Date.now() });
                var arr = [{ orientation: 1, url: rst.base64, file: file }];
                str.push(arr[0])
                this.setState({
                    yczfiles: str,
                })
              })

          }
        }else{
            this.setState({
              yczfiles,
          });
          }
        // console.log(yczfiles, type, index);
        // this.setState({
        //     yczfiles,
        // });
    }

    yktChange = (yktsqdfiles, type, index) => {
        var str=[];
        if(type!="remove"){
        for(let i=0;i<yktsqdfiles.length;i++){

              lrz(yktsqdfiles[i].url, { quality: 0.1 }) .then((rst) => {
                console.log(rst)
                let file = new File([rst.file], this.genID(16)+"."+yktsqdfiles[i].file.name.split(".")[1], { type: yktsqdfiles[i].file.type, lastModified: Date.now() });
                var arr = [{ orientation: 1, url: rst.base64, file: file }];
                str.push(arr[0])
                this.setState({
                    yktsqdfiles: str,
                })
              })

          }
        }else{
            this.setState({
              yktsqdfiles,
          });
          }
        // console.log(yktsqdfiles, type, index);
        // this.setState({
        //     yktsqdfiles,
        // });
    }
    //户口本改变
    hkbChange = (hkbfiles, type, index) => {
        var str=[];
        if(type!="remove"){
        for(let i=0;i<hkbfiles.length;i++){

              lrz(hkbfiles[i].url, { quality: 0.1 }) .then((rst) => {
                console.log(rst)
                let file = new File([rst.file], this.genID(16)+"."+hkbfiles[i].file.name.split(".")[1], { type: hkbfiles[i].file.type, lastModified: Date.now() });
                var arr = [{ orientation: 1, url: rst.base64, file: file }];
                str.push(arr[0])
                this.setState({
                    hkbfiles: str,
                })
              })

          }
        }else{
            this.setState({
              hkbfiles,
          });
          }
        // console.log(hkbfiles, type, index);
        // this.setState({
        //     hkbfiles,
        // });
    }

    //房主一卡通好变更
    hzyktChange = (hzyktfiles, type, index) => {
        var str=[];
        if(type!="remove"){
        for(let i=0;i<hzyktfiles.length;i++){

              lrz(hzyktfiles[i].url, { quality: 0.1 }) .then((rst) => {
                console.log(rst)
                let file = new File([rst.file], this.genID(16)+"."+hzyktfiles[i].file.name.split(".")[1], { type: hzyktfiles[i].file.type, lastModified: Date.now() });
                var arr = [{ orientation: 1, url: rst.base64, file: file }];
                str.push(arr[0])
                this.setState({
                    hzyktfiles: str,
                })
              })

          }
        }else{
            this.setState({
              hzyktfiles,
          });
          }
        // console.log(hzyktfiles, type, index);
        // this.setState({
        //     hzyktfiles,
        // });
    }

    //结婚证变动
    jhzChange = (jhzfiles, type, index) => {
        console.log(jhzfiles, type, index);
        this.setState({
            jhzfiles,
        });
    }

    //房主身份证号
    hzsfzChange = (hzsfzfiles, type, index) => {
        var str=[];
        if(type!="remove"){
        for(let i=0;i<hzsfzfiles.length;i++){

              lrz(hzsfzfiles[i].url, { quality: 0.1 }) .then((rst) => {
                console.log(rst)
                let file = new File([rst.file], this.genID(16)+"."+hzsfzfiles[i].file.name.split(".")[1], { type: hzsfzfiles[i].file.type, lastModified: Date.now() });
                var arr = [{ orientation: 1, url: rst.base64, file: file }];
                str.push(arr[0])
                this.setState({
                    hzsfzfiles: str,
                })
              })

          }
        }else{
            this.setState({
              hzsfzfiles,
          });
          }
    }

    async getSubmit() {
        // alert(this.state.typeName)
        if(this.state.tktlx == "B" || this.state.tktlx == "C"){
            if(this.state.ryxm==""||this.state.yhdwbm==""||this.state.sfzh==""
            ||this.state.yhxb==""||this.state.yhmzText==""||this.state.sjhm==""||this.state.jtzz==""
            || this.state.privyStr == ""){
                Toast.info("请将表单填写完整", 4, null, false);
                return;
            }
        }
        if (this.state.tktlx == "B" && this.state.typeName == "") {
            Toast.info("请将表单填写完整", 4, null, false);
                return;
        }
        // this.dataSubmit();
        console.log("判断是否可以提交");
        Toast.loading("加载中...", 0, null, true);
        const url = NEW_YGK_URL + 'cardApply/isSubmitApply';
        var params = new URLSearchParams();
        console.log(this.state.sfzh);
        params.append("idCardNo", this.state.sfzh);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res);
            if(res.data.flag){
                Toast.hide();
                this.dataSubmit();
            }else{
                Toast.hide();
                Toast.info(res.data.msg, 4, null, false);
            }
        });
    }

    dataSubmit = () => {
        Toast.loading("加载中...", 0, null, true);
        const applyType = this.state.yhlxmc;
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const userIdCardNo = userInfo.sfzh;//当前用户身份证号码
        const url = NEW_YGK_URL + 'cardApply/submitCardApply';
        let params = new FormData();
        if(applyType == '职工直系亲属'){
            params.append('applyCardTypeCode', '11');//申请类别
            params.append('applyCardTypeName', '直系亲属');//申请类别
            params.append('applyCardType', 'B卡');//申请类别
        }
        if(applyType == '职工非直系亲属'){
            params.append('applyCardTypeCode', '26');//申请类别
            params.append('applyCardTypeName', '非直系亲属');//申请类别
            params.append('applyCardType', 'C卡');//申请类别
        }
        params.append('detailType', this.state.typeName);//直系亲属详细类型
        params.append('isExist', this.state.isSubmit);//是否存在人员信息
        params.append('userIdCardNo', userIdCardNo);//当前用户身份证号码
        params.append('applyPersonName', this.state.ryxm);//用户姓名
        params.append('applyPersonIdCardNo', this.state.sfzh);//用户身份证号
        params.append('sex', this.state.yhxb);//用户性别
        params.append('nation', this.state.yhmzText);//用户民族
        params.append('phoneNo', this.state.sjhm);//手机号码
        params.append('address', this.state.jtzz);//用户地址
        params.append('privy', this.state.privy);//关系人
        params.append('privyId', this.state.privyId);//关系人id
        params.append('privyUnit', this.state.yhdw);//关系人单位
        params.append('privyUnitId', this.state.yhdwbm);//关系人单位id
        params.append('applyPersonUnit', this.state.yhdw);//用户单位
        params.append('applyPersonUnitId', this.state.yhdwbm);//用户单位id
        params.append('relations', this.state.relationStr);//人员关系
        params.append('note', "微信公众号申请");
        let fileInfo = []
        if (this.state.brsfzfiles.length !== 0) {
            for (let f of this.state.brsfzfiles) {
                params.append("fjList", f.file);
                fileInfo.push({ name: f.file.name, type: '2' });
            }
        }

        if (this.state.hkbfiles.length !== 0) {
            for (let f of this.state.hkbfiles) {
                params.append("fjList", f.file);
                fileInfo.push({ name: f.file.name, type: '10' });
            }
        }

        if (this.state.hzyktfiles.length !== 0) {
            for (let f of this.state.hzyktfiles) {
                params.append("fjList", f.file);
                fileInfo.push({ name: f.file.name, type: '6' });
            }
        }

        if (this.state.jhzfiles.length !== 0) {
            for (let f of this.state.jhzfiles) {
                params.append("fjList", f.file);
                fileInfo.push({ name: f.file.name, type: '3' });
            }
        }

        if (this.state.yczfiles.length !== 0) {
            for (let f of this.state.yczfiles) {
                params.append("fjList", f.file);
                fileInfo.push({ name: f.file.name, type: '5' });
            }
        }

        if (this.state.hzsfzfiles.length !== 0) {
            for (let f of this.state.hzsfzfiles) {
                params.append("fjList", f.file);
                fileInfo.push({ name: f.file.name, type: '7' });
            }
        }

        if (this.state.yktsqdfiles.length !== 0) {
            for (let f of this.state.yktsqdfiles) {
                params.append("fjList", f.file);
                fileInfo.push({ name: f.file.name, type: '1' });
            }
        }
        params.append('fileInfo', JSON.stringify(fileInfo));
        Axios({
            headers: { 'Content-Type': 'multipart/form-data' },
            method: 'post',
            url: url,
            data: params,
            //这部分非常重要，否则formdata会被转格式
            // transformRequest: [function () {
            //     return params;
            // }],
        }).then((res) => {
            Toast.hide();
            if (res.status === 200) {
                Toast.info(res.data.data, 2, null, false);
            }
            this.props.history.go(-1)
        });
    }

    render() {
        console.log(this.state.brsfzfiles)
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >一卡通申请</NavBar>
                <div className="divmagin">
                    <List className="my-list">
                        <Picker
                            data={this.state.tktlxList}
                            cols={1}
                            onOk={this.handleFromChange}
                            value={this.state.tktlx}
                        >
                            <List.Item >申请类型：</List.Item>
                        </Picker>
                        <div className="scfj">申请说明</div>
                            <div style={{color:'red',lineHeight:'25px',fontSize:'25px',margin:'10px 10px 10px 10px'}}>提交一卡通申请后,务必联系本单位管理员审核并送审,否则无法办理!</div>
                        {/* <p style={{color:'#C0C0C0',lineHeight:'25px',fontSize:'15px',margin:'10px 10px 10px 10px'}}> */}
                            <div style={{color:'#C0C0C0',lineHeight:'25px',fontSize:'15px',margin:'10px 10px 10px 10px'}} dangerouslySetInnerHTML={{ __html:this.state.smxx}}></div>
                            {/* </p> */}

                        {this.setContent()}
                        <Modal
                    visible={ this.state.sfzyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClosesfz(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={SFZ_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
                <Modal
                    visible={ this.state.ychdzpyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClosehdzp(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={ycz}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
                <Modal
                    visible={ this.state.yktyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onCloseykt(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={YKT_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
                <Modal
                    visible={ this.state.hkbyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClosehkb(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={hkb}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
                <Modal
                    visible={ this.state.jhzyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClosejhz(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={JHZ_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
                <Modal
                    visible={ this.state.yktsqdyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onCloseyktsqd(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={yktsqd}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
                    </List>

                </div>
            </div>
        )
    }
}
